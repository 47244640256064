import React, {useState} from 'react';
import {Box, Grid} from '@material-ui/core';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AppButton from '../../../core/components/AppButton';
import AppDatePicker from '../../../core/components/inputs/AppDatePicker';
import AppInput from '../../../core/components/inputs/AppInput/AppMuiInput';
import AddressBookRecordNav from '../AddressBookRecordNav';
import ComponentBuilder from '../../../core/ComponentBuilder';
import selectCustomerShipmentsSearch from '../../../hubs/shipment/selectors/selectCustomerShipmentsSearch';
import isNewCompany from '../../../hubs/addressBook/actions/modification/isNewCompany';
import ShipmentHistoryListing from './includes/ShipmentHistoryListing';
import { ShipmentHistoryType } from './includes/ShipmentHistoryType';
import './ShipmentHistoryPage.scss';
import CrmDocumentQuickViewDrawer from '../../../crm/components/CrmDocumentQuickViewDrawer';
import CheckIfAuthorized from "../tempSecurity/CheckIfAuthorized";
import MissingOrDeniedCompanyAlert from "../MissingOrDeniedCompanyAlert";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import useIsAgencyOps from "../../../hubs/personaV2/selectors/useIsAgencyOps";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import TgfEquipmentTypesComboBox from "../../../core/kendo/form/inputs/specialty/TgfEquipmentTypesComboBox";
import AppDropdown from "../../../core/components/dropdowns/AppDropdown";
import {Checkbox} from "@progress/kendo-react-inputs";
import {useDispatch} from "react-redux";
import AppCurrencyText from "../../../core/components/text/AppCurrencyText";

const LoadProcessName = 'AddressBook.ShipmentHistoryPage.Load';
const SearchProcessName = 'AddressBook.ShipmentHistoryPage.Search';
const ShipmentHistorySearchId = 'AddressBook.ShipmentHistoryPage';

const ShipmentHistoryPage = (props) => {
  const {
    company,
    isAdmin,
    isAgencyOps,
    modifyingAssociate,
    dispose,
    match,
    shipments,
    shipmentCount,
    getCustomerFinancialMetrics,
    searchCustomerShipments,
    clearCustomerSearch,
    load,
    associate,
    isDetailView,
  } = props;

  const companyId = match.params.id &&
    parseInt(match.params.id);
  usePageTitle(company?.name ? `Add: ${company.name.substring(0, 20)}` : null);

  if (isNewCompany(companyId)) {
    throw new Error('CompanyId url parameter missing.');
  }

  const historyTypes = [
      { id: ShipmentHistoryType.Standard, name: 'Standard'},
      { id: ShipmentHistoryType.Detailed, name: 'Shipment Details'}
  ];

  const isShipmentDetailFilter = () => {
      return historyType?.id === ShipmentHistoryType.Detailed;
  };

  const personalDispatch = useDispatch();

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['shipmentBolNumber', 'desc']]);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('shipmentBolNumber');

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [shipmentNumber, setShipmentNumber] = React.useState('');
  const [docQuickViewShipmentId, setDocQuickViewShipmentId] = React.useState(null);
  const [historyType, setHistoryType] = React.useState(historyTypes[0]);
  const [viewBlindOnly, setViewBlindOnly] = React.useState(false);
  const [viewHazMatOnly, setViewHazMatOnly] = React.useState(false);
  const [viewMultiStopsOnly, setViewMultiStopsOnly] = React.useState(false);
  const [equipmentType, setEquipmentType] = React.useState(null);

  const [financialMetrics, setFinancialMetrics] = useState(null);

  React.useEffect(() => {
    return () => clearCustomerSearch();
  }, [clearCustomerSearch]);

  React.useEffect(() => {
    load(companyId);
    loadCustomerFinancialMetrics(companyId);
    return () => dispose();
  }, [companyId, load, dispose]);

  React.useEffect(() => {
    performSearch();
  }, [offset, limit, sort, company, historyType]);

  const performSearch = () => {
    const processedStartDate = startDate && startDate.format('YYYY-MM-DD');
    const processedEndDate = endDate && endDate.format('YYYY-MM-DD');

    const args = [
      companyId,
      processedStartDate || null,
      processedEndDate || null,
      (shipmentNumber && parseInt(shipmentNumber)) || null,
      offset,
      limit,
      sort,
      viewBlindOnly,
      viewHazMatOnly,
      viewMultiStopsOnly,
      equipmentType
    ];
    searchCustomerShipments(...args);
  };

  const handleSearchClick = (e) => {
    setOffset(0);
    performSearch();
  };

  const handleStartDateChange = (dateValue) => {
    setStartDate(dateValue);
  };

  const handleEndDateChange = (dateValue) => {
    setEndDate(dateValue);
  };

  const handleShipmentNumberChange = (e) => {
    setShipmentNumber(e.target.value);
  };

  //Handles when the user changes pages within the table.
  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

  // Handles when the user clicks on column headers for sorting.
  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

    setSort([[column, changeOrder]]);
    setOrder(changeOrder);
    setOrderBy(column);
  };
  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  const handleDocumentQuickViewClick = (shipment) => {
    setDocQuickViewShipmentId(shipment.shipmentBolNumber);
  };

  const handleDocumentQuickViewClose = () => {
    setDocQuickViewShipmentId(null);
  };

  const handleHistoryTypeChange = (e, selectedHistoryType) => {
    setHistoryType(selectedHistoryType);
    if (selectedHistoryType.id === ShipmentHistoryType.Standard) {
        setViewBlindOnly(false);
        setViewHazMatOnly(false);
        setViewMultiStopsOnly(false);
        setEquipmentType(null);
    }
  };

  const handleViewBlindOnlyClick = (e) => {
    setViewBlindOnly(e.target.value);
  };

  const handleViewHazMatOnlyClick = (e) => {
    setViewHazMatOnly(e.target.value);
  };

  const handleViewMultiStopsOnlyClick = (e) => {
    setViewMultiStopsOnly(e.target.value);
  };

  const handleEquipmentTypeChange = (e) => {
      setEquipmentType(e.target.value);
  };

  const loadCustomerFinancialMetrics = async (companyId) => {
    try {
      personalDispatch(window.shell.actions.sys.processStart('getCustomerFinancialMetrics'));
      setFinancialMetrics(await window.shell.gateway.getCustomerFinancialMetrics(companyId));
    } catch (e) {
      console.log(e);
      personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
          content: 'Failed To Load Customer Financial Metrics',
          color: 'error',
      }));
    } finally {
      personalDispatch(window.shell.actions.sys.processComplete('getCustomerFinancialMetrics'));
    }
  };

  const title = company ?
    `Address Book - Shipment History - ${company.name}` :
    `Address Book - Shipment History`;

  const getFinancialMetricsClass = (value) => {
      return value < 0
          ? "financial-metrics-negative"
          : "";
  };

  const getGridColsByHistoryType = () => {
      return isShipmentDetailFilter() ? 1 : 2;
  };

  return (
    <FullWidthLayout SideNav={AddressBookRecordNav} title={title} className="shipment-history-styles">
      {company !== null ?
        <CheckIfAuthorized
          isAdmin={isAdmin}
          modifyingAssociate={modifyingAssociate}
          company={company} >
          <Grid container spacing={1}>
            <Grid item xs={12} md={2}>
                <AppDropdown
                    label="History Type:"
                    field={'name'}
                    fieldIndex={'id'}
                    disableClearable
                    options={historyTypes}
                    value={historyType}
                    onChange={handleHistoryTypeChange}
                />
            </Grid>
            {financialMetrics &&
            <Grid item xs={12} md={10} className="shipment-historical-data">
                <Box>
                    <Box>YTD Carrier Cost: <AppCurrencyText value={financialMetrics.ytdCarrierCost} className={getFinancialMetricsClass(financialMetrics.ytdCarrierCost)} /></Box>
                    <Box>Lifetime Carrier Cost: <AppCurrencyText value={financialMetrics.lifetimeCarrierCost} className={getFinancialMetricsClass(financialMetrics.lifetimeCarrierCost)} /></Box>
                </Box>
                <Box>
                    <Box>YTD Revenue: <AppCurrencyText value={financialMetrics.ytdCustomerCost} className={getFinancialMetricsClass(financialMetrics.ytdCustomerCost)} /></Box>
                    <Box>Lifetime Revenue <AppCurrencyText value={financialMetrics.lifetimeCustomerCost} className={getFinancialMetricsClass(financialMetrics.lifetimeCustomerCost)} /></Box>
                </Box>
                <Box>
                    <Box>YTD Margin: <AppCurrencyText value={financialMetrics.ytdTotalMargin} className={getFinancialMetricsClass(financialMetrics.ytdTotalMargin)} /></Box>
                    <Box>Lifetime Margin: <AppCurrencyText value={financialMetrics.lifetimeTotalMargin} className={getFinancialMetricsClass(financialMetrics.lifetimeTotalMargin)} /></Box>
                </Box>
            </Grid>}
            <Grid item xs={12} md={getGridColsByHistoryType()}>
                <Box>Pickup Date From:</Box>
                <AppDatePicker
                    value={startDate}
                    onChange={handleStartDateChange}
                />
            </Grid>
            <Grid item xs={12} md={getGridColsByHistoryType()}>
                <Box>Pickup Date To:</Box>
                <AppDatePicker
                    value={endDate}
                    onChange={handleEndDateChange}
                />
            </Grid>
            {isShipmentDetailFilter() &&
            <Grid item xs={12} md={6}>
                <Box className={"shipment-detail-filters"}>
                    <Box>
                        <Checkbox
                            value={viewBlindOnly}
                            id={"viewBlindOnly"}
                            name={"viewBlindOnly"}
                            label={"View Blind Only"}
                            onChange={handleViewBlindOnlyClick} />
                    </Box>
                    <Box>
                        <Checkbox
                            value={viewHazMatOnly}
                            id={"viewHazMatOnly"}
                            name={"viewHazMatOnly"}
                            label={"View Haz-mat Only"}
                            onChange={handleViewHazMatOnlyClick} />
                    </Box>
                    <Box>
                        <Checkbox
                            value={viewMultiStopsOnly}
                            id={"viewMultiStopsOnly"}
                            name={"viewMultiStopsOnly"}
                            label={"View Multi-stops Only"}
                            onChange={handleViewMultiStopsOnlyClick} />
                    </Box>
                    <Box>
                        <TgfEquipmentTypesComboBox
                            id={"EquipmentType"}
                            name={"EquipmentType"}
                            label={"Equipment Type:"}
                            onChange={handleEquipmentTypeChange}
                            style={{height: "2.25rem"}} />
                    </Box>
                </Box>
            </Grid>}
            <Grid item xs={12} md={getGridColsByHistoryType()}>
              <Box>Ship #:</Box>
              <AppInput
                  onChange={handleShipmentNumberChange}
                  inputProps={{
                      maxLength: 10
                  }}
              />
            </Grid>
            <Grid item xs={12} md={1} style={{ textAlign: 'left', alignContent: 'end'}}>
              <AppButton
                onClick={handleSearchClick}>
                Search
              </AppButton>
            </Grid>
            <Grid item xs={12}>
            <ShipmentHistoryListing
                count={shipmentCount}
                data={shipments}
                orderBy={orderBy}
                order={order}
                rowsPerPage={limit}
                page={offset / limit}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onSort={handleSortChange}
                onDocumentQuickViewClick={handleDocumentQuickViewClick}
                associate={associate}
                isAgencyOps={isAgencyOps}
                historyType={historyType}
              />
            </Grid>
          </Grid>
          {
            docQuickViewShipmentId &&
            <CrmDocumentQuickViewDrawer
              shipmentId={docQuickViewShipmentId}
              onClose={handleDocumentQuickViewClose}
            />
          }
        </CheckIfAuthorized>
        :
        <MissingOrDeniedCompanyAlert />}

    </FullWidthLayout>
  );
};

export default ComponentBuilder
  .wrap(ShipmentHistoryPage)
  .stateToProps((state, ownProps) => {
    const { shipments, count: shipmentCount } = selectCustomerShipmentsSearch(state, ShipmentHistorySearchId) || {};
    return {
      company: state.addressBook.modification.company,
      isAdmin: useIsAdministrator(),
      isAgencyOps: useIsAgencyOps(),
      modifyingAssociate: state.persona.modifyingAssociate,
      associate: state.persona.associate,
      shipments,
      shipmentCount
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async searchCustomerShipments(companyId, fromDate, toDate, shipmentNumber, offset, limit, sort, viewBlindOnly, viewHazMatOnly, viewMultiStopsOnly, equipmentType) {
        dispatch(shell.actions.sys.processStart(SearchProcessName));
        dispatch(await shell.actions.shipment.customer
          .searchCustomerShipments(ShipmentHistorySearchId, companyId, fromDate, toDate, shipmentNumber, false, offset, limit, sort, viewBlindOnly, viewHazMatOnly, viewMultiStopsOnly, equipmentType));
        dispatch(shell.actions.sys.processComplete(SearchProcessName));
      },
      async clearCustomerSearch() {
        dispatch(await shell.actions.shipment.customer.clearCustomerShipmentsSearch(ShipmentHistorySearchId));
      },
      async load(companyId) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.addressBook.modification.loadCompany(companyId));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.addressBook.modification.dispose());
      }
    };
  })
  .build();
