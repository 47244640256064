import React, {useEffect, useRef} from "react";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";
import "./agencyUser.scss";
import TgfFormComponent from "../../../../core/kendo/form/tgfFormComponent/TgfFormComponent";
import {TgfFormGrid} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfFormGrid";
import {TgfCustomFormField} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfCustomFormField";
import _ from "lodash";
import {useDispatch} from "react-redux";
import {TgfButtonFormField} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfButtonFormField";
import {Typography} from "@material-ui/core";

export const DeactivateUserModal = (props) => {
    const {
        reloadGrid,
        closeEditAgencyUserModal,
        closeDeactivationOpenEditAgencyUserModal,
        incomingAssociate,
        loadAgencyAccessFilters,
        agencyAccessFilters,
    } = props;

    let selectedAssociate = incomingAssociate;

    const ref = useRef();
    const personalDispatch = useDispatch();

    // initial load
    useEffect(() => {
        ref.current?.loadForm();
    }, []);

    const loadFormData = (loadValue) => {
        const formValues = {
            entity: _.cloneDeep(loadValue.selectedAssociate),
        };

        return formValues;
    };

    const gridList = [
        new TgfFormGrid(
            {
                spacing: 2,
                style: {paddingLeft: 9, paddingTop: 8},
                fieldList: [
                    new TgfCustomFormField({
                        columnWidth: 12,
                        displayElementFunction: (isEditMode, data, validationErrors) => {
                            return <Typography variant="body">
                                <label className={"modal-label"}>
                                    User:
                                </label>
                                {' '}{data.entity.firstName}{' '}{data.entity.lastName}
                            </Typography>;
                        },
                    }),
                    new TgfCustomFormField({
                        columnWidth: 12,
                        displayElementFunction: (isEditMode, data, validationErrors) => {
                            return <Typography variant="body">
                                Click the "Confirm Deactivation" button below to log this user out of the CRM and{' '}
                                deactivate their access.
                            </Typography>;
                        },
                    }),
                    new TgfCustomFormField({
                        columnWidth: 12,
                        displayElementFunction: (isEditMode, data, validationErrors) => {
                            return <Typography
                                variant={"h6"} style={{color: "#dc3545", fontWeight: "bold"}}
                            >
                                This deactivation process should only be used to quickly remove the user's CRM access
                                when the need arises. Tallgrass personnel MUST be notified if the user is being
                                permanently terminated. Keep in mind that only Tallgrass personnel can reactivate a
                                user's CRM access.
                            </Typography>;
                        },
                    }),
                    new TgfCustomFormField({
                        columnWidth: 12,
                        displayElementFunction: (isEditMode, data, validationErrors) => {
                            return <Typography
                                variant={"h6"}
                                style={{paddingTop: 7, paddingBottom: 10, color: "#007bff", fontWeight: "bold"}}
                            >
                                If this user is a Restricted user and they are being permanently terminated, this
                                could affect your visibility to what customers they were the owners of or assigned to.
                                Let the back office know immediately in such situations so they can make the
                                transition/reassigning of accounts an easy process.
                            </Typography>;
                        },
                    }),
                    new TgfButtonFormField({
                        columnWidth: 4,
                        variant: "contained",
                        color: "primary",
                        type: "submit",
                        disabledFunction: (isEditMode, data, validationErrors) => {
                            const disabled = validationErrors !== null
                                || (
                                    data.entity.isHeadOfAgency === true
                                );
                            return disabled;
                        },
                        contentFunction: (isEditMode, data, validationErrors) => {
                            return "CONFIRM DEACTIVATION";
                        },
                        onClickHandler: async () => {
                            try {
                                await ref.current?.submitForm();
                                await personalDispatch(
                                    await window.shell.actions.sys.sendSnackbarMessage(
                                        {content: 'Agency user changes saved.'},
                                    ),
                                );
                            } catch {
                                personalDispatch(
                                    await window.shell.actions.sys.sendSnackbarMessage(
                                        {content: 'Error. Please contact IT.'},
                                    ),
                                );
                            } finally {
                                closeDeactivationOpenEditAgencyUserModal();
                            }
                        },
                    }),
                    new TgfButtonFormField({
                        columnWidth: 2,
                        variant: "contained",
                        color: "default",
                        type: "submit",
                        contentFunction: (isEditMode, data, validationErrors) => {
                            return "Cancel";
                        },
                        onClickHandler: (isEditMode, data, validationErrors) => {
                            closeDeactivationOpenEditAgencyUserModal();
                            ref.current?.loadForm();
                        },
                    }),
                ],
            },
        ),
    ];

    const handleSubmit = async (data) => {
        if (data) {
            const associateId = _.cloneDeep(data?.entity?.id);

            await window.shell.gateway.deactivateAgencyOpsAssociate(associateId);

            closeEditAgencyUserModal();

            reloadGrid();
        }
    };

    return (
        <TgfDialog
            title={`Deactivate CRM Access for User`}
            onClose={closeDeactivationOpenEditAgencyUserModal}
            width={800}
        >
            <TgfFormComponent
                ref={ref}
                onLoadFormData={loadFormData}
                loadFormArgument={{
                    selectedAssociate: selectedAssociate,
                }}
                onSubmit={handleSubmit}
                preRenderComponent={<>Loading...</>}
                processName={'edit-agency-user'}
                gridList={gridList}
                {...props}
            />
        </TgfDialog>
    );
};
