import {TgfBaseFormField} from "./TgfBaseFormField";
import FieldError from "../../../../../crm/components/inputs/FieldError";
import React from "react";
import {Label} from "@progress/kendo-react-labels";
import {DropDownList} from "@progress/kendo-react-dropdowns";

export class TgfDropDownListFormField extends TgfBaseFormField {
    constructor({
                    columnWidth = 1,
                    id = 'drop-down-list',
                    name = 'drop-down-list',
                    label = 'DropDownList',
                    bindingValue = 'entity.value',
                    validationBindingValue = 'entity.value',
                    dataBindingValue = 'entity.additionalData.value',
                    required = false,
                    defaultValue = null,
                    disabledFunction = (isEditMode, data, validationErrors) => {
                        return false;
                    },
                    isVisibleFunction = (isEditMode, data, validationErrors) => {
                        return true;
                    },
                }) {
        super(columnWidth, bindingValue, validationBindingValue);

        this.id = id;
        this.name = name;
        this.label = label;
        this.required = required;
        this.dataBindingValue = dataBindingValue;
        this.required = required;
        this.defaultValue = defaultValue;
        this.disabledFunction = disabledFunction;
        this.isVisibleFunction = isVisibleFunction;
    }

    handleStringIdChange(e) {
        this.setValue(this.bindingValue, e.target.value?.id);
    };

    getDataBindingValue(data) {
        return this.findByPath(data, this.dataBindingValue);
    }

    getIdDataBindingValue(data) {
        let idValue = this.findByPath(data, this.bindingValue);
        if ((idValue == 0 || idValue == null) && this.defaultValue > 0)
            idValue = this.defaultValue;

        const pairs = this.getDataBindingValue(data);
        return pairs.find((obj) => obj.id === idValue);
    }

    renderField(setValue, data, validationErrors, isEditMode) {
        this.setValue = setValue;
        if (this.isVisibleFunction(isEditMode, data, validationErrors)) {
            return <>
                <Label editorId={this.id}>{this.label}</Label>
                <DropDownList
                    id={this.id}
                    name={this.name}
                    data={this.getDataBindingValue(data)}
                    textField="display"
                    dataItemKey="id"
                    value={this.getIdDataBindingValue(data)}
                    onChange={this.handleStringIdChange.bind(this)}
                    style={{
                        width: "100%",
                    }}
                    disabled={this.disabledFunction(isEditMode, data, validationErrors)}
                />
                <FieldError shouldRender={isEditMode}>{this.getValidationBindingValue(validationErrors)}</FieldError>
            </>;
        } else {
            return <></>;
        }
    }
}
