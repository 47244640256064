import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Menu, MenuItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import combineClassNames from '../../../utils/combineClassNames';
import isFranchiseModeEnabled from "../../../../hubs/personaV2/selectors/isFranchiseModeEnabled";

const useStyles = makeStyles((theme) => ({
    navButtons: {
        backgroundColor: theme.palette.primary.main,
        marginRight: 5,
        color: theme.palette.white,
        paddingRight: 10,
        paddingLeft: 10,
        opacity: .7,
        '&:hover': {
            backgroundColor: theme.palette.tgfBlack.main,
            opacity: 1,
            color: theme.palette.white,
            boxShadow: 'none',
        },
        '&:focus': {
            backgroundColor: theme.palette.tgfBlack.main,
            opacity: 1,
            outline: 'none',
        },
    },
    activetrue: {
        opacity: 1,
        backgroundColor: theme.palette.tgfBlack.main,
        outline: 'none',
    },
    menuLink: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    menuDropdown: {
        marginTop: 30,
    },
}));

const MainTopMenu = ({isAdmin, isHeadOfAgency, associateName, currentLoc, logout, navLinks, ...props}) => {
    const classes = useStyles();
    const franchiseModeEnabled = isFranchiseModeEnabled();

    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);

    const canAccessLink = (isAdmin, isHeadOfAgency) => (link) => {
        let canAccessLink;
        if (link.isRestrictedToAdminOnly) {
            canAccessLink = isAdmin;
        } else if (link.isRestrictedToFranchiseMode) {
            canAccessLink = (employeeContext?.claims?.isHeadOfAgency && franchiseModeEnabled) || (employeeContext?.claims?.isAdministrator && employeeContext?.claims?.canViewAgencyPage);
        } else {
            canAccessLink = true;
        }
        return canAccessLink;
    };

    const attachIsActive = (currentLoc) => (link) => {
        link.isActive = currentLoc === link.key;
        return link;
    };

    return (
        <>
            {
                navLinks
                    .filter(canAccessLink(isAdmin, isHeadOfAgency))
                    .map(attachIsActive(currentLoc))
                    .map((item) => {
                            return (
                                item.submenus ?
                                    <div key={item.key}>
                                        <Button color="inherit" onClick={item.handleAddMenu} className={classes.navButtons}>
                                            {item.title}
                                        </Button>
                                        <Menu
                                            id={item.key}
                                            anchorEl={item.addOpen}
                                            keepMounted
                                            open={item.openAddDropdown}
                                            onClose={item.handleAddClose}
                                            className={classes.menuDropdown}
                                        >
                                            {item.submenus.map((mitem, key) =>
                                                <MenuItem
                                                    component={Link}
                                                    key={key}
                                                    to={mitem.url}
                                                    className={classes.newItem}
                                                >
                                                    {mitem.title}
                                                </MenuItem>,
                                            )}
                                        </Menu>
                                    </div>
                                    :
                                    <Button
                                        component={Link}
                                        to={item.url}
                                        key={item.key}
                                        className={combineClassNames(classes.navButtons, classes[`active${item.isActive}`])}
                                        size="medium"
                                        variant="contained"
                                        disableElevation
                                    >
                                        {item.title}
                                    </Button>
                            );
                        },
                    )
            }
        </>
    );
};
export default MainTopMenu;
