import {Grid} from '@material-ui/core';
import React, {forwardRef} from "react";
import {useDispatch} from "react-redux";
import TgfDataGrid from "../../../../../core/kendo/dataGrid/TgfDataGrid";
import {
    AssignedOwnerCell,
    AssignedUsersCell,
    CompanyLinkCell,
    EditAssignmentCell,
    HasVisibilityCell,
    IsActiveCell,
    LastNoteCell
} from "./agencyCompanyGridCells";
import {HeaderInfoIconTooltipCell} from "../../../../../core/kendo/dataGrid/cells/HeaderInfoIconTooltipCell";


export const AgencyCompanyGrid = forwardRef(function AgencyUsersGrid(props, ref) {
    const {
        type,
        handleHasVisibilityClick,
        handleEditAssignmentClick,
        associateFilter,
        companyAssociateId,
        showOnlyActiveCustomers,
        showOnlyInactiveCustomers,
        selectedCompany
    } = props;

    const personalDispatch = useDispatch();

    const loadAgencyCompanyListing = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAgencyCustomerListing'));
            let additionalParams = null;
            if(associateFilter) {
                const associateFilterId = Object.values(associateFilter).find(value => value !== null && value !== undefined)?.id || null;
                if(associateFilterId !== -1) {
                    additionalParams = {actAsAssociateId: associateFilterId};
                }
            }
            const results = await window.shell.gateway.getPageOfAgencyCompanies(odata, additionalParams);
            return results;
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAgencyCustomerListing'));
        }
    };

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0,
        };
        odata.filter.companyAssociateId = companyAssociateId;
        if(showOnlyActiveCustomers) odata.filter.isActive = true;
        if(showOnlyInactiveCustomers) odata.filter.isActive = false;
        if(associateFilter?.agencyCompanyOwner?.id === -1) {
            odata.filter.assignedOwnerName = "UNASSIGNED";
        }
        if(associateFilter?.agencyAssignedUser?.id === -1) {
            odata.filter.assignedUsersNames = "NONE";
        }
        if(associateFilter?.agencyCompanyOwner && associateFilter?.agencyCompanyOwner.id !== -1) {
            odata.filter.ownerId = associateFilter.agencyCompanyOwner?.id;
        }
        if(selectedCompany) {
            odata.filter.companyId = selectedCompany?.companyId;
        }
        if(type === "customer") {
            odata.filter.companyTypeId = 1;
        } else {
            odata.filter.companyTypeId = 5
        }
        return odata;
    };

    const tooltipTitles = {
        ownerColumn: (
            <>
                <p>Displays the Restricted User who is assigned as the {type === "customer" ? "Customer" : "Lead"} Owner. If the {type === "customer" ? "customer" : "lead"} doesn't have an owner assigned, it will display UNASSIGNED.</p>
                <p>Names displaying in red are currently inactive Restricted users. If they are permanently terminated, let the back office know immediately so they can make the transition/reassigning of accounts an easy process.</p>
            </>
        ),
        assignedColumn: (
            <>
                <p>Displays Restricted Users who are assigned as Assigned Users. If the {type === "customer" ? "customer" : "lead"} doesn't have any users assigned, it will display NONE.</p>
                <p>Names displaying in red are currently inactive Restricted users. If they are permanently terminated, let the back office know immediately so they can make the transition/reassigning of accounts an easy process.</p>
            </>
        ),
        hasVisibilityColumn: (
            <>
                <p>Displays any active user who has visibility of the {type === "customer" ? "customer" : "lead"} along with their Visibility Permission setting. Inactive users do not display.</p>
                <p>Working with the back office when terminating a Restricted user is important as they can make the transition/reassigning of accounts an easy process.</p>
            </>
        ),
        lastNoteDateColumn: (
            <p>Provides the date of the last note entered on the {type === "customer" ? "customer's" : "lead's"} Notes page.</p>
        ),
        lastNoteColumn: (
            <p>Provides the last note entered on the {type === "customer" ? "customer's" : "lead's"} Note page, along with who entered it and when.</p>
        )
    }

    const gridColumns = [
        {
            title: `${type === "customer" ? "Customer" : "Company"} Name`,
            field: 'companyName',
            type: 'custom',
            cell: CompanyLinkCell,
            sortable: true,
        },
        {
            title: <HeaderInfoIconTooltipCell headerTitle={"Assigned Owner"} tooltipTitle={tooltipTitles.ownerColumn} />,
            field: 'assignedOwnerName',
            type: 'custom', sortable: true,
            cell: AssignedOwnerCell
        },
        {
            title: <HeaderInfoIconTooltipCell headerTitle={"Assigned Users"} tooltipTitle={tooltipTitles.assignedColumn} />,
            field: 'assignedUsersNames',
            type: 'custom',
            sortable: false,
            cell: AssignedUsersCell
        },
        {
            title: <HeaderInfoIconTooltipCell headerTitle={"Has Visibility"} tooltipTitle={tooltipTitles.hasVisibilityColumn} />,
            field: 'Has Visibility',
            type: 'custom',
            cell: (props) =>
                    <HasVisibilityCell
                        {...props}
                        handleHasVisibilityClick={handleHasVisibilityClick}
                    />,
            sortable: false,
        },
        {
            title: <HeaderInfoIconTooltipCell headerTitle={"Last Note Date"} tooltipTitle={tooltipTitles.lastNoteDateColumn} />,
            field: 'latestNoteDate', type: 'date', className: "center"},
        {
            title: <HeaderInfoIconTooltipCell headerTitle={"Last Note"} tooltipTitle={tooltipTitles.lastNoteColumn} />,
            field: 'latestNote',
            width: 100,
            type: 'custom',
            cell: (props) => <LastNoteCell {...props} />,
            sortable: false,
        },
        {
            title: ' ',
            field: '',
            type: 'custom',
            cell: (props) =>
                <EditAssignmentCell
                    {...props}
                    handleEditAssignmentClick={handleEditAssignmentClick}
                />,
            width: 40,
            sortable: false,
        },
    ];

    if (type === "customer") {
        gridColumns.splice(1, 0, {title: 'Disabled', field: 'isActive', type: 'custom', sortable: true, cell: IsActiveCell});
        gridColumns.splice(2, 0,{field: 'lastPickupDate', title: 'Last Pickup Date', type: 'date', sortable: true});
    }
    let sort = type === "customer" ? [{field: "lastPickupDate", dir: "desc"}] : [{field: "companyName", dir: "asc"}]
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TgfDataGrid
                    ref={ref}
                    onLoadDataPage={loadAgencyCompanyListing}
                    gridColumns={gridColumns}
                    onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                    sort={sort}
                    skipInitialLoad={true}
                />
            </Grid>
        </Grid>
    );
});
