import {useDispatch} from "react-redux";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import "../tgf-form.scss";

const Form = (props) => {
    return <>{props.gridList.map((grid, i) => grid.renderGrid(props.setValue, props.data, props.validationErrors, props.isEditMode))}</>;
};

const TgfFormComponent = forwardRef(function TgfFormComponent(props, ref) {

    const {
        onLoadFormData,
        loadFormArgument,
        validationSchema,
        onSubmit,
        preRenderComponent,
        processName,
        gridList,
    } = props;

    const personalDispatch = useDispatch();

    const [data, setData] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [validationErrors, setValidationErrors] = useState(null);
    const [isRenderable, setIsRenderable] = useState(false);

    useImperativeHandle(ref, () => ({
        async loadForm() {
            await loadForm();
        },
        async setValue() {
            await setValue();
        },
        async setIsEditMode(value) {
            setIsEditMode(value);
        },
        async submitForm() {
            await onSubmit(data);
        },
    }));

    useEffect(() => {
        validateFormData();
    }, [data]);

    const assign = (obj, keyPath, value) => {
        const keys = keyPath.split('.');
        const lastKey = keys.pop();
        const lastObj = keys.reduce((obj, key) =>
                obj[key] = obj[key] || {},
            obj);
        lastObj[lastKey] = value;
    };

    const loadForm = async () => {

        const returnData = {
            success: null,
            errorMessage: null,
        };

        let data = {};

        try {
            personalDispatch(window.shell.actions.sys.processStart(processName));

            try {
                try {
                    data = await onLoadFormData(loadFormArgument);
                    returnData.success = true;
                } catch (e) {
                    returnData.success = false;
                    returnData.errorMessage = e.message;
                    data.error = e.message;
                }
            } catch (e) {
                returnData.success = false;
                returnData.errorMessage = e.message;
                data.error = e.message;
            }

            if (!returnData.success) {
                setIsRenderable(false);
            } else {
                setData(data);
                setIsRenderable(true);
            }
        } catch (e) {
            returnData.success = false;
            returnData.errorMessage = e.message;
            data.error = e.message;
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete(processName));
        }

        return returnData;
    };

    const validateFormData = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('validating' + processName));
            try {
                if (validationSchema === null) return;
                await validationSchema.validate(data, {abortEarly: false});
                setValidationErrors(null);
            } catch (e) {
                const errors = {};
                for (let error of e.inner) {
                    assign(errors, error.path, error.message.replace(`${error.path}`, ""));
                }
                setValidationErrors(errors);
            }
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('validating' + processName));
        }
    };

    const submitForm = async () => {
        onSubmit(data);
    };

    const setValue = (name, value) => {

        assign(data, name, value);

        setData({
            ...data,
        });
    };

    return (
        <>
            {
                isRenderable ?
                    <Form
                        data={data}
                        validationErrors={validationErrors}
                        formIsValid={validationErrors === null}
                        isEditMode={isEditMode}
                        loadForm={loadForm}
                        setValue={setValue}
                        submitForm={submitForm}
                        gridList={gridList}
                        {...props}
                    >

                    </Form>
                    : [preRenderComponent]
            }
        </>
    );
});

export default TgfFormComponent;
