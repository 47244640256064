import React from "react";
import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import TgfDialog from "./TgfDialog";

const ConfirmationModal = (props) => {

    const {
        title,
        onClose,
        description,
        action,
        confirmBtnText,
        cancelBtnText,
    } = props;

    return (
        <TgfDialog
            title={title}
            onClose={onClose}
            width={500}
        >
            <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <span>{description}</span>
                </Grid>
                <Grid item>
                    <button
                        style={{padding: "4px 18px", cursor: "pointer"}}
                        onClick={action}
                    >
                        {confirmBtnText ?? "YES"}
                    </button>
                </Grid>
                <Grid item>
                    <button
                        style={{padding: "4px 18px", cursor: "pointer"}}
                        onClick={onClose}
                    >
                        {cancelBtnText ?? "NO"}
                    </button>
                </Grid>
            </Grid>
        </TgfDialog>
    );
};

ConfirmationModal.propTypes = {
    // Title Header.
    title: PropTypes.string.isRequired,
    // Short description.
    description: PropTypes.string.isRequired,
    // A delete handler function.
    action: PropTypes.func.isRequired,
    // A onClose handler function that closes this modal.
    onClose: PropTypes.func.isRequired,
};

export default ConfirmationModal;