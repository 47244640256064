import AppMuiCard from "../../../../../core/components/cards/AppCard";
import {
    Chart, ChartSeries, ChartSeriesItem, ChartSeriesItemTooltip, ChartTooltip, ChartLegend, ChartArea
} from "@progress/kendo-react-charts";
import * as PropTypes from "prop-types";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Grid} from "@material-ui/core";
import './MarginContributionChart.scss'
import TgfAssociateAutoComplete from "../../../../../core/kendo/form/inputs/specialty/TgfAssociateAutoComplete";
import useIsAdministrator from "../../../../../hubs/personaV2/selectors/useIsAdministrator";

ChartSeries.propTypes = {children: PropTypes.node};
const MarginContributionChart = () => {

    const LoadProcessName = 'Charting.MarginContribution.Loaded';

    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);

    const isAdmin = useIsAdministrator();
    const activeAssociateId = employeeContext?.agency?.id;
    const personalDispatch = useDispatch();

    const [filterAssociate, setFilterAssociate] = React.useState(null);
    const [chartSeries, setChartSeries] = React.useState(null);

    useEffect(() => {
        try {
            personalDispatch(window.shell.actions.sys.processStart(LoadProcessName));

            if (filterAssociate !== null) {
                window.shell.gateway.getMarginContributionChart(filterAssociate).then((data) => {
                    setChartSeries(data);
                })
            }

        } catch (e) {

        } finally {
            personalDispatch(window.shell.actions.sys.processComplete(LoadProcessName));
        }
    }, [filterAssociate]);


    useEffect(() => {
        try {
            personalDispatch(window.shell.actions.sys.processStart(LoadProcessName));

            if (!isAdmin && chartSeries === null) {
                window.shell.gateway.getMarginContributionChart(activeAssociateId).then((data) => {
                    setChartSeries(data);
                })
            }

        } catch (e) {

        } finally {
            personalDispatch(window.shell.actions.sys.processComplete(LoadProcessName));
        }
    }, []);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2
    });

    function handleFilterAssociateChange(e) {
        const value = e.target.value ?? "";
        setFilterAssociate(value.id);
    }

    return (

      <AppMuiCard title="Margin Contribution" height={"480px"}>
          <div className={"margin-contribution-chart-card"}>
              <Grid container spacing={1} direction={"row"} justifyContent={"center"}>
                  <Grid item xs={12} className={"margin-contribution-chart-heading-text"}>
                      {isAdmin && <>
                          <TgfAssociateAutoComplete
                            id={"filterAssociate"}
                            name={"filterAssociate"}
                            onChange={handleFilterAssociateChange}
                          />
                          <hr/>
                      </>}
                  </Grid>
                  <Grid item xs={12} className={"margin-contribution-chart-heading-text"}>
                      <h3>Current Month Margin Contribution</h3>
                      <p>Hover over the chart for further details</p>
                  </Grid>
                  <Grid item xs={12} className={"margin-contribution-chart-div"}>
                      {chartSeries?.length > 0 &&
                        <Chart transitions={false}>
                            <ChartArea height={240}/>
                            <ChartTooltip/>
                            <ChartLegend visible={false}/>
                            <ChartSeries>
                                <ChartSeriesItem
                                  type="pie"
                                  data={chartSeries}
                                  field="value"
                                  categoryField="category"
                                >
                                    <ChartSeriesItemTooltip render={(data) => {
                                        return `${data.point?.dataItem?.category} - ${formatter.format(data.point?.dataItem?.margin)} of ${formatter.format(data.point?.dataItem?.totalMargin)} - ${data.point?.value.toLocaleString(undefined, {
                                            style: 'percent', minimumFractionDigits: 2
                                        })}`
                                    }}
                                    />
                                </ChartSeriesItem>
                            </ChartSeries>
                        </Chart>
                      }
                      {(chartSeries == null || false || chartSeries?.length === 0) &&
                        <p>No data is currently available</p>
                      }
                  </Grid>
                  <Grid item xs={12} className={"margin-contribution-chart-footer-link"}>
                      <p>All customers that contribute less than 5% are grouped as "Other Customers" above.</p>
                      <Link id={"margin-contribution-chart-main-link"} to={`/reports/margin-contribution`}>
                          Click here to go to full report
                      </Link>
                  </Grid>
              </Grid>
          </div>
      </AppMuiCard>

    );
}
export default MarginContributionChart
