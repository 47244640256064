import React from "react";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import { Grid} from "@material-ui/core";
import LeftNav from "../LeftNav";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import RiskReviewReportNavBar from "./includes/RiskReviewReportNavBar";
import RiskReviewReportListingGrid from "./includes/RiskReviewReportListingGrid";
import AccessDeniedPage from "../../errors/AccessDeniedPage";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";

const RiskReviewReport = (props) => {
    usePageTitle("TGF: Risk Review Report");

    return (
        useIsAdministrator() ?
        <FullWidthLayout SideNav={LeftNav} title={"Risk Review Report"} className={"risk-review-report"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <RiskReviewReportNavBar />
                </Grid>
                <Grid item xs={12}>
                    <RiskReviewReportListingGrid />
                </Grid>
            </Grid>
        </FullWidthLayout> : <AccessDeniedPage />
    )
};

export default RiskReviewReport;
