import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";
import {Button, Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import TgfCollectorComboBox from "../../../../core/kendo/form/inputs/specialty/TgfCollectorComboBox";
import ConfirmationModal from "../../../../core/kendo/dialog/ConfimationModal";

const CollectorsAssignmentDialog = (props) => {
    const personalDispatch = useDispatch();
    const [assignedCollectorId, setAssignedCollectorId] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const handleCollectorChange = (e) => {
        setAssignedCollectorId(e.target.value?.id);
        setIsDirty(true)
    }

    useEffect(() => {
        if(props.data?.assignedCollectorId) setAssignedCollectorId(props.data.assignedCollectorId);
    }, [props.data]);

    const save = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveAssignedCollector'));

            await window.shell.gateway.saveAssociateCollectorAssignment(props.data.agentId, assignedCollectorId)

            setIsDirty(false);
            props.onCloseDialog();

        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Save The Assigned Collector',
                color: 'error'
            }));
        } finally {
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Assigned Collector Saved'}));
            personalDispatch(window.shell.actions.sys.processComplete('saveAssignedCollector'));
        }
    }

    return (
        <TgfDialog title={"Edit Assigned Collector"}
                   onClose={props.onCloseDialog}
                   width={500}>
            <Grid container spacing={2} alignItems={"flex-end"}>
                <Grid item xs={12}>
                    <TgfCollectorComboBox
                        id={"assignedCollector"}
                        name={"assignedCollector"}
                        onChange={handleCollectorChange}
                        value={assignedCollectorId}
                        label={"Assigned Collector"}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        type={"button"}
                        disabled={!isDirty}
                        onClick={() => setConfirmationModalOpen(true)}
                    >
                        Submit
                    </Button>
                </Grid>
                {confirmationModalOpen &&
                    <ConfirmationModal
                        title={`Confirm Collector Assignment`}
                        description={`Confirm you are wanting to change the Assigned Collection Associate for all customers for ${props.data?.agentFirstName} ${props.data?.agentLastName}.`}
                        action={save}
                        onClose={() => setConfirmationModalOpen(false)}
                    />
                }
            </Grid>
        </TgfDialog>
    )

}

export default CollectorsAssignmentDialog
