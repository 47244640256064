import React from 'react';
import {Redirect} from "react-router-dom";


const CheckIfAuthorized = (props) => {
  const {
    isAdmin,
    modifyingAssociate,
    company,
    children
  } = props

  if(!company || !company.id) {
      return (children);
  }

  if(!isAdmin && modifyingAssociate.id !== company.associateId && !isAdmin && modifyingAssociate.parentAssociateId !== company.associateId) {
    return <Redirect to={"404"}/>;
  }
  // else
  return (children);
}

export default CheckIfAuthorized;