import {Route} from "react-router-dom";
import AccessDeniedPage from "../errors/AccessDeniedPage";
import React from "react";
import CrmDataInitializer from "../../crm/components/CrmDataInitializer";
import useIsAdministrator from "../../hubs/personaV2/selectors/useIsAdministrator";

const AdministratorRoute = (props) => {

    const {
        component,
        render,
        isAuthorized,
        ...routeProps
    } = props;

    const safeRender = (safeRenderProps) => {
        const DelegateComponent = !!component ? component : render;
        return (<CrmDataInitializer><DelegateComponent {...safeRenderProps} /></CrmDataInitializer>);
    };

    return (
        useIsAdministrator() ?
            <Route
                {...routeProps}
                render={safeRender}
            />
            : <AccessDeniedPage />
    );
}
export default AdministratorRoute;