import React from 'react';
import { Grid} from '@material-ui/core';
import AppCard from '../../../../core/components/cards/AppCard';
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';
import AppDateText from '../../../../core/components/text/AppDateText';
import AppIntegerText from '../../../../core/components/text/AppIntegerText';
import AppPercentText from '../../../../core/components/text/AppPercentText';
import AppText from '../../../../core/components/text/AppText';
import AppBooleanText from '../../../../core/components/text/AppBooleanText';
import ComponentBuilder from '../../../../core/ComponentBuilder';
import PropTypes from 'prop-types';
import {Edit as EditIcon} from '@material-ui/icons';
import AppIconButton from '../../../../core/components/AppButton/AppIconButton';

const AceOnlyFields = [
  'autoCreditIncreaseIncrement',
  'autoCreditIncreaseThreshold',
  // 'companyAllowInvoiceBackdating'
];

const DashWrapper = (Comp) => ({ value, ...otherProps }) =>
  value !== undefined && value !== null ? <Comp value={value} {...otherProps} /> :
    <AppText value={'-'} {...otherProps} />;

const FieldDisplayConfig = [
  ['First Pickup Date', 'firstShipmentDate', DashWrapper(AppDateText)],
  ['Last Pickup Date', 'lastShipmentDate', DashWrapper(AppDateText)],
  ['Lifetime Shipment Total', 'customerTotalShipments', DashWrapper(AppIntegerText)],
  ['Lifetime Revenue Total', 'customerTotalRevenue', DashWrapper(AppCurrencyText)],
  ['Lifetime Margin Total', 'customerTotalMargin', DashWrapper(AppCurrencyText)],
  ['Lifetime Average GM per Shipment', 'customerAverageMarginPerShipment', DashWrapper(AppCurrencyText)],
  ['Lifetime Average Gross Margin %', 'averageMarginPercentageLifetime', DashWrapper(AppPercentText)],
  ['Average Total Monthly Margin', 'averageTotalMonthlyMargin', DashWrapper(AppCurrencyText)],
  ['Average Days to Pay (90-day avg)', 'averageDaysToPayRolling90', DashWrapper(AppIntegerText)],
  ['D&B Score', 'dunBradstreetScore', DashWrapper(AppText)],
  ['Equifax Score', 'paydexScore', DashWrapper(AppText)],
  ['D&B Avg. Days to Pay', 'paydexAverageDaysToPay', DashWrapper(AppText)],
  ['FEIN #', 'feinNumber', DashWrapper(AppText)],
  ['DUNS #', 'dunsNumber', DashWrapper(AppText)],
  ['Credit Limit', 'companyCreditLimit', DashWrapper(AppCurrencyText)],
  ['Credit Used', 'companyCreditUsed', DashWrapper(AppCurrencyText)],
  ['Credit Remaining', 'companyCreditRemaining', DashWrapper(AppCurrencyText)],
  ['Current Total Amount Owed', 'customerTotalAmountDue', DashWrapper(AppCurrencyText)],
  ['Auto-Credit Threshold Limit', 'autoCreditIncreaseThreshold', DashWrapper(AppCurrencyText)],
  ['Auto-Credit Increase Increment', 'autoCreditIncreaseIncrement', DashWrapper(AppCurrencyText)],
  ['Marked as Bad Debt', 'creditRiskIndicator', DashWrapper(AppBooleanText)],
  // ['Allow Invoice Backdating', 'companyAllowInvoiceBackdating', DashWrapper(AppBooleanText)]
];

const FinancialSummaryCard = (props) => {

  const {
    isAdmin,
    classes,
    companyFinancialHealthSummary,
    onEditClick
  } = props;

  const fieldElements = FieldDisplayConfig
    .filter(([title, fieldName]) => isAdmin || !AceOnlyFields.includes(fieldName))
    .map(([title, fieldName, FieldComponent, valueProcessor], index) => {
      return {
        title,
        element: companyFinancialHealthSummary ?
          (<FieldComponent key={index} value={valueProcessor ? valueProcessor(companyFinancialHealthSummary[fieldName]) : companyFinancialHealthSummary[fieldName]} />) :
          '-'
      };
    });

  const editAction = isAdmin ?
    (
      <AppIconButton
        color={'primary'}
        Icon={EditIcon}
        tooltip={'Testing'}
        onClick={() => onEditClick()}/>
    ) :
    null;

  return (
    <AppCard title={'Financial Summary'} action={editAction} height={'auto'}>
      <Grid container spacing={1}>
        {
          fieldElements
            .map(({ title, element }, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={8} className={classes.infoTitle}>
                    {title}
                  </Grid>
                  <Grid item xs={4} className={classes.infoValue}>
                    {element}
                  </Grid>
                </React.Fragment>
              );
            })
        }
      </Grid>
</AppCard>
  );
};

const styles = (theme) => ({
  infoTitle: {
    fontSize: '1.1em'
  },
  infoValue: {
    fontSize: '1.2em',
    fontWeight: 'bold'
  }
});

export default ComponentBuilder
  .wrap(FinancialSummaryCard)
  .props({
    isAdmin: PropTypes.bool.isRequired,
    companyFinancialHealthSummary: PropTypes.object,
    onEditClick: PropTypes.func
  })
  .withStyles(styles)
  .build();
