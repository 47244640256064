import React from 'react';
import {Grid} from '@material-ui/core';

import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import {makeStyles} from "@material-ui/core/styles";
import {CheckCircle} from "@material-ui/icons";
import AppCard from "../../../../core/components/cards/AppCard/AppMuiCard";
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";


const useStyles = makeStyles(theme => ({
    centerCells: {
        textAlign: 'center'
    },
    greenPeriod: {
        color: theme.palette.tgfGreen.main
    },
    bluePeriod: {
        color: theme.palette.tgfBlue.main
    },
    lessThanZero: {
        color: theme.palette.error.main
    }
}));

const AssociateCell = (associate) => () => {
    if (!associate)
        return null;
    return (
        <div>
            {associate.firstName} {associate.lastName} ({associate.systemId})
        </div>
    );
};

const RepPaidCell = (classes) => ({rowData: adjustment}) => adjustment?.repPaid ?
    <CheckCircle className={classes[`${adjustment.repPaidPeriod}Period`]} /> : null;

const AdjustmentAmountCell = (classes) => ({rowData: adjustment}) => {
    const amount = adjustment.additionAmount ?
        adjustment.additionAmount :
        -adjustment.deductionAmount;
    return <AppCurrencyText value={amount} className={parseFloat(amount) < 0 ? classes.lessThanZero : null} />;
};

const Adjustments = (props) => {
    const classes = useStyles();

    const {
        adjustments,
        adjustmentCount,
        associate,
        onPageChange,
        onLimitChange,
        onSortChange,
        offset,
        limit,
        sort,
        agencyMode,
    } = props;

    const gridColumns = [
        {
            title: 'Rep',
            field: 'rep',
            styles: {
                width: '10%',
            },
            dataType: 'component',
            component: AssociateCell(associate)
        },
        {
            title: 'Related Shipment(s)',
            field: 'relatedBolNumber',
            styles: {
                width: '10%',
            },
            dataType: 'string'
        },
        {
            title: 'Amount',
            field: 'additionAmount',
            styles: {
                textAlign: 'center',
                width: '8%',
            },
            dataType: 'component',
            component: AdjustmentAmountCell(classes)
        },
        {
            title: 'Reason for Change',
            field: 'reason',
            styles: {
                width: '18%',
            },
            dataType: 'string'
        },
        {
            title: 'Notes',
            field: 'note',
            styles: {
                width: '35%',
            },
            dataType: 'string'
        },
        {
            title: 'Pay Period',
            field: 'payPeriodDates',
            styles: {
                textAlign: 'center',
                width: '14%',
            },
            dataType: 'string',
            noSort: true
        },
        {
            title: 'Rep Paid',
            field: 'repPaid',
            styles: {
                textAlign: 'center',
                width: '5%',
            },
            dataType: 'component',
            component: RepPaidCell(classes)
        }
    ];

    return (
        <AppCard
            title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                    Adjustments {
                    agencyMode &&
                    <InfoIconTooltip
                        title={
                            `This information does NOT change when the filter and checkbox above are used. It will 
                            always provide you adjustments for your entire agency for the month/year being viewed.`
                        }
                        style={{alignSelf: "center"}}
                    />
                }
                </div>
            }
        >
            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item>
                    <AppDenseGrid
                        count={adjustmentCount}
                        data={adjustments}
                        orderBy={sort[0][1]}
                        order={sort[0][0]}
                        rowsPerPage={limit}
                        page={offset / limit}
                        onChangePage={onPageChange}
                        onChangeRowsPerPage={onLimitChange}
                        onSort={onSortChange}
                        columns={gridColumns}
                    />
                </Grid>
            </Grid>
        </AppCard>
    );
};

export default Adjustments;
