export class TgfBaseFormField {
    constructor(columnWidth, bindingValue, validationBindingValue) {
        this.columnWidth = columnWidth
        this.bindingValue = bindingValue;
        this.validationBindingValue = validationBindingValue;
    }

    findByPath(data, path) {
        return data ? path.split('.').reduce((o, i) => o[i], data) : null;
    }

    getBindingValue(data) {
        return this.findByPath(data, this.bindingValue);
    }

    getValidationBindingValue(validationErrors) {
        return validationErrors ? this.findByPath(validationErrors, this.validationBindingValue) : null;
    }

    renderField() {
        return <></>;
    }

}
