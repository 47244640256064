import React, { useEffect, useMemo, useState } from "react";
import {Box, Grid} from "@material-ui/core";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import ReportNav from "../ReportNav";
import {useDispatch} from "react-redux";
import { Checkbox } from "@progress/kendo-react-inputs";
import "./ShipmentHistoryReport.scss";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import AppDropdown from "../../../core/components/dropdowns/AppDropdown";
import AppDatePicker from "../../../core/components/inputs/AppDatePicker";
import TgfEquipmentTypesComboBox from "../../../core/kendo/form/inputs/specialty/TgfEquipmentTypesComboBox";
import AppInput from "../../../core/components/inputs/AppInput/AppMuiInput";
import AppButton from "../../../core/components/AppButton/AppMuiButton";
import CrmDocumentQuickViewDrawer from "../../../crm/components/CrmDocumentQuickViewDrawer";
import {ShipmentHistoryType} from "../../address-book/ShipmentHistoryPage/includes/ShipmentHistoryType";
import TgfCustomerAutoComplete from "../../../core/kendo/form/inputs/specialty/TgfCustomerAutoComplete";
import ShipmentHistoryListing from "./includes/ShipmentHistoryListing";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import DateUtils from "../../../core/utils/dateUtils/dateUtils";
import CrmAssociateDropDown from "../../../crm/components/CrmAssociateDropDown";
import AppSpinner from "../../../core/components/AppSpinner";
import CrmTypedAddressBookListingComboBox from "../../../crm/components/CrmTypedAddressBookListingComboBox";
import useIsHeadOfAgency from "../../../hubs/personaV2/selectors/useIsHeadOfAgency";
import AccessDeniedPage from "../../errors/AccessDeniedPage";

const ShipmentHistorySearchId = 'AddressBook.ShipmentHistoryPage';

const ShipmentHistoryReport = (props) => {

    const dispatch = useDispatch();
    const state = window.shell.stateStore.getState();
    const isAdmin = useIsAdministrator();
    const isHeadOfAgency = useIsHeadOfAgency();
    const associate = state.persona.associate;

    const initialSort = [['shipmentBolNumber', 'desc']];

    usePageTitle('Shipment History Report');

    const historyTypes = [
        { id: ShipmentHistoryType.Standard, name: 'Standard'},
        { id: ShipmentHistoryType.Detailed, name: 'Shipment Details'}
    ];

    const clearCustomerSearch = async () => {
        dispatch(await window.shell.actions.shipment.customer.clearCustomerShipmentsSearch(ShipmentHistorySearchId));
    };

    const isShipmentDetailFilter = () => {
        return historyType?.id === ShipmentHistoryType.Detailed;
    };

    const [isLoading, setIsLoading] = React.useState(false);
    const [offset, setOffset] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [sort, setSort] = React.useState(initialSort);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('shipmentBolNumber');

    const [startDate, setStartDate] = React.useState(DateUtils.getDateDaysAgo(365).toMoment());
    const [endDate, setEndDate] = React.useState(null);
    const [shipmentNumber, setShipmentNumber] = React.useState('');
    const [docQuickViewShipmentId, setDocQuickViewShipmentId] = React.useState(null);
    const [historyType, setHistoryType] = React.useState(historyTypes[0]);
    const [viewBlindOnly, setViewBlindOnly] = React.useState(false);
    const [viewHazMatOnly, setViewHazMatOnly] = React.useState(false);
    const [viewMultiStopsOnly, setViewMultiStopsOnly] = React.useState(false);
    const [equipmentType, setEquipmentType] = React.useState(null);

    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const [selectedAgent, setSelectedAgent] = React.useState(null);
    const [shipments, setShipments] = useState(null);
    const [shipmentCount, setShipmentCount] = useState(0);

    React.useEffect(() => {
        return () => clearCustomerSearch();
    }, [clearCustomerSearch]);

    React.useEffect(() => {
        performSearch();
    }, [offset, limit, sort]);

    const performSearch = async () => {
        const processedStartDate = startDate && startDate.format('YYYY-MM-DD');
        const processedEndDate = endDate && endDate.format('YYYY-MM-DD');
        const filterToAging = false;

        const args = [
            selectedCustomer?.affiliateCompanyId,
            processedStartDate || null,
            processedEndDate || null,
            (shipmentNumber && parseInt(shipmentNumber)) || null,
            filterToAging,
            offset,
            limit,
            sort ?? initialSort,
            viewBlindOnly ?? false,
            viewHazMatOnly ?? false,
            viewMultiStopsOnly ?? false,
            equipmentType,
            selectedAgent,
        ];

        setIsLoading(true);
        const {shipments, count} = await window.shell.gateway.getCustomerShipmentListings(...args);
        setShipments(shipments);
        setShipmentCount(count);
        setIsLoading(false);
    };

    const handleSelectedCustomerChange = (customer) =>  {
        setSelectedCustomer(customer ?? null)
    };

    const handleSelectedAgentChange = (e) => {
        const value = e?.id ?? "";
        setSelectedAgent(value);
    }

    const handleSearchClick = (e) => {
        setOffset(0);
        performSearch();
    };

    const handleStartDateChange = (dateValue) => {
        setStartDate(dateValue);
    };

    const handleEndDateChange = (dateValue) => {
        setEndDate(dateValue);
    };

    const handleShipmentNumberChange = (e) => {
        setShipmentNumber(e.target.value);
    };

    //Handles when the user changes pages within the table.
    const handlePageChange = (e, page) => {
        setOffset(page * limit);
    };

    // Handles when the user clicks on column headers for sorting.
    const handleSortChange = (column) => {
        const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

        setSort([[column, changeOrder]]);
        setOrder(changeOrder);
        setOrderBy(column);
    };
    const handleChangeRowsPerPage = (e) => {
        setOffset(0);
        setLimit(e.target.value);
    };

    const handleDocumentQuickViewClick = (shipment) => {
        setDocQuickViewShipmentId(shipment.shipmentBolNumber);
    };

    const handleDocumentQuickViewClose = () => {
        setDocQuickViewShipmentId(null);
    };

    const handleHistoryTypeChange = (e, selectedHistoryType) => {
        setHistoryType(selectedHistoryType);
        if (selectedHistoryType.id === ShipmentHistoryType.Standard) {
            setViewBlindOnly(false);
            setViewHazMatOnly(false);
            setViewMultiStopsOnly(false);
            setEquipmentType(null);
        }
    };

    const handleViewBlindOnlyClick = (e) => {
        setViewBlindOnly(e.target.value);
    };

    const handleViewHazMatOnlyClick = (e) => {
        setViewHazMatOnly(e.target.value);
    };

    const handleViewMultiStopsOnlyClick = (e) => {
        setViewMultiStopsOnly(e.target.value);
    };

    const handleEquipmentTypeChange = (e) => {
        setEquipmentType(e.target.value);
    };

    const getGridColsByHistoryType = () => {
        return isShipmentDetailFilter() ? 1 : 2;
    };

    if (!isAdmin && !isHeadOfAgency)
        return <AccessDeniedPage />;

    return (
        <FullWidthLayout SideNav={ReportNav} title={'Shipment History'} className="shipment-history-styles">
            <AppSpinner isLoading={isLoading} />
            <Grid container spacing={1}>
                <Grid item xs={12} md={2}>
                    <AppDropdown
                        label="History Type:"
                        field={'name'}
                        fieldIndex={'id'}
                        disableClearable
                        options={historyTypes}
                        value={historyType}
                        onChange={handleHistoryTypeChange} />
                </Grid>
                <Grid item xs={12} md={2}>
                    <label htmlFor="search">Search Customer:</label>
                    <CrmTypedAddressBookListingComboBox
                        types={[CrmTypedAddressBookListingComboBox.Customer]}
                        value={selectedCustomer || null}
                        onChange={handleSelectedCustomerChange}
                        popupIcon={' '}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    {isAdmin && <CrmAssociateDropDown
                        id={'agent'}
                        label={'Search Agents'}
                        placeholder={'Search...'}
                        onChangeAssociate={handleSelectedAgentChange}
                        autoSelectFirst={false}
                        includeInactive={false} />}
                </Grid>
                <Grid item md={6}>
                </Grid>
                <Grid item xs={12} md={getGridColsByHistoryType()}>
                    <Box>Pickup Date From:</Box>
                    <AppDatePicker
                        value={startDate}
                        onChange={handleStartDateChange}
                    />
                </Grid>
                <Grid item xs={12} md={getGridColsByHistoryType()}>
                    <Box>Pickup Date To:</Box>
                    <AppDatePicker
                        value={endDate}
                        onChange={handleEndDateChange}
                    />
                </Grid>
                {isShipmentDetailFilter() &&
                    <Grid item xs={12} md={6}>
                        <Box className={"shipment-detail-filters"}>
                            <Box>
                                <Checkbox
                                    value={viewBlindOnly}
                                    id={"viewBlindOnly"}
                                    name={"viewBlindOnly"}
                                    label={"View Blind Only"}
                                    onChange={handleViewBlindOnlyClick} />
                            </Box>
                            <Box>
                                <Checkbox
                                    value={viewHazMatOnly}
                                    id={"viewHazMatOnly"}
                                    name={"viewHazMatOnly"}
                                    label={"View Haz-mat Only"}
                                    onChange={handleViewHazMatOnlyClick} />
                            </Box>
                            <Box>
                                <Checkbox
                                    value={viewMultiStopsOnly}
                                    id={"viewMultiStopsOnly"}
                                    name={"viewMultiStopsOnly"}
                                    label={"View Multi-stops Only"}
                                    onChange={handleViewMultiStopsOnlyClick} />
                            </Box>
                            <Box>
                                <TgfEquipmentTypesComboBox
                                    id={"EquipmentType"}
                                    name={"EquipmentType"}
                                    label={"Equipment Type:"}
                                    onChange={handleEquipmentTypeChange}
                                    style={{height: "2.25rem"}} />
                            </Box>
                        </Box>
                    </Grid>}
                <Grid item xs={12} md={getGridColsByHistoryType()}>
                    <Box>Ship #:</Box>
                    <AppInput
                        onChange={handleShipmentNumberChange}
                        inputProps={{
                            maxLength: 10
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={1} style={{ textAlign: 'left', alignContent: 'end'}}>
                    <AppButton onClick={handleSearchClick}>Search</AppButton>
                </Grid>
                <Grid item xs={12}>
                    {shipments && <ShipmentHistoryListing
                        count={shipmentCount}
                        data={shipments}
                        orderBy={orderBy}
                        order={order}
                        rowsPerPage={limit}
                        page={offset / limit}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        onSort={handleSortChange}
                        onDocumentQuickViewClick={handleDocumentQuickViewClick}
                        associate={associate}
                        historyType={historyType} />}
                </Grid>
            </Grid>
            {docQuickViewShipmentId &&
                <CrmDocumentQuickViewDrawer
                    shipmentId={docQuickViewShipmentId}
                    onClose={handleDocumentQuickViewClose} />}
        </FullWidthLayout>
    );
};
export default ShipmentHistoryReport
