import {Grid} from '@material-ui/core';
import {Edit, Lock} from "@material-ui/icons";
import Add from '@material-ui/icons/Add';
import React from 'react';
import {Link} from 'react-router-dom';
import AppButton from '../../../../core/components/AppButton';
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import AppCheckbox from '../../../../core/components/inputs/AppCheckbox';
import TgfDataGrid from '../../../../core/kendo/dataGrid/TgfDataGrid';
import DebouncedTextBox from "../../../../core/kendo/inputs/DebouncedTextBox";
import TgfLabeledInput from "../../../../core/kendo/inputs/TgfLabeledInput";
import FullWidthLayout from '../../../../core/layouts/FullWidthLayout';
import {usePageTitle} from "../../../../crm/components/customHooks/misc/usePageTitle";
import LeftNav from '../../LeftNav';
import TableTopBlock from './includes/TableTopBlock';

const FailedAttemptsCell = (cellProps) =>
    <td className={"center"}>
        {cellProps.dataItem.loginAttempts >= 3
            ? <Lock color={'error'} fontSize={'small'} />
            : null}
    </td>;

const EditAssociateButtonCell = (cellProps) =>
    <td className={"center"}>
        <AppIconButton to={`/admin/manage-users-new/${cellProps.dataItem.id}`} Icon={Edit} color={'primary'} component={Link} />
    </td>;

const ManageUsersListingPage = (props) => {
    const ref = React.useRef();
    usePageTitle("TGF: Manage Users");

    const [associateCount, setAssociateCount] = React.useState(0);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [isAllAssociates, setIsAllAssociates] = React.useState(false);

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        odata.filter.isActive = (!isAllAssociates) ? true : undefined;
        odata.filter.id = {ne: 1};

        if (searchTerm != null && searchTerm.length > 0) {
            odata.filter.or = {
                firstName: { substringof: searchTerm },
                lastName: { substringof: searchTerm },
                emailAddress: { substringof: searchTerm },
                businessPhoneExt: { substringof: searchTerm },
                systemId: { substringof: searchTerm }
            };
        }

        return odata;
    };

    const loadAssociatesData = async (odata) => {
        const dataPage = await window.shell.gateway.listAssociates(odata);

        setAssociateCount(dataPage.totalItemCount);

        return dataPage;
    };

    React.useEffect(() => {
        ref.current.refresh();
    }, [isAllAssociates, searchTerm]);

    const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleAllAssociates = (e) => {
        setIsAllAssociates(e.target.checked);
    };

    const associateColumns = [
        { field: "lastName", title: "Last Name", type: "text", width: 225 },
        { field: "firstName", title: "First Name", type: "text", width: 225 },
        { field: "systemId", title: "System ID", type: "text", className: "center", width: 90 },
        { field: "businessPhoneExt", title: "Ext", type: "text", className: "center", width: 60 },
        { field: "emailAddress", title: "Email", type: "text"},
        { field: "roleName", title: "Role", type: "text", className: "center", width: 80 },
        { field: "hasDatCredentials", title: "DAT Privileges", type: "boolean" },
        { field: "hasTruckstopCredentials", title: "ITS Privilege", type: "boolean", width: 80 },
        { field: "hasCarrierPrivilege", title: "Carrier Privilege", type: "boolean", width: 80 },
        { field: "collectionRole", title: "Collection Role", type: "boolean" },
        { field: "isActive", title: "Account Active", type: "boolean", width: 80 },
        { field: "failedAttempts", title: " ", type: "custom", cell: FailedAttemptsCell, width: 80 },
        { field: "editButtons", title: " ", type: "custom", cell: EditAssociateButtonCell, width: 80 }
    ];

    return (
        <FullWidthLayout SideNav={LeftNav} title="Manage Users">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems={'flex-end'}>
                        <Grid item xs={12} md={6} lg={3}>
                            <TgfLabeledInput
                                label={"Search"}
                                inputComponent={<DebouncedTextBox
                                    id={"searchTerm"}
                                    value={searchTerm}
                                    onChange={handleSearchTerm}
                                />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3} style={{ textAlign: 'center' }}>
                            <AppCheckbox
                                name={"allAssociates"}
                                label={"show all / past associates"}
                                handleChange={handleAllAssociates}
                                checked={isAllAssociates}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} style={{ textAlign: 'right' }}>
                            <AppButton component={Link} startIcon={<Add />} to={'/admin/manage-users-new/new'}>
                                Add User
                            </AppButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableTopBlock count={associateCount} />
                    <TgfDataGrid
                        ref={ref}
                        sort={[{ field: "hasCarrierPrivilege", dir: "desc" }]}
                        gridColumns={associateColumns}
                        onLoadDataPage={loadAssociatesData}
                        onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                    />
                    <TableTopBlock count={associateCount} />
                </Grid>
            </Grid>
        </FullWidthLayout>
    );
};

export default ManageUsersListingPage;
