import React, {useEffect, useState} from "react";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import ShipmentRecordNav from "../ShipmentRecordNav";
import {Grid} from "@material-ui/core";
import {useParams} from "react-router-dom";
import ShipmentPaymentHistoryGrid from "./includes/ShipmentFinancialsPaymentHistoryListing/ShipmentPaymentHistoryGrid";
import ShipmentFinancialsCurrentBreakdownCard
    from "./includes/ShipmentFinancialsCurrentBreakdownCard/ShipmentFinancialsCurrentBreakdownCard";
import ShipmentFinancialsInvoiceAgingCard from "./includes/ShipmentFinancialsInvoiceAgingCard/ShipmentFinancialsInvoiceAgingCard";
import WhenAdministrator from "../../securityV2/WhenAdministrator";
import {useDispatch, useSelector} from "react-redux";
import {Save, Undo} from "@material-ui/icons";
import AppButton from "../../../core/components/AppButton/AppMuiButton";
import AccessDeniedPage from "../../errors/AccessDeniedPage";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const AccountingPage = (props) => {
    let { id } = useParams();
    const bolNumber = id;
    usePageTitle(`Ship ${bolNumber}`);
    const personalDispatch = useDispatch();
    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);
    const canViewAccountingTab = !!employeeContext?.claims?.canViewAccountingTab;

    const [data, setData] = useState(null);
    const [invoiceSentDate, setInvoiceSentDate] = useState(null);
    const [hasChanged, setHasChanged] = useState(false);

    const getShipmentFinancials = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAccountingPageData'));
            const shipmentFinancials = await window.shell.gateway.getShipmentReceivables(bolNumber);
            setData(shipmentFinancials);
        } catch(e) {
            console.log(e)
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAccountingPageData'));
        }

    };

    useEffect(() => {
        getShipmentFinancials();
    }, []);

    const handleReset = () => {
        const sentDate = data?.invoiceAging?.sentDate ? new Date(data.invoiceAging.sentDate) : null;
        setInvoiceSentDate(sentDate);
        setHasChanged(false);
    }

    const handleInvoiceDateSave = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveInvoiceDate'));

            const invoiceData = {
                invoiceDateTime: invoiceSentDate ? invoiceSentDate : null,
            }

            await window.shell.gateway.updateShipmentInvoice(bolNumber, invoiceData);

            // reload
            await getShipmentFinancials();
            setHasChanged(false)

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveInvoiceDate'));
        }
    }

    return (
        canViewAccountingTab ?
        <FullWidthLayout SideNav={ShipmentRecordNav} title={`Shipments - Accounting - ${bolNumber}`} className={"shipments-styles"}>
            <Grid container spacing={2}>
                {!data?.invoiceAging?.paidOffDate &&
                    <WhenAdministrator>
                        <Grid item xs={12}>
                            <AppButton
                                type={"button"}
                                startIcon={<Save/>}
                                disabled={!hasChanged}
                                onClick={handleInvoiceDateSave}
                            >
                                Save
                            </AppButton>
                            <AppButton
                                style={{marginLeft: 10}}
                                type={"button"}
                                startIcon={<Undo/>}
                                color={'warning'}
                                onClick={handleReset}
                                disabled={!hasChanged}
                            >
                                Reset
                            </AppButton>
                        </Grid>
                    </WhenAdministrator>
                }
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ShipmentFinancialsCurrentBreakdownCard bolNumber={bolNumber} data={data} />
                        </Grid>
                        <Grid item xs={12}>
                            <ShipmentFinancialsInvoiceAgingCard
                                bolNumber={bolNumber}
                                data={data?.invoiceAging}
                                invoiceSentDate={invoiceSentDate}
                                setInvoiceSentDate={setInvoiceSentDate}
                                setHasChanged={setHasChanged}
                                disableEdit={Boolean(data?.invoice?.voidId)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <ShipmentPaymentHistoryGrid bolNumber={bolNumber} data={data}/>
                </Grid>
            </Grid>
        </FullWidthLayout> : <AccessDeniedPage />
    )
};

export default AccountingPage;
