import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const load = async (shell, filter, offset, limit, sort) => {

    offset = offset / limit;

    const pendingCarriers = await window.shell.gateway.getPendingCarriers({
        filter,
        offset,
        limit,
        sort
    });

    return {
        type: ActionTypes.Carrier.Pending.Loaded,
        carriers: pendingCarriers.carriers,
        carrierCount: pendingCarriers.count
    }
};

export default ActionBuilder
    .for(load)
    .build();
