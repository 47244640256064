import * as yup from "yup";

const alphaOnlyRegex = /^([^0-9]*)$/;

export const agencyUserSchema = yup.object({
    entity: yup.object().shape({
        isHeadOfAgency: yup.boolean().required(),
        accessFilterId: yup.string()
            .oneOf([
                "1",
                "2",
                "3",
            ],
                "Required"),
    }),
});
