import React, {useState} from "react";
import AppMuiCard from "../../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import TgfDataGrid from "../../../../../../core/kendo/dataGrid/TgfDataGrid";
import {CheckCircle, Edit} from "@material-ui/icons";
import AppIconButton from "../../../../../../core/components/AppButton/AppIconButton";

const editCell = (props, onClickEditButton) => {
    return (
        <td>
            <AppIconButton
                Icon={Edit}
                tooltip={`Edit User`}
                onClick={() => onClickEditButton(props.dataItem)}
            />
        </td>
    );
}

const JackListingGrid = (props) => {
    const personalDispatch = useDispatch();
    const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);
    const [noResults, setNoResults] = useState(false);

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        odata.filter = {
            queenId: props.associateId,
            id: {
                ne: props.associateId
            }
        };

        return odata;
    }

    const loadAssociateJacks = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAssociateJacks'));
            const results = await window.shell.gateway.getPageOfManageUserAssociateJacks(odata);
            setNoResults(results.items.length === 0);
            return results;

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAssociateJacks'));
        }
    }

    const onClickEditAssociateJack = (cellProps) => {
        openLinksInNewTab ? window.open(`/admin/manage-users-new/${cellProps.id}`)
            : window.location.href = `/admin/manage-users-new/${cellProps.id}`;
    }

    const IsActiveCell = (props) => {
        return (
            props.isActive ?
                <td style={{textAlign: "center"}}><CheckCircle color={'primary'} fontSize={'small'}/></td> : <td></td>
        )
    }

    const gridColumns = [
        {
            title: 'Last Name',
            field: 'lastName',
            type: 'text',
        },
        {
            title: 'First Name',
            field: 'firstName',
            type: 'text',
        },
        {
            title: 'SystemId',
            field: 'systemId',
            type: 'text',
        },
        {
            title: 'Account Active',
            field: 'isActive',
            type: 'custom',
            cell: (props) => <IsActiveCell isActive={props.dataItem?.isActive}/>,
            width: 100
        },
        {
            title: ' ',
            field: '',
            type: 'custom',
            cell: (props) => editCell(props, onClickEditAssociateJack),
            width: 40,
            sortable: false
        },
    ];


    return (
        <AppMuiCard title={"Jacks"}>
            <Grid container spacing={2}>
                <Grid item>
                    {noResults === false &&
                        <TgfDataGrid
                            className={"manage-users__associate-jacks-grid"}
                            onLoadDataPage={loadAssociateJacks}
                            onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                            gridColumns={gridColumns}
                            sort={[{field: "lastName", dir: "asc"}]}
                            pageable={{
                                pageSize: 20,
                                buttonCount: 10,
                                pageSizes: [5, 10, 20, 50, 100, 500]
                            }}/>
                    }
                    {noResults === true &&
                        <p>Associate has no jacks assigned.</p>
                    }
                </Grid>
            </Grid>
        </AppMuiCard>
    )
}

export default JackListingGrid;
