import React, {useEffect, useState} from "react";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import {useParams} from "react-router-dom";
import AddressBookRecordNav from "../AddressBookRecordNav";
import {useDispatch, useSelector} from "react-redux";
import useStyles from "../BillingSettingsPage/styles";
import {Grid} from "@material-ui/core";
import MissingOrDeniedCompanyAlert from "../MissingOrDeniedCompanyAlert";
import CollectionContacts from "./includes/CollectionContacts/CollectionContacts";
import CollectionNotes from "./includes/CollectionNotes/CollectionNotes";
import CollectionHotNote from "./includes/CollectionHotNote/CollectionHotNote";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import CheckIfAuthorized from "../tempSecurity/CheckIfAuthorized";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";

const CollectionsPage = () => {
    const classes = useStyles();
    const urlParams = useParams();
    const companyId = parseInt(urlParams?.id);
    // const agent = useSelector(state => state?.persona?.associate);
    const personalDispatch = useDispatch();
    const isAdmin = useIsAdministrator();
    const modifyingAssociate = useSelector(state => state.persona.modifyingAssociate);
    const [company, setCompany] = useState(null);
    usePageTitle(company ? `Add: ${company?.name.substring(0, 20)}` : null);

    const loadCompany = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadCompanyCollections'));
            personalDispatch(window.shell.actions.sys.processStart('loadPageDependencies'));
            personalDispatch(await window.shell.actions.addressBook.modification.loadCompany(companyId));

            if (companyId) setCompany(await window.shell.gateway.getCompany(companyId));
        }
        catch (e) {
            console.log(e);
        }
        finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadCompanyCollections'));
            personalDispatch(window.shell.actions.sys.processComplete('loadPageDependencies'));
        }
    }

    useEffect(() => {
       loadCompany();
    }, []);

    return (
        <FullWidthLayout SideNav={AddressBookRecordNav} title={`Address Book - Collections - ${company?.name || ""}`} className={`${classes.header} address-book-styles`}>
            {company?.name ?
                <CheckIfAuthorized
                    isAdmin={isAdmin}
                    modifyingAssociate={modifyingAssociate}
                    company={company}
                >
                    <Grid container spacing={2}>
                        <Grid item>
                            <CollectionContacts
                                companyId={companyId}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <CollectionNotes
                                companyId={companyId}
                            />
                        </Grid>
                        <Grid item xs={4} style={{maxHeight: 200}}>
                            <CollectionHotNote
                                companyId={companyId}
                            />
                        </Grid>
                    </Grid>
                </CheckIfAuthorized>
                :
                <MissingOrDeniedCompanyAlert />
            }
        </FullWidthLayout>
    )
}

export default CollectionsPage;
