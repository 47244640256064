import React from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {CheckCircle} from '@material-ui/icons';

import AppCard from '../../../../core/components/cards/AppCard';

const useStyles = makeStyles((theme) => ({
    highlight: {
        verticalAlign: 'super',
        color: 'red'
    },
    formulaText: {
        fontSize: '1.15em'
    },
    highlightNote: {
        fontSize: 14,
        color: 'red',
    },
    greenMark: {
        color: theme.palette.tgfGreen.main,
        fontWeight: 'bold'
    },
    blueMark: {
        color: theme.palette.tgfBlue.main,
        fontWeight: 'bold'
    },
    blackMark: {
        color: theme.palette.tgfBlack.main,
        fontWeight: 'bold'
    }
}));

const LegendCheckMark = (props) => {
    const {agencyMode} = props;
    const classes = useStyles();

    return (
        <AppCard title="Legend Check Marks">
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <Grid
                        container spacing={1} direction={agencyMode ? "row" : "column"}
                        style={agencyMode && {padding: 5}}
                    >
                        <Grid
                            item xs={12} md={agencyMode && 4}
                            style={agencyMode && {textAlign: "center"}}
                        >
                            <div style={{justifyContent: "center", display: "flex"}}>
                                <CheckCircle className={classes.greenMark} />
                                <div style={{alignSelf: "center", paddingLeft: "2px"}}>
                                    <span className={classes.greenMark}>
                                        Green Payment -
                                    </span>
                                    {' '}Shipments that were locked the 26th of the previous month through the 10th of
                                    this month.
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item xs={12} md={agencyMode && 4}
                            style={agencyMode && {textAlign: "center"}}
                        >
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <CheckCircle className={classes.blueMark} />
                                <div style={{alignSelf: "center", paddingLeft: "2px"}}>
                                    <span className={classes.blueMark}>
                                        Blue Payment -
                                    </span>
                                    {' '}Shipments that were locked on the 11th through the 25th of this month.
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item xs={12} md={agencyMode && 4}
                            style={agencyMode && {textAlign: "center"}}
                        >
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <CheckCircle className={classes.blackMark} />
                                <div style={{marginTop: "3px", paddingLeft: "2px"}}>
                                    <span className={classes.blackMark}>
                                        Black Payment -
                                    </span>
                                    {' '}Shipments that were paid past this month's pay periods. Refer to locked date
                                    for
                                    specific pay period.
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AppCard>
    );
};
export default LegendCheckMark;
