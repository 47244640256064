import React from 'react';
import {Grid} from '@material-ui/core';
import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import {makeStyles} from "@material-ui/core/styles";
import AppCard from "../../../../core/components/cards/AppCard/AppMuiCard";
import {CheckCircle} from '@material-ui/icons';
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

const useStyles = makeStyles((theme) => ({
    greenPeriod: {
        color: theme.palette.tgfGreen.main
    },
    bluePeriod: {
        color: theme.palette.tgfBlue.main
    },
    blackPeriod: {
        color: theme.palette.tgfBlack.main
    },
    lessThanZero: {
        color: theme.palette.error.main
    }
}));

const RepPaidCell = (classes) => ({rowData: shipment}) => {

    if (!shipment.repPaid || !shipment.periodMark) {
        return null;
    }

    return (
        <CheckCircle className={classes[`${shipment.periodMark}Period`]} />
    );
};

const ShipmentListing = (props) => {
    const classes = useStyles();

    const {
        shipments,
        shipmentCount,
        onPageChange,
        onLimitChange,
        onSortChange,
        offset,
        limit,
        order,
        orderBy,
        agencyMode,
    } = props;

    const AmountCell = (propName) => ({rowData: record}) => {
        const amount = record[propName];
        return (
            <AppCurrencyText className={amount < 0 ? classes.lessThanZero : null} value={amount} />
        );
    };

    const gridColumns = [
        {
            title: 'Ship #',
            field: 'bolNumber',
            styles: {
                textAlign: 'center',
                width: 50,
            },
            dataType: 'component',
            component: AppDenseGrid.Cells
                .LinkCell((record) => [`/shipment/${record.bolNumber}/details`, record.bolNumber])
        },
        {
            title: 'Carrier Cost',
            field: 'adjustedCarrierCost',
            noSort: true,
            styles: {
                textAlign: 'center',
                width: 70,
            },
            dataType: 'component',
            component: AmountCell('adjustedCarrierCost')
        },
        {
            title: 'TGF Fee',
            field: 'fee',
            noSort: true,
            styles: {
                textAlign: 'center',
                width: 70,
            },
            dataType: 'component',
            component: AmountCell('fee')
        },
        {
            title: 'Customer Cost',
            field: 'adjustedCustomerCost',
            noSort: true,
            styles: {
                textAlign: 'center',
                width: 70,
            },
            dataType: 'component',
            component: AmountCell('adjustedCustomerCost')
        },
        {
            title: 'Total Margin',
            field: 'totalMargin',
            noSort: true,
            styles: {
                width: 70,
            },
            dataType: 'component',
            component: AmountCell('totalMargin')
        },
        {
            title: 'Type of Load',
            field: 'loadType',
            noSort: true,
            styles: {
                width: 50,
            },
            dataType: 'string'
        },
        {
            title: 'Carrier Name',
            field: 'carrierName',
            noSort: true,
            styles: {
                width: 150,
            },
            dataType: 'component',
            component: AppDenseGrid.Cells
                .LinkCell((record) => [`/carrier/${record.mcNumber}/details`, record.carrierName])
        },
        {
            title: 'Third Party',
            field: 'thirdPartyName',
            noSort: true,
            styles: {
                width: 80,
            },
            dataType: 'string'
        },
        {
            title: 'Pickup Date',
            field: 'bolDate',
            styles: {
                width: 80,
            },
            dataType: 'date'
        },
        {
            title: 'Customer Name',
            field: 'customerName',
            noSort: true,
            styles: {
                width: 150,
            },
            dataType: 'component',
            component: AppDenseGrid.Cells
                .LinkCell((record) => [`/address-book/${record.companyId}/details`, record.customerName])
        },
        {
            title: 'Earned Payout',
            field: 'earnedPayout',
            noSort: true,
            styles: {
                width: 50,
            },
            dataType: 'component',
            component: AmountCell('earnedPayout')
        },
        {
            title: 'Locked Date',
            field: 'actualDeliveryDate',
            noSort: true,
            styles: {
                width: 70,
            },
            dataType: 'date'
        },
        {
            title: 'Rep Paid',
            field: '',
            noSort: true,
            styles: {
                textAlign: 'center',
                width: 50,
            },
            dataType: 'component',
            component: RepPaidCell(classes),
        }
    ];

    return (
        <AppCard
            title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                    Shipments {
                    agencyMode &&
                    <InfoIconTooltip
                        title={
                            `This information will change depending on the filter and checkbox above being used. 
                            Otherwise, it will provide information for your entire agency.`
                        }
                        style={{marginTop: "1px"}}
                    />
                }
                </div>
            }
        >
            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item>
                    <AppDenseGrid
                        count={shipmentCount}
                        data={shipments}
                        columns={gridColumns}
                        orderBy={orderBy}
                        order={order}
                        rowsPerPage={limit}
                        page={offset / limit}
                        onChangePage={onPageChange}
                        onChangeRowsPerPage={onLimitChange}
                        onSort={onSortChange}
                    />
                </Grid>
            </Grid>
        </AppCard>
    );
};

export default ShipmentListing;
