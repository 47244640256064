import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import * as Yup from 'yup';
import Core from "@atomos/core";

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import associate from '../../../hubs/persona/selectors/associate';
import ComponentBuilder from '../../../core/ComponentBuilder';
import ContentListingGrid from './ContentsListingGrid';
import FormFactor from '../../../core/FormFactor/FormFactor';
import FreightCategoryTypeNames from '../../../hubs/shipment/FreightCategoryTypeNames';
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import MissingOrDeniedShipmentAlert from '../MissingOrDeniedShipmentAlert';
import renderModifyContentForm from './modals/AddContent';
import renderTruckLoadValueConfirmationForm from './modals/completeConfirmation';
import SecureAction from "../permissions/SecureAction";
import When from "../../../core/components/condtionals/When";

import AppAlertStatic from '../../../core/components/AppAlertStatic';
import AppButton from '../../../core/components/AppButton';
import AppDialog from '../../../core/components/AppDialog';
import AppMuiDialog from '../../../core/components/AppDialog/AppMuiDialog';

import ShipmentRecordNav from '../ShipmentRecordNav';
import './ContentPage.scss';
import AppCurrencyText from '../../../core/components/text/AppCurrencyText';
import {ShipmentTitle} from "../ShipmentTitle";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const LoadProcessName = 'Shipment.ContentPage.Load';
const SaveProcessName = 'Shipment.ContentPage.Save';
const DeleteProcessName = 'Shipment.ContentPage.Delete';
const SaveTruckLoadValue = 'Shipment.ContentPage.TruckLoadValue.Save';

const ContentsPage = (props) => {

  const {
    associatedId,
    freightCategoryTypes = [],
    match,
    shipment = {},
    shipmentContents = [],
    shipmentPackageTypes = [],
    shipmentFreightClasses = [],
    shipmentTruckLoadValueConfirmationTypes = [],
    shipmentTruckLoadConfirmation,
    isAdmin,
    load,
    deleteShipmentContent,
    saveShipmentContent,
    saveShipmentTruckLoadConfirmation,
    deleteShipmentTruckLoadConfirmation,
    sendSnackbarMessage
  } = props;

  const bolNumber = match.params.id && parseInt(match.params.id);
  usePageTitle(`Ship ${bolNumber}`);

  if (Core.Utils.isUndefined(bolNumber) || bolNumber === 0) {
    throw new Error('BolNUmber url parameter missing.');
  }

  const sortedPackageTypes = Core.Utils.sortBy(shipmentPackageTypes, 'name');
  const sortedFreightClasses = Core.Utils.sortBy(shipmentFreightClasses, 'value');

  const [draftContent, setDraftContent] = React.useState(null);
  const [deleteContent, setDeleteContent] = React.useState(null);
  const [draftTruckLoadValue, setDraftTruckLoadValue] = React.useState(null);
  const [confirmRevertTruckLoadValue, setConfirmRevertTruckLoadValue] = React.useState(false);
  const [disableEdit, setDisableEdit] = useState(false);

  React.useEffect(() => {
    load(bolNumber);
  }, [bolNumber, load]);

  useEffect(() => {
    if(shipment?.voidId) setDisableEdit(true)
  });

  const isTruckLoadConfirmed = Boolean(shipmentTruckLoadConfirmation);

  let isLoadTl = false;
  if (shipment) {
    const defaultFreightCategoryType = freightCategoryTypes.find(x => x.name === FreightCategoryTypeNames.Truckload);
    isLoadTl = defaultFreightCategoryType && defaultFreightCategoryType.id === shipment.freightCategoryId;
  }

  const handleNewContentOpen = (e) => {
    setDraftContent({
      isLoadTl,
      packageTypes: sortedPackageTypes,
      freightClasses: sortedFreightClasses,
      packageTypeId: null,
      selectedPackageType: null,
      selectedFreightClass: null,
      id: 0,
      bolNumber: bolNumber,
      packageCount: null,
      pieceCount: null,
      description: null,
      dimensions: null,
      packageWeight: null,
      freightClassId: null,
      nmfc: null,
      note: null
    })
  };

  const handleNewTruckLoadValueOpen = (e) => {
    setDraftTruckLoadValue({
      rangeType: shipmentTruckLoadValueConfirmationTypes,
      selectedRangeValue: shipmentTruckLoadValueConfirmationTypes[0],
      bolNumber: bolNumber,
      typeId: shipmentTruckLoadValueConfirmationTypes[0].id,
      confirmedDate: null,
      confirmingAssociateId: associatedId,
      confirmedValue: null
    })
  };

  const handleNewTruckLoadValueClose = (e) =>
    setDraftTruckLoadValue(null);

  const handleNewContentClose = (e) =>
    setDraftContent(null);

  const handleNewContentSubmit = (values, formFactor) => {
    setDraftContent(null);
    saveShipmentContent(values)
      .then(() => {
        sendSnackbarMessage({ content: 'Content saved.'});
      });
  };

  const handleNewTruckLoadValueSubmit = (values, formFactor) => {
    setDraftTruckLoadValue(null);
    saveShipmentTruckLoadConfirmation(values)
      .then(() => {
        sendSnackbarMessage({ content: 'Truckload Value Confirmation saved.'});
      });
  };

  const handleRevertTruckLoadValueConfirmation = () => {
    deleteShipmentTruckLoadConfirmation(bolNumber)
      .then(() => {
        setConfirmRevertTruckLoadValue(false);
        sendSnackbarMessage({content: 'Truckload Value Confirmation reverted.'})
      });
  };

  const handlePromptRevertTruckLoadValueConfirmation = () => {
    setConfirmRevertTruckLoadValue(true);
  };

  const handleCancelRevertTruckloadConfirmation = () => {
    setConfirmRevertTruckLoadValue(false);
  };

  const handleEditContentClick = (content) => {
    setDraftContent({
      isLoadTl,
      packageTypes: sortedPackageTypes,
      freightClasses: sortedFreightClasses,
      selectedPackageType: sortedPackageTypes.find(p => p.id === content.packageTypeId),
      selectedFreightClass: sortedFreightClasses.find(s => s.id === content.freightClassId),
      id: content.id,
      bolNumber: content.bolNumber,
      packageCount: content.packageCount,
      packageTypeId: content.packageTypeId,
      pieceCount: content.pieceCount,
      description: content.description,
      dimensions: content.dimensions,
      packageWeight: content.packageWeight,
      freightClassId: content.freightClassId,
      nmfc: content.nmfc,
      note: content.notel
    });
  };

  const handleDeleteContentClick = (content) => {
    setDeleteContent(content);
  };

  const handleDeleteContentClose = (e) =>
    setDeleteContent(null);

  const handleDeleteContentConfirm = (e) => {
    setDeleteContent(null);
    deleteShipmentContent(deleteContent.bolNumber, deleteContent.id)
      .then(() => {
        sendSnackbarMessage({ content: 'Content deleted.' });
      });
  };

  const deleteAction = [
    {
      title: 'Cancel',
      action: handleDeleteContentClose
    },
    {
      title: 'Confirm',
      action: handleDeleteContentConfirm
    }
  ];

  const initialValues = {
    ...draftContent,
    onClose: handleNewContentClose,
    selectProductModalOpen: false,
    customerId: shipment?.customerId,
    shipmentType: shipment?.equipmentType,
  };

  const truckLoadInitialValues = {
    ...draftTruckLoadValue,
    onClose: handleNewTruckLoadValueClose
  };

  const renderTruckLoadValueMessage = () => {

    let alertStyle = 'warning';

    if (isTruckLoadConfirmed) {
      const confirmedValue = shipmentTruckLoadConfirmation.confirmedValue;
      const associateName = `${shipmentTruckLoadConfirmation.confirmingAssociate.firstName} ${shipmentTruckLoadConfirmation.confirmingAssociate.lastName}`;
      const confirmedDate = shipmentTruckLoadConfirmation.confirmedDate.toMoment().format('L LT');
      const currentTruckloadValueConfirmType = shipmentTruckLoadValueConfirmationTypes.find(x => x.id === shipmentTruckLoadConfirmation.typeId);

      alertStyle =
        currentTruckloadValueConfirmType.requiresEstimatedValue ? 'success' : 'info';

      return (
        <AppAlertStatic color={alertStyle} title={'Truckload Value Confirmation'}>
          {
            !currentTruckloadValueConfirmType.requiresEstimatedValue &&
              <p>Less than 100k confirmed by <b>{associateName}</b> on <b>{confirmedDate}</b></p>
          }
          {
            currentTruckloadValueConfirmType.requiresEstimatedValue &&
            <p>Over 100k at <b><AppCurrencyText value={parseFloat(confirmedValue)} /></b> by <b>{associateName}</b> on <b>{confirmedDate}</b></p>
          }
          {
            !isTruckLoadConfirmed &&
              <SecureAction
                pageName={'loadContents'}
                actionName={'confirmTruckloadValue'}
                as={AppButton}
                variant={'contained'}
                color={'warning'}
                onClick={handleNewTruckLoadValueOpen}
                disabled={disableEdit}
              >
                Complete Confirmation
              </SecureAction>
          }
          {
            isAdmin && isTruckLoadConfirmed &&
            <SecureAction
              pageName={'loadContents'}
              actionName={'confirmTruckloadValue'}
              as={AppButton}
              variant={'contained'}
              color={'info'}
              onClick={handlePromptRevertTruckLoadValueConfirmation}
              disabled={disableEdit}
            >
              Revert Confirmation
            </SecureAction>
          }
        </AppAlertStatic>
      );
    } else {

      return (
        <AppAlertStatic color={alertStyle} title={'Incomplete Truckload Value Confirmation'}>
          <p>Click the "Complete Confirmation" button to assign a truckload value.</p>
          <SecureAction
            pageName={'loadContents'}
            actionName={'confirmTruckloadValue'}
            as={AppButton}
            variant={'contained'}
            color={'warning'}
            onClick={handleNewTruckLoadValueOpen}
            disabled={disableEdit}
          >
            Complete Confirmation
          </SecureAction>
        </AppAlertStatic>
      );
    }

  };

  return (
    <FullWidthLayout SideNav={ShipmentRecordNav} className={"shipments-styles"}>
      <Grid container spacing={1}>

        <When condition={!shipment}>
          <MissingOrDeniedShipmentAlert />
        </When>

        <When condition={shipment}>
          <Grid item xs={12}><ShipmentTitle bolNumber={bolNumber} title={`Shipments - Contents - ${bolNumber ? bolNumber : 'New'}`}/></Grid>
          <Grid item xs={12}>
            <SecureAction
              pageName={'loadContents'}
              actionName={'addContent'}
              as={AppButton}
              startIcon={<Add />}
              onClick={handleNewContentOpen}
              disabled={disableEdit}
            >
              Add Content
            </SecureAction>
          </Grid>
          <Grid item xs={12}>
            {isLoadTl && renderTruckLoadValueMessage()}

          </Grid>
          <Grid item xs={12}>
            <ContentListingGrid
              contents={shipmentContents}
              freightClasses={sortedFreightClasses}
              packageTypes={sortedPackageTypes}
              onEditClick={handleEditContentClick}
              onDeleteClick={handleDeleteContentClick}
              disableEdit={disableEdit}
            />
          </Grid>

        </When>
      </Grid>
      {
        draftContent && <FormFactor
          initialValues={initialValues}
          schema={ShipmentContentSchema}
          onSubmit={handleNewContentSubmit}>
          {renderModifyContentForm}
        </FormFactor>
      }
      {
        deleteContent && <AppDialog
          title={'Content Deletion'}
          open={true}
          onClose={handleDeleteContentClose}
          width='sm'
          actionButtons={deleteAction}>
          Confirm that you want to delete content [{deleteContent.description}]?
        </AppDialog>
      }
      {
        draftTruckLoadValue && <FormFactor
          initialValues={truckLoadInitialValues}
          schema={TruckLoadConfirmationSchema}
          onSubmit={handleNewTruckLoadValueSubmit}>
          {renderTruckLoadValueConfirmationForm}
        </FormFactor>
      }
      {
        confirmRevertTruckLoadValue &&
        <AppMuiDialog
          open={confirmRevertTruckLoadValue}
          width={'sm'}
          title={'Revert Truckload Value Confirmation'}
          toggleDialog={handleCancelRevertTruckloadConfirmation}
          actionButtons={[
            {
              action: handleCancelRevertTruckloadConfirmation,
              title: 'Cancel'
            },
            {
              action: handleRevertTruckLoadValueConfirmation,
              title: 'Confirm'
            }
          ]}
        >
        <span>Are you sure you want to remove this truckload value confirmation?</span>
        </AppMuiDialog>
      }
    </FullWidthLayout>
  );
};

const ShipmentContentSchema = Yup.lazy( values => {
  const schema = {
    packageCount: Yup.number()
      .required('Required value.')
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    packageTypeId: Yup.number()
      .nullable()
      .required('Required value.'),
    description: Yup.string()
      .typeError('Max 250 characters.')
      .nullable()
      .required('Required value.'),
    packageWeight: Yup.number()
      .required('Required value.')
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.')
  };

  if (!values.isLoadTl) {
    schema.nmfc = Yup.string()
      .typeError('Dimension is required.')
      .nullable()
      .required('Required value.');
    schema.freightClassId = Yup.number()
      .typeError('Freight Class is required.')
      .required('Required value.');
    schema.dimensions = Yup.string()
      .typeError('Dimension is required.')
      .nullable()
      .required('Required value.');
  }

  return Yup.object().shape(schema);
});


const TruckLoadConfirmationSchema = Yup.lazy(values => {
  const schema = {
  };

  if (values.selectedRangeValue.requiresEstimatedValue) {
    schema.confirmedValue = Yup
      .number()
      .typeError('Required value.')
      .nullable()
      .required('Required value.')
      .min(100001, 'Value must be over 100k.');
  }

  return Yup.object().shape(schema);
});

export default ComponentBuilder
  .wrap(ContentsPage)
  .stateToProps((state, ownProps) => {
    return {
      associatedId: Core.Utils.get(associate(state), 'id', null),
      freightCategoryTypes: state.support.freightCategoryTypes,
      isAdmin: useIsAdministrator(),
      shipment: state.shipment.modification.shipment,
      shipmentContents: state.shipment.modification.shipmentContents,
      shipmentPackageTypes: state.support.shipmentPackageTypes,
      shipmentFreightClasses: state.support.shipmentFreightClasses,
      shipmentTruckLoadConfirmation: state.shipment.modification.shipmentTruckLoadConfirmation,
      shipmentTruckLoadValueConfirmationTypes: state.support.shipmentTruckLoadValueConfirmationTypes
    }
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(bolNumber){
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        const actions = await Promise.all([
          shell.actions.shipment.modification.loadShipment(bolNumber),
          shell.actions.shipment.modification.loadShipmentContents(bolNumber),
          shell.actions.shipment.modification.loadShipmentTruckLoadConfirmation(bolNumber)
        ]);
        actions.forEach(dispatch);
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async saveShipmentContent(content){
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        dispatch(await shell.actions.shipment.modification.saveShipmentContent(content));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));
      },
      async deleteShipmentContent(bolNumber, shipmentContentId) {
        dispatch(shell.actions.sys.processStart(DeleteProcessName));
        dispatch(await shell.actions.shipment.modification.deleteShipmentContent(bolNumber, shipmentContentId));
        dispatch(shell.actions.sys.processComplete(DeleteProcessName));
      },
      async saveShipmentTruckLoadConfirmation(shipmentTruckloadValue) {
        dispatch(shell.actions.sys.processStart(SaveTruckLoadValue));
        dispatch(await shell.actions.shipment.modification.saveShipmentTruckLoadConfirmation(shipmentTruckloadValue));
        dispatch(shell.actions.sys.processComplete(SaveTruckLoadValue));
      },
      async deleteShipmentTruckLoadConfirmation(bolNumber) {
        dispatch(shell.actions.sys.processStart('DeleteTruckloadValue'));
        dispatch(await shell.actions.shipment.modification.deleteShipmentTruckLoadConfirmation(bolNumber));
        dispatch(shell.actions.sys.processComplete('DeleteTruckloadValue'));
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    }
  })
  .build();
