import React from 'react';
import ComponentBuilder from '../../../../core/ComponentBuilder';
import FullWidthLayout from '../../../../core/layouts/FullWidthLayout';
import LeftNav from '../../LeftNav';
import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import {Grid, Typography} from '@material-ui/core';
import AppDatePicker from "../../../../core/components/inputs/AppDatePicker";
import AppMuiButton from "../../../../core/components/AppButton";
import CrmAssociateComboBoxQueensOnly from "../../../../crm/components/CrmAssociateComboBoxQueensOnly";
import AgentTooltipComponent from "../../../../crm/components/agent-tooltip/AgentTooltipComponent";
import {usePageTitle} from "../../../../crm/components/customHooks/misc/usePageTitle";
import {loadFlagsCell} from "../../../open-load-board/includes/listingCells/multiUseListingCells";
import {
    loadFlagBlindActiveIcon, loadFlagBlindInactiveIcon,
    loadFlagHazardousActiveIcon, loadFlagHazardousInactiveIcon,
    loadFlagMultistopInactiveIcon,
    loadFlagMutlistopActiveIcon
} from "../../../../core/media/icons/exportCustomIcons";

const LoadProcessName = 'Admin.DoubleBrokeredReport.Load';

const DoubleBrokeredReportPage = (props) => {

    const {
      doubleBrokeredLoads,
      doubleBrokeredLoadsCount,
      loadRecords,
    } = props;

    usePageTitle("TGF: Double Brokered Loads");

    const gridColumns = [
      {
        field: 'rep',
        title: 'Rep',
        styles: {
          width: '10%'
        },
        dataType: 'component',
          component: ({ rowData: record }) => {
              return (
                <AgentTooltipComponent content={record.rep} agentId={record.repId}/>
              );
          },
      },
      {
        field: 'bolNumber',
        title: 'Ship #',
        styles: {
          width: '10%'
        },
        dataType: 'component',
        component: AppDenseGrid.Cells
          .LinkCell((record) => [`/shipment/${record.bolNumber}/details`, record.bolNumber])
      },
      {
        field: 'pickupDate',
        title: 'Pickup Date',
        styles: {
          width: '10%'
        },
        dataType: 'dateUTC'
      },
      {
        title: 'Customer Name',
        field: 'customerName',
        styles: {
          width: '35%'
        },
        dataType: 'string'
      },
      {
        field: 'carrierName',
        title: 'Carrier Name',
        styles: {
          width: '10%'
        },
        dataType: 'string'
      },
      {
        field: 'curAdjCarrierCost',
        title: 'Carrier Cost',
        styles: {
          width: '10%'
        },
        dataType: 'currency'
      },
      {
        field: 'auditFinalized',
        title: 'Audit Finalized',
        styles: {
          width: '10%'
        },
        dataType: 'string'
      },
      {
        field: 'disputeStatus',
        title: 'Dispute Status',
        styles: {
          width: '6%'
        },
        dataType: 'string'
      },
      {
        field: 'claimStatus',
        title: 'Claim Status',
        styles: {
            width: '6%'
        },
        dataType: 'string'
      }
    ];

    const [offset, setOffset] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [sort, setSort] = React.useState([['bolNumber', 'desc']]);

    const [approvedFilterStartDate, setApprovedFilterStartDate] = React.useState(null);
    const [approvedFilterEndDate, setApprovedFilterEndDate] = React.useState(null);
    const [byAgentId, setByAgentId] = React.useState(null);

    React.useEffect(() => {
      loadRecords(
        {
          limit: limit,
          offset: offset,
          sort: sort,
          startDate: approvedFilterStartDate,
          endDate: approvedFilterEndDate,
          byAgentId
        }
      );
    }, [offset, limit, sort, approvedFilterStartDate, approvedFilterEndDate, byAgentId, loadRecords]);

    const handleChangePage = (e, page) => {
      setOffset(page * limit);
    };
    const handleSortChange = (column) => {

      const [existingColumn, existingOrder] = sort[0];

      const order = column === existingColumn ?
        existingOrder === 'asc' ? 'desc' : 'asc' :
        'asc';

      setSort([[column, order]]);
    };
    const handleChangeRowsPerPage = (e) => {
      setOffset(0);
      setLimit(e.target.value);
    };
    const handleApprovedFilterStartDateChange = (e) => {
      setApprovedFilterStartDate(e && e.toISOString() ? e.toISOString().substring(0, 10) : '');
    }

    const handleApprovedFilterEndDateChange = (e) => {
      setApprovedFilterEndDate(e && e.toISOString() ? e.toISOString().substring(0, 10) : '');
    }

    const onSelectAssociate = (e) => {
      setByAgentId(e ? e.id : null);
    }

    const handleClearDatesClick = () => {
      setApprovedFilterStartDate(null);
      setApprovedFilterEndDate(null);
    }

    return (
      <FullWidthLayout title={'Double Brokered Loads Report'} SideNav={LeftNav}>
        <Grid container spacing={2} alignItems={'center'}>

          <Grid item xs={12} md={'auto'}>
            <Typography noWrap={true} align='left'>Shipment Date From:</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <AppDatePicker
              value={approvedFilterStartDate}
              onChange={handleApprovedFilterStartDateChange}
            />
          </Grid>
          <Grid item xs={12} md={'auto'}>
            <Typography align='left'>Shipment Date To:</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <AppDatePicker
              value={approvedFilterEndDate}
              onChange={handleApprovedFilterEndDateChange}
            />
          </Grid>
          <Grid item xs={12} md={'auto'}>
            <AppMuiButton onClick={handleClearDatesClick}>Clear Dates</AppMuiButton>
          </Grid>
          <Grid item xs={12} md={"auto"}>
            <Typography align='left'>Rep:</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <CrmAssociateComboBoxQueensOnly
              placeholder={'Search agents...'}
              onSelectAssociate={onSelectAssociate}
              style={{minWidth: '250px'}}
            />
          </Grid>
          <Grid item xs={12}>
            <AppDenseGrid
              columns={gridColumns}
              data={doubleBrokeredLoads}
              count={doubleBrokeredLoadsCount}
              orderBy={sort[0][0]}
              order={sort[0][1]}
              rowsPerPage={limit}
              page={offset / limit}
              onSort={handleSortChange}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </FullWidthLayout>
    );
  }
;

export default ComponentBuilder
  .wrap(DoubleBrokeredReportPage)
  .stateToProps((state, ownProps) => ({
    doubleBrokeredLoads: state.automation.doubleBrokered.doubleBrokeredLoads,
    doubleBrokeredLoadsCount: state.automation.doubleBrokered.doubleBrokeredLoadsCount,
  }))
  .dispatchToProps((shell, dispatch, getState) => ({
    async loadRecords(options) {
      dispatch(shell.actions.sys.processStart(LoadProcessName));
      dispatch(await shell.actions.automation.doubleBrokered.doubleBrokered(options));
      dispatch(shell.actions.sys.processComplete(LoadProcessName));
    },
  }))
  .build();
