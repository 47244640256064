import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ComponentBuilder from "../../../../core/ComponentBuilder";
import AppDenseGrid from "../../../../core/components/AppDenseGrid/AppDenseGrid";
import useIsAdministrator from "../../../../hubs/personaV2/selectors/useIsAdministrator";

const useStyles = makeStyles((theme) => ({
  tdStyles: {
    textDecoration: "none",
    color: theme.palette.tgfLink.main,
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const AgentRewardsListingTable = (props) => {
  const {
    data,
    orderBy,
    order,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    gridColumns
  } = props;



  return (
    data && (
      <AppDenseGrid
        count={data.count}
        data={data.breakdowns}
        orderBy={orderBy}
        order={order}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onSort={onSort}
        columns={gridColumns}
      />
    )
  );
};

export default ComponentBuilder.wrap(AgentRewardsListingTable)
  .stateToProps((state, ownProps) => {
    return {
      isAdmin: useIsAdministrator(),
      modifyingAssociate: state.persona.modifyingAssociate,
    };
  })
  .build();
