import {Grid} from '@material-ui/core';
import FullWidthLayout from 'core/layouts/FullWidthLayout';
import React, {useEffect, useRef, useState} from 'react';
import {usePageTitle} from "crm/components/customHooks/misc/usePageTitle";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import {useDispatch} from 'react-redux';
import isHeadOfAgency from "../../../hubs/personaV2/selectors/useIsHeadOfAgency";
import LeftNav from '../LeftNav';
import TgfAgencyComboBox from "../../../core/kendo/form/inputs/specialty/TgfAgencyComboBox";
import {AgencyUsersGrid} from "./includes";
import AppCheckbox from "../../../core/components/inputs/AppCheckbox/AppMuiCheckbox";
import {EditAgencyUserModal} from "./includes/EditAgencyUserModal";
import {DeactivateUserModal} from "./includes/DeactivateUserModal";
import {InvolvedCustomersModal} from "./includes/InvolvedCustomersModal";
import {InvolvedLeadsModal} from "./includes/InvolvedLeadsModal";
import AccessDeniedPage from "../../errors/AccessDeniedPage";
import InfoIconTooltip from "../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

export const AgencyUsers = () => {
    const isAdmin = useIsAdministrator();
    const gridRef = useRef();
    const personalDispatch = useDispatch();
    usePageTitle("TGF: Manage Users");
    const headOfAgency = isHeadOfAgency();

    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);
    const canViewAgencyPage = (employeeContext?.claims?.isHeadOfAgency) || (employeeContext?.claims?.isAdministrator && employeeContext?.claims?.canViewAgencyPage);

    const [selectedAgencyId, setSelectedAgencyId] = useState();
    const [isAllAssociates, setIsAllAssociates] = useState(false);
    const [editAssociateModalOpen, setEditAssociateModalOpen] = useState(false);
    const [deactivationModalOpen, setDeactivationModalOpen] = useState(false);
    const [selectedAssociate, setSelectedAssociate] = useState();
    const [agencyAccessFilters, setAgencyAccessFilters] = useState();
    const [openInvolvedCustomers, setOpenInvolvedCustomers] = useState(false);
    const [openInvolvedLeads, setOpenInvolvedLeads] = useState(false);
    const [headOfAgencyCount, setHeadOfAgencyCount] = useState(0);

    const loadAgencyAccessFilters = async () => {

        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAgencyAccessFilters'));
            const agencyAccessFilters = await window.shell.gateway.getAgencyAccessFilters();

            setAgencyAccessFilters(agencyAccessFilters);
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAgencyAccessFilters'));
        }

    };

    const loadHeadOfAgencyCount = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadHeadOfAgencyCount'));
            const headOfAgencyCount = await window.shell.gateway.getHeadOfAgencyCount(selectedAgencyId);
            setHeadOfAgencyCount(headOfAgencyCount);
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadHeadOfAgencyCount'));
        }
    };

    const handleSelectionOfAgency = (e) => {
        setSelectedAgencyId(e.target.value);
    };

    const handleAllAssociates = (e) => {
        setIsAllAssociates(e.target.checked);
    };

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0,
        };

        odata.filter.isActive = (!isAllAssociates) ? true : undefined;
        odata.filter.agencyId = headOfAgency ? window.shell.employeeContext?.agency?.id : selectedAgencyId;

        return odata;
    };

    const closeEditAgencyUserModal = () => {
        setEditAssociateModalOpen(false);
        setSelectedAssociate(null);
    };

    const openEditAgencyUserModal = () => {
        setEditAssociateModalOpen(true);
    };

    const openDeactivationModal = () => {
        setDeactivationModalOpen(true);
    };

    const closeDeactivationOpenEditAgencyUserModal = () => {
        setDeactivationModalOpen(false);
        openEditAgencyUserModal();
    };

    const closeInvolvedCustomersModal = () => {
        setOpenInvolvedCustomers(false);
        setSelectedAssociate(null);
    };

    const closeInvolvedLeadsModal = () => {
        setOpenInvolvedLeads(false);
        setSelectedAssociate(null);
    };

    const reload = () => {
        gridRef.current?.refresh();
    };

    useEffect(() => {
        reload();
    }, [selectedAgencyId, isAllAssociates, headOfAgency]);

    useEffect(() => {
        if (!editAssociateModalOpen) {
            reload();
        }
    }, [editAssociateModalOpen]);


    return (
        canViewAgencyPage ? <FullWidthLayout SideNav={LeftNav} title="Manage Agency Users">
            <Grid container spacing={2}>
                {isAdmin &&
                    <Grid item xs={12} md={4}>
                        <TgfAgencyComboBox
                            label={"Franchise Mode Agencies"}
                            onChange={handleSelectionOfAgency}
                            value={selectedAgencyId}
                            franchiseMode={true}
                            isDisabled={false}
                        />
                    </Grid>
                }
                <Grid
                    item xs={12} md={6} lg={3}
                    style={{
                        textAlign: isAdmin ? "center" : "left",
                        marginTop: '10px',
                        alignItems: "center",
                        display: "flex",
                        justifyContent: isAdmin ? "center" : "left",
                    }}
                >
                    <AppCheckbox
                        name={"allAssociates"}
                        label={"show all / inactive CRM users"}
                        handleChange={handleAllAssociates}
                        checked={isAllAssociates}
                    />
                    <InfoIconTooltip
                        title={
                            <>
                                <p>
                                    Checking this box will display all historical users under your agency - whether
                                    active or inactive.
                                </p>
                                <p>
                                    The Account Active column advises whether or not they are currently active.
                                </p>
                            </>
                        }
                        style={{alignSelf: "center"}}
                    />
                </Grid>
                {(selectedAgencyId || headOfAgency) &&
                    <Grid item xs={12}>
                        <AgencyUsersGrid
                            ref={gridRef}
                            applyAdditionalFilters={applyAdditionalFilters}
                            setEditAssociateModalOpen={setEditAssociateModalOpen}
                            setSelectedAssociate={setSelectedAssociate}
                            loadAgencyAccessFilters={loadAgencyAccessFilters}
                            agencyAccessFilters={agencyAccessFilters}
                            setOpenInvolvedCustomers={setOpenInvolvedCustomers}
                            setOpenInvolvedLeads={setOpenInvolvedLeads}
                        />
                    </Grid>
                }
                {openInvolvedCustomers &&
                    <InvolvedCustomersModal
                        closeInvolvedCustomersModal={closeInvolvedCustomersModal}
                        selectedAssociate={selectedAssociate}
                    />
                }
                {openInvolvedLeads &&
                    <InvolvedLeadsModal
                        closeInvolvedLeadsModal={closeInvolvedLeadsModal}
                        selectedAssociate={selectedAssociate}
                    />
                }
                {editAssociateModalOpen && selectedAssociate &&
                    <EditAgencyUserModal
                        closeEditAgencyUserModal={closeEditAgencyUserModal}
                        incomingAssociate={selectedAssociate}
                        loadAgencyAccessFilters={loadAgencyAccessFilters}
                        loadHeadOfAgencyCount={loadHeadOfAgencyCount}
                        agencyAccessFilters={agencyAccessFilters}
                        openDeactivationModal={openDeactivationModal}
                        headOfAgencyCount={headOfAgencyCount}
                    />
                }
                {deactivationModalOpen && selectedAssociate &&
                    <DeactivateUserModal
                        reloadGrid={reload}
                        closeEditAgencyUserModal={closeEditAgencyUserModal}
                        closeDeactivationOpenEditAgencyUserModal={closeDeactivationOpenEditAgencyUserModal}
                        incomingAssociate={selectedAssociate}
                        loadAgencyAccessFilters={loadAgencyAccessFilters}
                        agencyAccessFilters={agencyAccessFilters}
                    />
                }
            </Grid>
        </FullWidthLayout> : <AccessDeniedPage />
    );
};
