import React, {useState} from 'react';
import Core from '@atomos/core';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider } from '@material-ui/core';
import { Publish, ArrowDownward } from '@material-ui/icons';

import ComponentBuilder from '../../core/ComponentBuilder';
import isProcessActive from '../../hubs/sys/selectors/isProcessActive';
import useIsAdministrator from '../../hubs/personaV2/selectors/useIsAdministrator';
import HelpWidthLayout from '../../core/layouts/HelpWidthLayout';
import HelpList from './includes/HelpList';

import AppButton from '../../core/components/AppButton';
import AppCard from '../../core/components/cards/AppCard';
import AppDocumentRemoveFile from '../../core/components/Documents/AppDocumentRemoveFile';
import HelpSearchDrawer from './includes/HelpSearchDrawer';
import useSearchTermStore from './includes/useSearchTermStore';
import {usePageTitle} from "../../crm/components/customHooks/misc/usePageTitle";
import "./Help.scss";
import HelpDocumentDrawer from "./includes/HelpDocumentDrawer";
import {Checkbox} from "@progress/kendo-react-inputs";
const PermanentSearchTerms = [
  {
    value: 'office info',
    label: 'Office Info'
  },
  {
    value: 'customer onboarding',
    label: 'Customer Onboarding'
  },
  {
    value: 'compliance',
    label: 'Compliance'
  },
  {
    value: 'ltl',
    label: 'LTL'
  }
];

const LoadProcessName = 'Support.LoadHelpDocuments';
const useStyles = makeStyles(theme => ({
  noDocs: {
    marginTop: 50,
    textAlign: 'center'
  },
  alignRight: {
    textAlign: 'right'
  },
  height100: {
    height: '100%'
  },
  leftPad5: {
    paddingLeft: 5,
    fontWeight: 'bold',
    display: 'initial',
    position: 'absolute',
    marginTop: 5
  },
  iconDescending: {
    transform: 'rotate(180deg)'
  },
  pointer: {
    cursor: 'pointer'
  }
}));

const documentTypes = [
  {
    id: 1,
    name: 'Help Center'
  },
  {
    id: 2,
    name: 'Other'
  }
];

const Help = (props) => {
  const {
    associate,
    dispose,
    deleteHelpDocument,
    helpDocuments,
    isAdmin,
    isLoading,
    load,
    publishHelpDocument,
    sendSnackbarMessage,
    canViewBackOfficeHelpDocs
  } = props;

  usePageTitle("TGF: Help Page");

  const classes = useStyles();
  const [cardDocument, setCardDocument] = React.useState(null);
  const [documentId, setDocumentId] = React.useState(null);
  const [openNewDocument, setOpenNewDocument] = React.useState(false);
  const [removeFileOpen, setRemoveFileOpen] = React.useState(false);
  const [sortAscending, setSortAscending] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchTerms, addSearchTerm, removeSearchTerm, clearSearchTerms] = useSearchTermStore();
  const [showBackOfficeOnly, setShowBackOfficeOnly] = useState(false);
  const delayedAddSearch = React.useCallback(Core.Utils.debounce((searchTerm) => {
    if (!PermanentSearchTerms.includes(searchTerm)) {
      addSearchTerm(searchTerm);
    }
  }, 750), []);

  React.useEffect(() => {
    load();
    return () => dispose();
  }, []);

  const handleCloseNewDocument = () => setOpenNewDocument(false);
  const handleNewDocument = () => setOpenNewDocument(true);
  const handleRemoveFileClose = () => setRemoveFileOpen(false);

  const handlePublishClick = (helpDocument) => {
    publishHelpDocument(helpDocument)
      .then(() => {
        setOpenNewDocument(false);
        sendSnackbarMessage({ content: 'Document uploaded.' })
      });
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm && searchTerm.trim().length > 0) {
      delayedAddSearch(searchTerm);
    }
  };

  const handleSelectSearchTerm = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  const handleRemoveSearchTerm = (searchTerm) => {
    removeSearchTerm(searchTerm);
  };

  const handleClearSearchTerms = () => {
    clearSearchTerms();
  };

  const handleOpenDownloadFileClick = (document, shouldOpen) => {

    const a = window.document.createElement('a');

    shouldOpen ? a.target = '_blank' : a.download = document.filename;

    a.href = `/file/support/document/${document.id}`;
    a.click();
    a.remove();
  };

  const handleRemoveFileConfirmClick = () => {
    setRemoveFileOpen(false);
    deleteHelpDocument(documentId)
      .then(() => {
        sendSnackbarMessage({ content: 'Document deleted.' });
      });
  };

  const handleRemoveFileClick = (document) => {
    setCardDocument(document);
    setDocumentId(document.id);
    setRemoveFileOpen(true);
  };

  const options = [
    {
      label: 'Open',
      click: (document) => handleOpenDownloadFileClick(document, true),
      isVisible: true
    },
    {
      label: 'Download',
      click: (document) => handleOpenDownloadFileClick(document, false),
      isVisible: true
    },
    {
      label: 'Remove',
      click: (document) => handleRemoveFileClick(document),
      isVisible: isAdmin
    }
  ];

  const NoDocuments = () =>
    !isLoading ? <Typography variant="h4" className={classes.noDocs}>No documents available.</Typography> : null;

  const SideNav = () => (
    <HelpSearchDrawer
      searchTerm={searchTerm}
      permanentSearchTerms={PermanentSearchTerms}
      searchTerms={searchTerms}
      onSearch={handleSearch}
      onSelect={handleSelectSearchTerm}
      onRemove={handleRemoveSearchTerm}
      onClear={handleClearSearchTerms}
    />
  );

  let filterDocuments = helpDocuments?.filter(doc => {
    return !Boolean(searchTerm) ||
      doc.description.toLowerCase().includes(searchTerm.toLowerCase());
  }) || [];

  if(!associate.canViewBackOfficeHelpDocs) {
    filterDocuments = filterDocuments?.filter(doc => doc.permAllUsers === true);
  }

  if(showBackOfficeOnly) {
    filterDocuments = filterDocuments?.filter(doc => doc.permBackOfficeOnly === true);
  }

 const ascSortedDocuments = Core.Utils.sortBy(filterDocuments, 'description');


  const sortedDocuments = sortAscending ?
    ascSortedDocuments :
    ascSortedDocuments.reverse();

  const sortList = () => {
    // Toggle the direction.
    setSortAscending(!sortAscending);
  };

  return (
    <HelpWidthLayout SideNav={SideNav}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Grid container alignItems={"center"} justifyContent={"space-between"}>
            <Grid item xs={12} md={7}>
              <Typography variant="h2">Help</Typography>
            </Grid>
            {associate.canViewBackOfficeHelpDocs &&
            <Grid item xs={12} md={3}>
              <Checkbox
                  value={showBackOfficeOnly}
                  id={"showBackOfficeOnly"}
                  name={"showBackOfficeOnly"}
                  label={'Only Show "Back Office Only" Documents'}
                  onChange={(e) => setShowBackOfficeOnly(e.target.value)}
              />
            </Grid>
            }
            {
              isAdmin && <Grid item xs={12} md={2} className={classes.alignRight}>
                <AppButton onClick={handleNewDocument}>
                  <Publish /> Upload
              </AppButton>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">Listing of helpful documentation.</Typography>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        {
          filterDocuments?.length > 0 ? <>
            <Grid item container alignItems="center">
              <div onClick={sortList} className={classes.pointer}>
                <ArrowDownward className={sortAscending ? classes.iconDescending : null} />
                <span className={classes.leftPad5}>Sorted {sortAscending ? 'Ascending' : 'Descending'}</span>
              </div>
            </Grid>
            <Grid item>
                  <AppCard cardContent>
                    <HelpList
                      documents={sortedDocuments}
                      options={options}
                      permissions={{canViewBackOfficeHelpDocs}}
                    />
                  </AppCard>
            </Grid>
          </>
            : <Grid item><NoDocuments /></Grid>
        }
      </Grid>
      <HelpDocumentDrawer
        anchor="right"
        documentTypes={documentTypes}
        defaultValue={documentTypes[0]}
        open={openNewDocument}
        onClose={handleCloseNewDocument}
        onPublish={handlePublishClick}
      />
      { cardDocument && <AppDocumentRemoveFile
        document={cardDocument}
        open={removeFileOpen}
        onClose={handleRemoveFileClose}
        onConfirmClick={handleRemoveFileConfirmClick}
      />
      }
    </HelpWidthLayout>
  )
}
export default ComponentBuilder
  .wrap(Help)
  .stateToProps((state, ownProps) => (
    {
      associate: state.persona.associate,
      isLoading: isProcessActive(state, LoadProcessName),
      isAdmin: useIsAdministrator(),
      canViewBackOfficeHelpDocs: state.persona.associate.canViewBackOfficeHelpDocs,
      helpDocuments: state.support.helpDocuments
    }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load() {
        dispatch(await shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.support.loadHelpDocuments());
        dispatch(await shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.support.dispose());
      },
      async deleteHelpDocument(documentId) {
        dispatch(await shell.actions.support.deleteHelpDocument(documentId));
      },
      async publishHelpDocument(helpDocument) {
        dispatch(await shell.actions.support.publishHelpDocument(helpDocument));
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    }
  })
  .build();