import React from 'react';
import ComponentBuilder from '../../../core/ComponentBuilder';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AppDenseGrid from '../../../core/components/AppDenseGrid/AppDenseGrid';
import {Grid} from '@material-ui/core';
import ReportNav from '../ReportNav';
import CrmCollectionAssociateDropDown from '../../../crm/components/CrmCollectionAssociateDropDown';
import CrmAssociateDropDown from '../../../crm/components/CrmAssociateDropDown';
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import AgentTooltipComponent from "../../../crm/components/agent-tooltip/AgentTooltipComponent";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
const LoadProcessName = 'Admin.InvoiceAgingSummaryPage.Load';

const LoadRecords = async (filter, offset, limit, sort) => {
  return await window.shell.gateway.getCompanyInvoiceAgingSummary(filter, offset, limit, sort);
};

const InvoiceAgingSummaryPage = (props) => {

  usePageTitle("TGF: Invoice Aging Summary");

  const gridColumns = [
    {
      title: 'Customer Name',
      field: 'companyName',
      styles: {
        width: '25%'
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/address-book/${record.companyId}/details`, record.companyName])
    },
    {
      title: 'Rep. First',
      field: 'associateFirstName',
      styles: {},
      dataType: 'component',
      component: ({ rowData: shipmentRecord }) => {
          return (
            <AgentTooltipComponent content={shipmentRecord.associateFirstName} agentId={shipmentRecord.associateId}/>
          );
        }
    },
    {
      title: 'Rep. Last',
      field: 'associateLastName',
      styles: {},
      dataType: 'string'
    },
    {
      title: 'Coll. Rep. First',
      field: 'collectionAssociateFirstName',
      styles: {},
      dataType: 'component',
      component: ({ rowData: shipmentRecord }) => {
        return (
            <AgentTooltipComponent content={shipmentRecord.collectionAssociateFirstName} agentId={shipmentRecord.collectionAssociateId}/>
        );
      }
    },
    {
      title: 'Coll. Rep. Last',
      field: 'collectionAssociateLastName',
      styles: {},
      dataType: 'string'
    },
    {
      title: 'Total Amt. Due',
      field: 'totalAmountDue',
      styles: {},
      dataType: 'currency'
    },
    {
      title: 'Current',
      field: 'nonDelinquentCount',
      styles: {
        cell: {
          fontWeight: 'bold'
        }
      },
      dataType: 'number'
    },
    {
      title: '1-5 Days',
      field: 'range1To15Count',
      styles: {
        color: '#E97452',
        cell: {
          fontWeight: 'bold'
        }
      },
      dataType: 'number'
    },
    {
      title: '16-30 Days',
      field: 'range16To30Count',
      styles: {
        color: '#D65D42',
        cell: {
          fontWeight: 'bold'
        }
      },
      dataType: 'number'
    },
    {
      title: '31-45 Days',
      field: 'range31To45Count',
      styles: {
        color: '#C34632',
        cell: {
          fontWeight: 'bold'
        }
      },
      dataType: 'number'
    },
    {
      title: '46-60 Days',
      field: 'range46To60Count',
      styles: {
        color: '#B12E21',
        cell: {
          fontWeight: 'bold'
        }
      },
      dataType: 'number'
    },
    {
      title: '61-90 Days',
      field: 'range61To90Count',
      styles: {
        color: '#9E1711',
        cell: {
          fontWeight: 'bold'
        }
      },
      dataType: 'number'
    },
    {
      title: '91+ Days',
      field: 'range91PlusCount',
      styles: {
        color: '#8B0001',
        cell: {
          fontWeight: 'bold'
        }
      },
      dataType: 'number'
    }
  ];

  const {
    loadRecords,
    isAdmin
  } = props;

  const [recordCount, setRecordCount] = React.useState(0);
  const [filter, setFilter] = React.useState({});
  const [records, setRecords] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['companyName', 'asc']]);

  React.useEffect(() => {
    loadRecords(filter, offset, limit, sort)
      .then(({ invoiceAgingSummaryRows, count }) => {
        setRecords(invoiceAgingSummaryRows);
        setRecordCount(count);
      })
      .catch((error) => console.error(error));
  }, [filter, offset, limit, sort, loadRecords]);

  const handleChangePage = (e, page) => {
    setOffset(page * limit);
  };
  const handleSortChange = (column) => {

    const [existingColumn, existingOrder] = sort[0];

    const order = column === existingColumn ?
      existingOrder === 'asc' ? 'desc' : 'asc' :
      'asc';

    setSort([[column, order]]);
  };
  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  const handleAssociateChange = (associate) => {
    setOffset(0);
    setFilter({
      ...filter,
      associateId: associate?.id || undefined
    });
  };

  const handleCollectionsPersonnelChange = (associate) => {
    setOffset(0);
    setFilter({
      ...filter,
      collectionAssociateId: associate ? associate.id : undefined
    });
  };

  return (
    <FullWidthLayout title={'Invoice Aging Summary'} SideNav={ReportNav}>
      <Grid container spacing={2}>
        {
          isAdmin &&
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} md={3}>
              <label htmlFor="associate">Associate</label>
              <CrmAssociateDropDown
                id={'associate'}
                onChangeAssociate={handleAssociateChange}
                autoSelectFirst={false}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CrmCollectionAssociateDropDown
                useUnassignedOption
                label={'Collections Personnel'}
                onChange={handleCollectionsPersonnelChange}
              />
            </Grid>
          </Grid>
        }
        <Grid item xs={12}>
          <AppDenseGrid
            columns={gridColumns}
            data={records}
            count={recordCount}
            orderBy={sort[0][0]}
            order={sort[0][1]}
            rowsPerPage={limit}
            page={offset / limit}
            onSort={handleSortChange}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </FullWidthLayout>
  );
};

export default ComponentBuilder
  .wrap(InvoiceAgingSummaryPage)
  .stateToProps((state, ownProps) => {
    return {
      isAdmin: useIsAdministrator()
    };
  })
  .dispatchToProps((shell, dispatch, getState) => ({
    loadRecords: async (filter, offset, limit, sort) => {
      dispatch(shell.actions.sys.processStart(LoadProcessName));
      const results = await LoadRecords(filter, offset, limit, sort);
      dispatch(shell.actions.sys.processComplete(LoadProcessName));
      return results;
    }
  }))
  .build();
