import React, {useEffect} from 'react';
import {useState} from "react";
import FullWidthLayout from "../../core/layouts/FullWidthLayout";
import OpenLoadBoardListingFilterBar from "./includes/navigation/OpenLoadBoardListingFilterBar";
import NewPostingButton from "./includes/navigation/NewPostingButton";
import ShipmentListing from "./shipment-postings/includes/ShipmentListing";
import DatListing from "./dat-postings/includes/DatListing";
import TruckstopListing from "./truckstop-postings/includes/TruckstopListing";
import {Grid} from "@material-ui/core";
import useIsAdministrator from "../../hubs/personaV2/selectors/useIsAdministrator";
import {useSelector} from "react-redux";
// import DebouncedTextBox from "../../core/kendo/inputs/DebouncedTextBox";
import AddEditDatPosting from "./includes/edit-posting/AddEditDatPosting";
import AddEditTruckstopPosting from "./includes/edit-posting/AddEditTruckstopPosting";
import classNames from "classnames";
import CrmAssociateComboBox from "../../crm/components/CrmAssociateComboBox";
import Core from "@atomos/core";
import RoleNames from "../../hubs/persona/RoleNames";
import TgfDialog from "../../core/kendo/dialog/TgfDialog";
import {useLocation} from "react-router-dom";
import {usePageTitle} from "../../crm/components/customHooks/misc/usePageTitle";

const OpenLoadsBoardPage = (props) => {

    let location = useLocation();
    let currentPath = location.pathname;
    usePageTitle("TGF: Open Loads");

    const shipmentGridRef = React.useRef('shipment');
    const datGridRef = React.useRef('dat');
    const truckstopGridRef = React.useRef('truckstop');

    const [newDatPostingDialogOpen, setNewDatPostingDialogOpen] = useState(false);
    const [newTruckstopPostingDialogOpen, setTruckstopPostingDialogOpen] = useState(false);
    // const [activeGrid, setActiveGrid] = useState('SHIPMENT');

    const [shipmentCount, setShipmentCount] = useState(0);
    const [datPostingCount, setDatPostingCount] = useState(0);
    const [truckstopPostingCount, setTruckstopPostingCount] = useState(0);

    const [filterAssociate, setFilterAssociate] = React.useState(null);
    const isAdmin = useIsAdministrator();
    const associateRoles = useSelector(state => state.persona.roles);
    const queenRoleId = Core.Utils.get(Core.Utils.find(associateRoles, r => r.name === RoleNames.Queen), 'id', null);

    const onSelectAssociate = async (associate) => {
        const associateValue = associate ? associate.id : null;
        await setFilterAssociate(associateValue);
        allGridRefresh();
    };

    const handleDialogCloseClick = () => {
        setNewDatPostingDialogOpen(false);
        setTruckstopPostingDialogOpen(false);
        allGridRefresh();
    };

    const allGridRefresh = () => {
        shipmentGridRef.current.refresh();
        datGridRef.current.refresh();
        truckstopGridRef.current.refresh();
    }

    const handleCoveredShipmentToggle = () => {
        shipmentGridRef.current.refresh();
    }

    const handleItsRefreshClick = () => {
        truckstopGridRef.current.refresh();
    }

    const handleDatRefreshClick = () => {
        datGridRef.current.refresh();
    }

    useEffect(() => {
        allGridRefresh();
    }, [currentPath])

    return (
        <FullWidthLayout title="Open Loads Board">
            <Grid container spacing={1} direction="column" justifyContent="flex-start">
                <Grid container spacing={1} direction="row" alignItems={"center"}>
                    <Grid item>
                        <OpenLoadBoardListingFilterBar
                            shipmentCount={shipmentCount}
                            datPostingCount={datPostingCount}
                            truckstopPostingCount={truckstopPostingCount}
                        />
                    </Grid>
                    <Grid item>
                        <NewPostingButton
                            setNewPostingDialogOpen={setNewDatPostingDialogOpen}
                            integrationType={"DAT"}
                        />
                        {newDatPostingDialogOpen &&
                            <TgfDialog
                                title={'Open Loads Board - DAT Posting'}
                                onClose={handleDialogCloseClick}
                                width={1000}
                            >
                            <AddEditDatPosting
                                closeDialog={handleDialogCloseClick}
                            />
                        </TgfDialog>
                        }
                    </Grid>
                    <Grid item>
                        <NewPostingButton
                            setNewPostingDialogOpen={setTruckstopPostingDialogOpen}
                            integrationType={"Truckstop"}
                        />
                        {newTruckstopPostingDialogOpen &&
                            <TgfDialog
                                title={'Open Loads Board - ITS Posting'}
                                onClose={handleDialogCloseClick}
                                width={1000}
                            >
                            <AddEditTruckstopPosting
                                closeDialog={handleDialogCloseClick}
                            />
                            </TgfDialog>
                        }
                    </Grid>
                        {isAdmin &&
                          <Grid item>
                              <CrmAssociateComboBox
                                placeholder={'Search agents...'}
                                roleId={queenRoleId}
                                onSelectAssociate={onSelectAssociate}
                                style={{minWidth: "200px"}}
                              />
                          </Grid>
                        }
                    <Grid item xs>
                        <p style={{padding: 8, color: "#171717", fontWeight: "bold", width: "100%"}}>
                            ***IMPORTANT - If a carrier is assigned and saved within a shipment, the corresponding post for that shipment will automatically be deleted.***
                        </p>
                    </Grid>
                </Grid>
                <Grid item>
                    <div className={classNames({hidden: currentPath !== "/open-loads-board/shipments"})}>
                        <ShipmentListing
                            ref={shipmentGridRef}
                            filterAssociate={filterAssociate}
                            onDataChanged={(dataPage) => setShipmentCount(dataPage.totalItemCount)}
                            onCloseDialog={handleDialogCloseClick}
                            onCoveredToggle={handleCoveredShipmentToggle}
                        />
                    </div>
                    <div className={classNames({hidden: currentPath !== "/open-loads-board/dat-postings"})}>
                        <DatListing
                            ref={datGridRef}
                            filterAssociate={filterAssociate}
                            onDataChanged={(dataPage) => setDatPostingCount(dataPage.totalItemCount)}
                            onCloseDialog={handleDialogCloseClick}
                            onRefreshClick={handleDatRefreshClick}
                        />
                    </div>
                    <div className={classNames({hidden: currentPath !== "/open-loads-board/its-postings"})}>
                        <TruckstopListing
                            ref={truckstopGridRef}
                            filterAssociate={filterAssociate}
                            onDataChanged={(dataPage) => setTruckstopPostingCount(dataPage.totalItemCount)}
                            onCloseDialog={handleDialogCloseClick}
                            onRefreshClick={handleItsRefreshClick}
                        />
                    </div>
                </Grid>
            </Grid>
        </FullWidthLayout>
    )
};

export default OpenLoadsBoardPage