import React, {useEffect, useRef, useState} from "react";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import {useHistory, useParams} from "react-router-dom";
import AddressBookRecordNav from "../AddressBookRecordNav";
import {useDispatch, useSelector} from "react-redux";
import useStyles from "../BillingSettingsPage/styles";
import _ from "lodash";
import {Grid} from "@material-ui/core";
import AppButton from "../../../core/components/AppButton/AppMuiButton";
import {Save, Undo} from "@material-ui/icons";
import BillingAddressCard from "./includes/BillingAddressCard";
import BillingNotesCard from "./includes/BillingNotesCard";
import BillingContactsGrid from "./includes/BillingContactsGrid/BillingContactsGrid";
import DeliveryNotesCard from "./includes/DeliveryNotesCard";
import InvoiceConfigurationCard from "./includes/InvoiceConfigurationCard";
import {InvoiceConfigValidationSchema} from "./includes/validation/invoiceConfigValidationSchema";
import useFieldValidator from "../../../crm/components/customHooks/form/validation/useFieldValidator";
import MissingOrDeniedCompanyAlert from "../MissingOrDeniedCompanyAlert";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import CheckIfAuthorized from "../tempSecurity/CheckIfAuthorized";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";

const BillingSettingsPage = () => {
    const classes = useStyles();
    const urlParams = useParams();
    const companyId = parseInt(urlParams?.id);
    const agent = useSelector(state => state?.persona?.associate);
    const isAdmin = useIsAdministrator();
    const modifyingAssociate = useSelector(state => state.persona.modifyingAssociate);
    const personalDispatch = useDispatch();
    const ref = useRef();
    const [company, setCompany] = useState(null)
    usePageTitle(company?.name ? `Add: ${company?.name.substring(0, 20)}` : null);

    const [data, setData] = useState({
        company: {},
        additionalData: {
            emailBatchOptions: [],
            deliveryMethods: [],
            docGroupings: [],
            includeOptions: [],
            sendToContacts: [],
            automationDisabled: null,
        },
        entity: {},
        entityId: null,
        validation: {
            customMessages: {},
            rules: {}
        }
    });

    const [initialData, setInitialData] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [disableEdit, setDisableEdit] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const loadPageDependencies = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAddressBookCompanyBillingPage'));
            personalDispatch(window.shell.actions.sys.processStart('loadPageDependencies'));
            personalDispatch(await window.shell.actions.addressBook.modification.loadCompany(companyId));

            if (companyId) setCompany(await window.shell.gateway.getCompany(companyId));

            const results = await window.shell.gateway.getInvoiceCommunicationConfig(companyId)

            // if we don't dispatch the company load, then the side nav won't load up properly. We're also fetching company name to use on header
            // Should eventually consider re-writing the AddressBookRecordNav component to use new patterns.
            personalDispatch(await window.shell.actions.addressBook.modification.loadCompany(companyId));

            if(results?.entity?.disableAutoInvoicing === null) results.entity.disableAutoInvoicing = true;

            setData(results);

            const dataClone = _.cloneDeep(results);
            setInitialData(dataClone);
            setIsLoaded(true);

        } catch (e) {
            setIsLoaded(false);
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Load Customer Billing',
                color: 'error'
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAddressBookCompanyBillingPage'));
            personalDispatch(window.shell.actions.sys.processComplete('loadPageDependencies'));
        }
    }

    const handleResetClick = () => {
        const initialDataClone = _.cloneDeep(initialData);
        setData(initialDataClone);
        setIsDirty(false);
    }

    const reloadGridData = () => {
        ref.current.refresh();
    }

    useEffect(() => {
        if(agent.canEditCustomerBilling) setDisableEdit(agent.canEditCustomerBilling);
    }, [agent]);

    //* using this to have a flattened object in state. YUP doesn't like to play well with nested objects,
    // so we can either do this, or create multiple schemas and use multiple field validators.
    const [fieldsToValidate, setFieldsToValidate] = useState({});

    useEffect(() => {
        setFieldsToValidate({...data.entity, automationDisabled: data.additionalData.automationDisabled});
    },[data]);
    //*

    const fieldErrors = useFieldValidator(InvoiceConfigValidationSchema, fieldsToValidate, setIsValid);

    const history = useHistory();
    if(company && company.categoryTypeId !== 1){
        history.push(`/address-book/${company.id}/details`);
    }

    const save = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveAddressBookCompanyBillingPage'));
            // save billing contacts
            const billingContactsData = [];
            for(let billingContact of data.additionalData.sendToContacts) {
                billingContactsData.push({
                    id: billingContact?.id || null,
                    companyId: companyId,
                    firstName: billingContact?.firstName || null,
                    lastName: billingContact?.lastName || null,
                    email: billingContact?.email || null,
                    primaryPhone: billingContact?.directPhone || null,
                    cellPhone: billingContact?.mobilePhone || null,
                    fax: billingContact?.fax || null,
                    emailOption: billingContact.emailOption,
                    role: "billing",
                })
            }
            await window.shell.gateway.saveCompanyBillingContacts(companyId, billingContactsData);

            // save invoice config
            const config = {
                ...data.entity,
                additionalData: {
                    automationDisabled: data.additionalData.automationDisabled
                }
            }

            await window.shell.gateway.saveInvoiceCommunicationConfig(companyId, config);
            setIsDirty(false);
            await loadPageDependencies();
            await reloadGridData();
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({content: 'Customer Billing Saved'}));
        } catch (e) {
            console.log(e)
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Customer Billing Update Failed',
                color: 'error'
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveAddressBookCompanyBillingPage'));
        }
    }

    useEffect(() => {
        loadPageDependencies()
    }, []);

    return (
        <FullWidthLayout SideNav={AddressBookRecordNav} title={`Address Book - Billing - ${company?.name || ""}`} className={`${classes.header} address-book-styles`}>
            {company?.name ?
                <CheckIfAuthorized
                    isAdmin={isAdmin}
                    modifyingAssociate={modifyingAssociate}
                    company={company}
                >
                    <Grid container spacing={2}>
                        {/* Buttons */}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <AppButton
                                        type={'submit'}
                                        startIcon={<Save/>}
                                        disabled={!isDirty || !isValid || !disableEdit}
                                        onClick={save}
                                    >Save
                                    </AppButton>
                                </Grid>
                                <Grid item>
                                    <AppButton
                                        type={'button'}
                                        startIcon={<Undo/>}
                                        color={'warning'}
                                        disabled={!isDirty}
                                        onClick={handleResetClick}
                                    >Reset
                                    </AppButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Cards */}
                        {isLoaded &&
                            <>
                                <Grid item xs={4}>
                                    <BillingAddressCard
                                        data={data}
                                        setData={setData}
                                        setIsDirty={setIsDirty}
                                        fieldErrors={fieldErrors}
                                        disableEdit={disableEdit}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <DeliveryNotesCard
                                        data={data}
                                        setData={setData}
                                        setIsDirty={setIsDirty}
                                        fieldErrors={fieldErrors}
                                        disableEdit={disableEdit}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BillingNotesCard
                                        data={data}
                                        setData={setData}
                                        setIsDirty={setIsDirty}
                                        disableEdit={disableEdit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <BillingContactsGrid
                                        data={data}
                                        setData={setData}
                                        setIsDirty={setIsDirty}
                                        ref={ref}
                                        reload={reloadGridData}
                                        disableEdit={disableEdit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InvoiceConfigurationCard
                                        data={data}
                                        setData={setData}
                                        setIsDirty={setIsDirty}
                                        fieldErrors={fieldErrors}
                                        disableEdit={disableEdit}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                </CheckIfAuthorized>
                :
                <MissingOrDeniedCompanyAlert />
            }
        </FullWidthLayout>
    )
}

export default BillingSettingsPage;
