import React from 'react';
import {Redirect} from 'react-router-dom';
import ComponentBuilder from '../../core/ComponentBuilder';
import AppSpinner from '../../core/components/AppSpinner';
import selectIsAuthenticated from '../../hubs/persona/selectors/selectIsAuthenticated';
import {useDispatch} from "react-redux";
import identify from "../../hubs/persona/actions/identify";

const SecurityBlanket = (props) => {

    const {
        identify,
        children,
        isAuthenticated
    } = props;

    const [isAuthChecking, setIsAuthChecking] = React.useState(true);

    React.useEffect(() => {
        identify().then(() => {
            setIsAuthChecking(false);
        });
    }, [isAuthenticated]);

    // When not authenticated and no longer auth checking, send to login.
    const result = (!isAuthenticated && !isAuthChecking) ?
        (<Redirect to={'/login'}/>) :
        // When authenticated and no longer auth checking, allow the children to render.
        (isAuthenticated && !isAuthChecking) ?
            [children] :
            (<AppSpinner/>); // Otherwise, we're still auth checking, show a spinner.

    return result;
};

export default ComponentBuilder
    .wrap(SecurityBlanket)
    .stateToProps((state) => ({
        isAuthenticated: selectIsAuthenticated(state),
    }))
    .dispatchToProps((shell, dispatch) => {
        return {
            async identify() {
                dispatch(await shell.actions.persona.identify());
            }
        };
    })
    .build();
