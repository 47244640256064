import { Button, Grid, Tooltip } from "@material-ui/core";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import _ from "lodash";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import ConditionalWrapper from "../../../../core/kendo/ConditionalWrapper";
import DisplayError from "../../../../core/kendo/display/DisplayError";
import TgfComboBox from "../../../../core/kendo/form/inputs/TgfComboBox";
import TgfTextArea from "../../../../core/kendo/form/inputs/TgfTextArea";
import TgfTextBox from "../../../../core/kendo/form/inputs/TgfTextBox";
import "../../../../core/kendo/form/tgf-form.scss";
import DateOnlyPicker from "../../../../core/kendo/inputs/DateOnlyPicker";
import DateUtils from "../../../../core/utils/dateUtils/dateUtils";
import useFieldValidator from "../../../../crm/components/customHooks/form/validation/useFieldValidator";
import FieldError from "../../../../crm/components/inputs/FieldError";
import useIsAdministrator from "../../../../hubs/personaV2/selectors/useIsAdministrator";
import EditDialog from "../dialog/EditDialog";
import PostAge from "../listingCells/PostAge";
import AutoDeletePostWarning from "./AutoDeletePostWarning";
import { truckstopValidationSchema } from "./formValidationSchemas/truckstopValidationSchema";

const AddEditTruckstopPosting = (props) => {



    const personalDispatch = useDispatch();

    const isAdmin = useIsAdministrator();
    const hasTruckstopCredentials = useSelector((state) => {
        return state.persona.associate.hasTruckstopCredentials;
    });

    const [formData, setFormData] = React.useState(null);
    const [formValues, setFormValues] = useState({});
    const [isRenderable, setIsRenderable] = React.useState(false);
    const [error, setError] = useState(null);
    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(!props.truckstopLoadPostingId);
    const [enablePost, setEnablePost] = useState(false);
    const [postDeletedOrExpired, setPostDeletedOrExpired] = useState(false);
    const [duplicationModalOpen, setDuplicationModalOpen] = useState(false);
    const [duplicationFormValues, setDuplicationFormValues] = useState(null);

    const autoFocusRef = React.useRef(null);

    useEffect(() => {
        if (isRenderable) {
            autoFocusRef.current.element.focus();
        }
    }, [isRenderable, isEditMode]);

    const fieldErrors = useFieldValidator(truckstopValidationSchema, formValues);

    // initial load
    useEffect(() => {
        loadPageDependencies();
    }, []);

    useEffect(() => {
        truckstopValidationSchema.validate(formValues)
            .then(() => {
                setEnablePost(true);
            })
            .catch((e) => {
                //console.log(e);
                setEnablePost(false);
            });
    }, [formValues]);

    const loadPageDependencies = async (postingId = null, shipmentId = null) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadPageDependencies'));

            postingId = postingId ? postingId : (props.truckstopLoadPostingId ?? 0);
            shipmentId = shipmentId ? shipmentId : props.openLoad?.shipmentId;

            let formData;
            try {
                formData = await window.shell.gateway.getTruckstopPostingFormData(postingId, shipmentId);
            } catch (e) {
                if (e.response?.status === 404) {
                    setIsRenderable(false);
                    setPostDeletedOrExpired(true);
                    console.error(e.message);
                }
                throw e;
            }

            if (formData?.entity?.truckstopDeleted) {
                setIsRenderable(false);
                setPostDeletedOrExpired(true);
                return;
            }

            // Doing this programmatically for now to ensure Mike is happy with it, before I alter the original sql columns.
            formData.additionalData.equipmentTypes = formData.additionalData.equipmentTypes.filter(item => item.id !== 12 && item.id !== 43);
            formData.additionalData.equipmentTypes.unshift({ id: 12, display: 'Flatbed - F' });
            formData.additionalData.equipmentTypes.unshift({ id: 43, display: 'Van - V' });
            //

            setFormData(formData);

            setFormValues({
                ...formValues,
                ...formData.entity,
                ...props.truckstopDuplicationFormValues
            });
            setIsRenderable(true);
        } catch (e) {
            setIsRenderable(false);
            setPostDeletedOrExpired(true);
            console.error(e.message);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadPageDependencies'));
        }
    };

    const refreshTruckstopPosting = (postingId) => {
        personalDispatch(window.shell.actions.sys.processStart('refreshTruckstopPosting'));
        window.shell.gateway.refreshTruckstopPosting(postingId).then(async () => {
            await loadPageDependencies(postingId);
        }).finally(() => {
            personalDispatch(window.shell.actions.sys.processComplete('refreshTruckstopPosting'));
        });
    };

    const handleSubmit = async (event) => {
        setError(null);

        event.preventDefault();

        if (formValues) {
            try {
                personalDispatch(window.shell.actions.sys.processStart('saveTruckstopPosting'));
                let saveResponse;
                if (formValues.id) {
                    saveResponse = await window.shell.gateway.updateTruckstopPosting(formValues);
                } else {
                    saveResponse = await window.shell.gateway.saveTruckstopPosting(formValues);
                }

                await loadPageDependencies(saveResponse.id);
                setIsEditMode(!saveResponse.id);

                if (props.onDialogUpdate) {
                    props.onDialogUpdate();
                }
                if (props?.truckstopDuplicationFormValues) {
                    props.closeDialog();
                    personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({ content: 'ITS load duplicated' }));
                } else {
                    personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({ content: 'ITS load saved' }));
                }
            } catch (e) {
                setError(e);
                personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                    content: 'ITS save failed',
                    color: 'error'
                }));
            } finally {
                personalDispatch(window.shell.actions.sys.processComplete('saveTruckstopPosting'));
            }
        }
    };

    const onClickDeleteTruckstopPosting = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('deleteTruckstopPosting'));
            await window.shell.gateway.deleteTruckstopPosting(formData.entityId);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({ content: 'Truckstop load deleted' }));
            props.closeDialog();
        } catch (e) {
            setError(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Truckstop load delete failed',
                color: 'error'
            }));
        } finally {
            closeDeleteConfirmationDialog();
            personalDispatch(window.shell.actions.sys.processComplete('deleteTruckstopPosting'));
        }
    };

    const enterEditMode = () => {
        setIsEditMode(true);
    };

    const closeDeleteConfirmationDialog = () => {
        setIsDeleteConfirmationVisible(false);
    };

    const OpenDeleteConfirmationDialog = () => {
        setIsDeleteConfirmationVisible(true);
    };

    const handleEditModeCancel = () => {
        setFormValues(formData.entity);
        setError(null);
        setIsEditMode(false);
    };

    const applyFormValue = (name, value) => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };
    const handleNumericTextChange = (e) => {
        e.target.value !== null ?
            applyFormValue(e.target.name ?? e.target.element.name, Number(e.target.value))
            : applyFormValue(e.target.name ?? e.target.element.name, null);
    };

    const handleCurrencyChange = (e) => {
        e.target.value !== null && e.target.value > 0 ?
            applyFormValue(e.target.name ?? e.target.element.name, Number(e.target.value))
            : applyFormValue(e.target.name ?? e.target.element.name, null);
    };

    const handleTextChange = (e) => {
        applyFormValue(e.target.name ?? e.target.element.name, e.target.value);
    };

    // const handleDatePickerChange = (e) => {
    //     const value = e.target.value;
    //     value && value.setUTCHours(12,0,0,0);
    //     applyFormValue(e.target.name, value);
    // }

    const handleDateOnlyPickerChange = (e) => {
        if (e.target.value !== null) {
            const v = new Date(e.target.value.setUTCHours(12, 0, 0, 0));
            applyFormValue(e.target.name, v);
        } else {
            applyFormValue(e.target.name, null);
        }
    };

    const handleStringIdChange = (e) => {
        applyFormValue(e.target.name, e.target.value?.id);
    };

    const handleIdChange = (e) => {
        applyFormValue(e.target.name, Number(e.target.value?.id));
    };

    const handleIdsChange = (e) => {
        const values = e.target.value.map(x => Number(x.id));
        applyFormValue(e.target.name, values);
    };

    const handleCloseDuplicationDialog = () => {
        setDuplicationModalOpen(false);
        setDuplicationFormValues(null);
    };

    const handleDuplicateClick = () => {
        const formValuesClone = _.cloneDeep(formValues);
        const formValuesForDuplication = {
            originEarlyDateTime: formValuesClone.originEarlyDateTime,
            originLateDateTime: formValuesClone.originLateDateTime,
            destinationEarlyDateTime: formValuesClone.destinationEarlyDateTime,
            destinationLateDateTime: formValuesClone.destinationLateDateTime,
            originCity: formValuesClone.originCity,
            originState: formValuesClone.originState,
            destinationCity: formValuesClone.destinationCity,
            destinationState: formValuesClone.destinationState,
            weight: formValuesClone.weight,
            length: formValuesClone.length,
            width: formValuesClone.width,
            height: formValuesClone.height,
            equipmentTypeId: formValuesClone.equipmentTypeId,
            transportationModeId: formValuesClone.transportationModeId,
            pieceCount: formValuesClone.pieceCount,
            palletCount: formValuesClone.palletCount,
            postedAllInRateUsd: formValuesClone.postedAllInRateUsd,
            otherEquipmentNeeds: formValuesClone.otherEquipmentNeeds,
            note: formValuesClone.note,
            equipmentOptionIds: formValuesClone.equipmentOptionIds,
            commodityId: formValuesClone.commodityId
        };
        setDuplicationFormValues(formValuesForDuplication);
        setDuplicationModalOpen(true);
    };

    return <React.Fragment>
        {
            isRenderable ?
                <React.Fragment>
                    <form onSubmit={handleSubmit}>
                        <div disabled={!isEditMode} style={{ borderStyle: "none", overflow: "hidden", padding: "10px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <DateOnlyPicker
                                        id={"originEarlyDateTime"}
                                        name={"originEarlyDateTime"}
                                        label={"Early Pickup Date"}
                                        value={formValues.originEarlyDateTime}
                                        onChange={handleDateOnlyPickerChange}
                                        disabled={!isEditMode}
                                        tabIndex={1}
                                        min={new Date()}
                                    />
                                    <FieldError shouldRender={isEditMode}>{fieldErrors.originEarlyDateTime}</FieldError>
                                </Grid>
                                <Grid item xs={3}>
                                    <DateOnlyPicker
                                        id={"originLateDateTime"}
                                        name={"originLateDateTime"}
                                        label={"Late Pickup Date"}
                                        value={formValues.originLateDateTime}
                                        onChange={handleDateOnlyPickerChange}
                                        disabled={!isEditMode}
                                        tabIndex={2}
                                        min={formValues.originEarlyDateTime}
                                    />
                                    <FieldError shouldRender={isEditMode}>{fieldErrors.originLateDateTime}</FieldError>
                                </Grid>
                                <Grid item xs={3}>
                                    <DateOnlyPicker
                                        id={"destinationEarlyDateTime"}
                                        name={"destinationEarlyDateTime"}
                                        label={"Early Del. Date"}
                                        value={formValues.destinationEarlyDateTime}
                                        onChange={handleDateOnlyPickerChange}
                                        min={formValues.originEarlyDateTime}
                                        disabled={!isEditMode}
                                        tabIndex={3}
                                    />
                                    <FieldError shouldRender={isEditMode}>{fieldErrors.destinationEarlyDateTime}</FieldError>
                                </Grid>
                                <Grid item xs={3}>
                                    <DateOnlyPicker
                                        id={"destinationLateDateTime"}
                                        name={"destinationLateDateTime"}
                                        label={"Late Del. Date"}
                                        value={formValues.destinationLateDateTime}
                                        onChange={handleDateOnlyPickerChange}
                                        min={DateUtils.maxDateTime([formValues.originEarlyDateTime, formValues.destinationEarlyDateTime])}
                                        disabled={!isEditMode}
                                        tabIndex={4}
                                    />
                                    <FieldError shouldRender={isEditMode}>{fieldErrors.destinationLateDateTime}</FieldError>
                                </Grid>
                                <Grid item xs={3}>
                                    <Label editorId={"originCity"}>From City</Label>
                                    <TgfTextBox
                                        id={"originCity"}
                                        name={"originCity"}
                                        value={formValues.originCity}
                                        onChange={handleTextChange}
                                        disabled={formData.entityId}
                                        tabIndex={5}
                                        ref={autoFocusRef} //set focus to the modal
                                    />
                                    <FieldError shouldRender={isEditMode}>{fieldErrors.originCity}</FieldError>
                                </Grid>
                                <Grid item xs={3}>
                                    <TgfComboBox
                                        label={"From State"}
                                        id={"originState"}
                                        name={"originState"}
                                        data={formData.additionalData.stateProvinces}
                                        value={formValues.originState}
                                        onChange={handleStringIdChange}
                                        disabled={!isEditMode || formData.entityId}
                                        tabIndex={6}
                                    />
                                    <FieldError shouldRender={isEditMode}>{fieldErrors.originState}</FieldError>
                                </Grid>
                                <Grid item xs={3}>
                                    <Label editorId={"destinationCity"}>To City</Label>
                                    <TgfTextBox
                                        id={"destinationCity"}
                                        name={"destinationCity"}
                                        value={formValues.destinationCity}
                                        onChange={handleTextChange}
                                        disabled={formData.entityId}
                                        tabIndex={7}
                                    />
                                    <FieldError shouldRender={isEditMode}>{fieldErrors.destinationCity}</FieldError>
                                </Grid>
                                <Grid item xs={3}>
                                    <TgfComboBox
                                        label={"To State"}
                                        id={"destinationState"}
                                        name={"destinationState"}
                                        data={formData.additionalData.stateProvinces}
                                        value={formValues.destinationState}
                                        onChange={handleStringIdChange}
                                        disabled={!isEditMode || formData.entityId}
                                        tabIndex={8}
                                    />
                                    <FieldError shouldRender={isEditMode}>{fieldErrors.destinationState}</FieldError>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Label editorId={"weight"}>Weight (lbs)</Label>
                                            <NumericTextBox
                                                id={"weight"}
                                                name={"weight"}
                                                value={formValues.weight}
                                                max={9999999}
                                                onChange={handleNumericTextChange}
                                                disabled={!isEditMode}
                                                tabIndex={9}
                                                spinners={false}
                                            />
                                            <FieldError shouldRender={isEditMode}>{fieldErrors.weight}</FieldError>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Label editorId={"length"}>Length (ft)</Label>
                                            <NumericTextBox
                                                id={"length"}
                                                name={"length"}
                                                value={formValues.length}
                                                max={999}
                                                onChange={handleNumericTextChange}
                                                disabled={!isEditMode}
                                                tabIndex={10}
                                                spinners={false}
                                            />
                                            <FieldError shouldRender={isEditMode}>{fieldErrors.length}</FieldError>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Label editorId={"width"}>Width (ft)</Label>
                                            <NumericTextBox
                                                id={"width"}
                                                name={"width"}
                                                value={formValues.width}
                                                max={999}
                                                onChange={handleNumericTextChange}
                                                disabled={!isEditMode}
                                                tabIndex={12}
                                                spinners={false}
                                            />
                                            <FieldError shouldRender={isEditMode}>{fieldErrors.width}</FieldError>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Label editorId={"height"}>Height (ft)</Label>
                                            <NumericTextBox
                                                id={"height"}
                                                name={"height"}
                                                value={formValues.height}
                                                max={999}
                                                onChange={handleNumericTextChange}
                                                disabled={!isEditMode}
                                                tabIndex={12}
                                                spinners={false}
                                            />
                                            <FieldError shouldRender={isEditMode}>{fieldErrors.height}</FieldError>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <TgfComboBox
                                        label={"Equipment Type"}
                                        id={"equipmentTypeId"}
                                        name={"equipmentTypeId"}
                                        data={formData.additionalData.equipmentTypes}
                                        value={formValues.equipmentTypeId}
                                        onChange={handleIdChange}
                                        disabled={!isEditMode || formData.entityId}
                                        tabIndex={13}
                                    />
                                    <FieldError shouldRender={isEditMode}>{fieldErrors.equipmentTypeId}</FieldError>
                                </Grid>
                                <Grid item xs={3}>
                                    <Label editorId={"transportationModeId"}>Full/Partial</Label>
                                    <DropDownList
                                        id={"transportationModeId"}
                                        name={"transportationModeId"}
                                        data={formData.additionalData.transportationModes}
                                        textField="display"
                                        dataItemKey="id"
                                        value={formData.additionalData.transportationModes.find(x => x.id === formValues.transportationModeId)}
                                        onChange={handleIdChange}
                                        disabled={!isEditMode}
                                        tabIndex={14}
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Label editorId={"pieceCount"}>Pieces</Label>
                                            <NumericTextBox
                                                id={"pieceCount"}
                                                name={"pieceCount"}
                                                value={formValues.pieceCount}
                                                max={999999}
                                                onChange={handleNumericTextChange}
                                                disabled={!isEditMode}
                                                tabIndex={15}
                                                spinners={false}
                                            />
                                            <FieldError shouldRender={isEditMode}>{fieldErrors.pieceCount}</FieldError>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Label editorId={"palletCount"}>Pallets</Label>
                                            <NumericTextBox
                                                id={"palletCount"}
                                                name={"palletCount"}
                                                value={formValues.palletCount}
                                                max={9999}
                                                onChange={handleNumericTextChange}
                                                disabled={!isEditMode}
                                                tabIndex={16}
                                                spinners={false}
                                            />
                                            <FieldError shouldRender={isEditMode}>{fieldErrors.palletCount}</FieldError>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <Label editorId={"postedBy"}>Original Poster</Label>
                                    <DropDownList
                                        id={"postedBy"}
                                        name={"postedBy"}
                                        data={formData.additionalData.availablePosters}
                                        textField="display"
                                        dataItemKey="id"
                                        value={formData.additionalData.availablePosters.find(x => x.id === formValues.postedBy)}
                                        onChange={handleIdChange}
                                        style={{
                                            width: "100%",
                                        }}
                                        // disabled={(isAdmin && !props.openLoad) ? !isEditMode : true}
                                        disabled={true}
                                        tabIndex={17}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Label style={{ color: "green" }} editorId={"estMiles"}>Estimated Miles</Label>
                                    <InfoIconTooltip title={"After a posting is completed, the miles that are displayed are the approximate driving miles according to Google Maps and are based off the involved From and To cities and states."} />
                                    <NumericTextBox
                                        id={"estMiles"}
                                        name={"estMiles"}
                                        onChange={handleNumericTextChange}
                                        value={formValues.estMiles}
                                        disabled={true}
                                        spinners={false}
                                        tabIndex={18}
                                    />
                                    <FieldError shouldRender={isEditMode}>{fieldErrors.estMiles}</FieldError>
                                </Grid>
                                <Grid item xs={3}>
                                    <Label editorId={"postedAllInRateUsd"}>Posted Amount ($USD)</Label>
                                    <NumericTextBox
                                        id={"postedAllInRateUsd"}
                                        name={"postedAllInRateUsd"}
                                        value={formValues.postedAllInRateUsd}
                                        onChange={handleCurrencyChange}
                                        format={"c2"}
                                        disabled={!isEditMode}
                                        tabIndex={19}
                                        min={0}
                                        spinners={false}

                                    />
                                    <FieldError shouldRender={isEditMode}>{fieldErrors.postedAllInRateUsd}</FieldError>

                                </Grid>
                                <Grid item xs={6}>
                                    <Label editorId={"otherEquipmentNeeds"}>Other Equipment Needs (70 max characters)</Label>
                                    <TgfTextArea
                                        id={"otherEquipmentNeeds"}
                                        name={"otherEquipmentNeeds"}
                                        value={formValues.otherEquipmentNeeds ?? ""}
                                        maxLength={70}
                                        onChange={handleTextChange}
                                        disabled={!isEditMode}
                                        tabIndex={20}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Label editorId={"note"}>Special Information (255 max characters)</Label>
                                    <TgfTextArea
                                        id={"note"}
                                        name={"note"}
                                        value={formValues.note}
                                        maxLength={255}
                                        onChange={handleTextChange}
                                        disabled={!isEditMode}
                                        tabIndex={21}
                                    />
                                    <FieldError shouldRender={isEditMode}>{fieldErrors.note}</FieldError>
                                </Grid>
                                <Grid item xs={6}>
                                    <Label editorId={"equipmentType"}>More Load Options</Label>
                                    <MultiSelect
                                        id={"equipmentOptionIds"}
                                        name={"equipmentOptionIds"}
                                        data={formData.additionalData.equipmentOptions}
                                        textField="display"
                                        dataItemKey="id"
                                        value={formData.additionalData.equipmentOptions.filter(x => formValues.equipmentOptionIds.includes(x.id))}
                                        onChange={handleIdsChange}
                                        disabled={!isEditMode}
                                        tabIndex={22}
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TgfComboBox
                                        label={"Commodity"}
                                        id={"commodityId"}
                                        name={"commodityId"}
                                        data={formData.additionalData.commodities}
                                        value={formValues.commodityId}
                                        onChange={handleIdChange}
                                        disabled={!isEditMode}
                                        tabIndex={23}
                                    />
                                </Grid>
                                {isEditMode &&
                                    <Grid container spacing={3} style={{ paddingTop: 30, paddingLeft: 9 }}>
                                        <Grid item>
                                            <ConditionalWrapper
                                                condition={!hasTruckstopCredentials}
                                                wrapper={children =>
                                                    <Tooltip title={<div className={"tgf-kendo-grid-tooltip"}>
                                                        <p>{'You currently do not have valid ITS credentials.'}</p>
                                                    </div>
                                                    }>
                                                        <span>{children}</span>
                                                    </Tooltip>
                                                }
                                            >
                                                <Button variant="contained" color="primary" type="submit" disabled={!hasTruckstopCredentials || !enablePost}>
                                                    {formValues.id ? "Update" : "Post Load"}
                                                </Button>
                                            </ConditionalWrapper>
                                        </Grid>

                                        {formData.entityId &&
                                            <>
                                                <Grid item>
                                                    <Button variant="contained" color="primary" type="submit"
                                                        onClick={() => handleEditModeCancel()}>
                                                        Cancel
                                                    </Button>
                                                </Grid>

                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        className={"delete-button"}
                                                        type="button"
                                                        onClick={OpenDeleteConfirmationDialog}>
                                                        Delete ITS Posting
                                                    </Button>
                                                </Grid>
                                            </>
                                        }

                                        <Grid item xs={6}>
                                            <DisplayError error={error} />
                                        </Grid>
                                        {formData.entityId &&
                                            <Grid item>
                                                <PostAge
                                                    dateRefreshable={new Date(formData.entity.truckstopRefreshable)}
                                                    postId={formValues.id}
                                                    refreshPosting={refreshTruckstopPosting}
                                                    showText={true}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                }
                                {
                                    isDeleteConfirmationVisible &&
                                    <Dialog title={"Please Confirm"}
                                        onClose={closeDeleteConfirmationDialog}
                                        height={100}>
                                        <span>
                                            Are you sure you want to delete this posting?
                                        </span>
                                        <DialogActionsBar>
                                            <button
                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                onClick={closeDeleteConfirmationDialog}
                                            >
                                                No
                                            </button>
                                            <button
                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                onClick={onClickDeleteTruckstopPosting}
                                            >
                                                Yes
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>

                                }
                                {!isEditMode &&
                                    <Grid container spacing={3} style={{ paddingTop: 30, paddingLeft: 9 }}>
                                        <Grid item>
                                            <ConditionalWrapper
                                                condition={!hasTruckstopCredentials}
                                                wrapper={children =>
                                                    <Tooltip title={<div className={"tgf-kendo-grid-tooltip"}>
                                                        <p>{'You currently do not have valid ITS credentials.'}</p>
                                                    </div>
                                                    }>
                                                        <span>{children}</span>
                                                    </Tooltip>
                                                }
                                            >
                                                <Button variant="contained" color="primary" disabled={!hasTruckstopCredentials} onClick={() => enterEditMode()}>
                                                    Edit
                                                </Button>
                                            </ConditionalWrapper>
                                        </Grid>
                                        {formData.entityId &&
                                            <Grid item>
                                                <PostAge
                                                    dateRefreshable={new Date(formData.entity.truckstopRefreshable)}
                                                    postId={formValues.id}
                                                    refreshPosting={refreshTruckstopPosting}
                                                    disabled={!hasTruckstopCredentials}
                                                    disabledReason={'You currently do not have valid ITS credentials.'}
                                                    showText={true}
                                                    tooltip={'When this countdown reaches 00:00, the posting can be refreshed by clicking this icon.  ITS automatically refreshes postings anytime they are updated in some manner.  Otherwise, ITS allows postings to be refreshed only once every 15 minutes.'}
                                                />
                                            </Grid>
                                        }
                                        {!isEditMode &&
                                            <>
                                                <Grid item className={"add-edit-posting_duplicate-btn"}>
                                                    <Button disabled={!hasTruckstopCredentials} variant="contained" color="primary" style={{ padding: "5px 20px" }} onClick={handleDuplicateClick}>
                                                        Duplicate
                                                    </Button>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                }
                                {formData.entityId &&
                                    <Grid item>
                                        <AutoDeletePostWarning
                                            dateString={formData.entity.truckstopExpiration}
                                            tooltip={`ITS automatically deletes postings at 11:59 pm (based on the time zone of the involved From City/State) on the date showing as the Late Pickup Date.  If a Late Pickup Date isn’t completed, the date entered as the Early Pickup Date will be considered the Late Pickup Date.`}
                                        ></AutoDeletePostWarning>

                                    </Grid>
                                }
                            </Grid>

                        </div>
                    </form>
                </React.Fragment>
                : postDeletedOrExpired ? <p style={{ color: "red", fontWeight: "bold" }}> The record you are trying to view is no longer available on the Open Loads page. Please close out of this dialog to refresh the page.</p> : null
        }
        {duplicationModalOpen &&
            <EditDialog
                setEditDialogOpen={setDuplicationModalOpen}
                openedFrom={'ITS'}
                closeDialog={handleCloseDuplicationDialog}
                truckstopDuplicationFormValues={duplicationFormValues}
            />
        }
    </React.Fragment>;
};

export default AddEditTruckstopPosting;
