import { Grid } from "@material-ui/core";
import { Save, Undo } from "@material-ui/icons";
import { Label } from "@progress/kendo-react-labels";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AppAlertStatic from "../../../core/components/AppAlertStatic";
import AppButton from "../../../core/components/AppButton/AppMuiButton";
import TgfTextArea from "../../../core/kendo/form/inputs/TgfTextArea";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import { usePageTitle } from "../../../crm/components/customHooks/misc/usePageTitle";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import AddressBookRecordNav from "../AddressBookRecordNav";
import useStyles from "../BillingSettingsPage/styles";
import MissingOrDeniedCompanyAlert from "../MissingOrDeniedCompanyAlert";
import CheckIfAuthorized from "../tempSecurity/CheckIfAuthorized";

const NotesAutofillPage = () => {
    const classes = useStyles();
    const urlParams = useParams();
    const companyId = parseInt(urlParams?.id);
    const isAdmin = useIsAdministrator();
    const modifyingAssociate = useSelector(state => state.persona.modifyingAssociate);
    const personalDispatch = useDispatch();
    const [company, setCompany] = useState(null);
    usePageTitle(company ? `Add: ${company?.name.substring(0, 20)}` : null);

    const [data, setData] = useState({
        id: null,
        generalNote: null,
        rateConNote: null,
    });
    const [dataClone, setDataClone] = useState(null);
    const [isDirty, setIsDirty] = useState(false);

    const load = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadCompanyAutofill'));
            personalDispatch(window.shell.actions.sys.processStart('loadPageDependencies'));
            personalDispatch(await window.shell.actions.addressBook.modification.loadCompany(companyId));

            if (companyId) setCompany(await window.shell.gateway.getCompany(companyId));

            const autofillFields = await window.shell.gateway.getCompanyAutofillFields(companyId);
            if (autofillFields) {
                setData(autofillFields);
                setDataClone(_.clone(autofillFields));
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadCompanyAutofill'));
            personalDispatch(window.shell.actions.sys.processComplete('loadPageDependencies'));
        }
    };

    const save = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveCompanyAutofill'));

            await window.shell.gateway.saveCompanyAutofillFields(companyId, data);
            setIsDirty(false);
            await load();
        }
        catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Save Autofill Notes',
                color: 'error'
            }));
        }
        finally {
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Autofill Notes Saved'
            }));
            personalDispatch(window.shell.actions.sys.processComplete('saveCompanyAutofill'));
        }
    };

    const handleResetClick = () => {
        setData(_.clone(dataClone));
        setIsDirty(false);
    };

    const handleChange = async (e) => {
        const name = e.target.name;
        const value = e.target.value ?? "";
        setData({
            ...data,
            [name]: value,
        });
        setIsDirty(true);
    };

    useEffect(() => {
        load();
    }, []);

    return (
        <FullWidthLayout SideNav={AddressBookRecordNav} title={`Address Book - Notes Autofill - ${company?.name || ""}`} className={`${classes.header} address-book-styles`}>
            {company?.name ?
                <CheckIfAuthorized
                    isAdmin={isAdmin}
                    modifyingAssociate={modifyingAssociate}
                    company={company}
                >
                    <Grid container spacing={2}>
                        {/* Buttons */}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <AppButton
                                        type={'submit'}
                                        startIcon={<Save />}
                                        disabled={!isDirty}
                                        onClick={save}
                                    >Save
                                    </AppButton>
                                </Grid>
                                <Grid item>
                                    <AppButton
                                        type={'button'}
                                        startIcon={<Undo />}
                                        color={'warning'}
                                        disabled={!isDirty}
                                        onClick={handleResetClick}
                                    >Reset
                                    </AppButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* info message */}
                        <Grid item xs={12}>
                            <AppAlertStatic
                                title={<span>The notes entered/saved in the fields below will autofill on shipments accordingly upon this customer being selected in the "Customer" field.  Afterwards, the shipments' fields can be edited as desired. <br />  Please remember that when duplicating shipments, the General Notes and Rate Con Notes fields are both completed using the data from the shipment that is being duplicated.</span>}
                                color={"info"}
                            />
                        </Grid>
                        {/* inputs */}
                        <Grid item xs={6}>
                            <Label editorId={"generalNote"}>"General Notes" Autofill Notes (850 max characters)</Label>
                            <TgfTextArea
                                id={"generalNote"}
                                name={"generalNote"}
                                value={data?.generalNote}
                                onChange={handleChange}
                                maxLength={850}
                                rows={8}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Label editorId={"generalNote"}>"Rate Con Notes" Autofill Notes (850 max characters)</Label>
                            <TgfTextArea
                                id={"rateConNote"}
                                name={"rateConNote"}
                                value={data?.rateConNote}
                                onChange={handleChange}
                                maxLength={850}
                                rows={8}
                            />
                        </Grid>
                    </Grid>
                </CheckIfAuthorized>
                :
                <MissingOrDeniedCompanyAlert />}
        </FullWidthLayout>
    );
};

export default NotesAutofillPage;
