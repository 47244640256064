import React from 'react';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AddressBookRecordNav from '../AddressBookRecordNav';
import ComponentBuilder from '../../../core/ComponentBuilder';
import composePageTitle from '../../../core/utils/composePageTitle';
import {Redirect} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import FinancialSummaryCard from './includes/FinancialSummaryCard';
import PropTypes from 'prop-types';
import ModifyFinancialsDialog from './includes/ModifyFinancialsDialog';
import CreditLimitChangesCard from './includes/CreditLimitChangesCard';
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import "./FinancialHealthPage.scss";
import CheckIfAuthorized from "../tempSecurity/CheckIfAuthorized";
import MissingOrDeniedCompanyAlert from "../MissingOrDeniedCompanyAlert";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const LoadProcessName = 'AddressBook.FinancialHealthPage.Load';
const SaveProcessName = 'AddressBook.FinancialHealthPage.Save';

const FinancialHealthPage = (props) => {

  const {
    company,
    isAdmin,
    modifyingAssociate,
    companyFinancial,
    companyFinancialHealthSummary,
    companyInvoiceSettings,
    companyCreditLimitChanges,
    match,
    load,
    loadFinancialHealthSummary,
    saveFinancialChanges,
    dispose,
    sendSnackbarMessage,
    createEmptyCompanyInvoiceSettings,
    createEmptyCompanyFinancial
  } = props;

  const companyId = match.params.id ?
    parseInt(match.params.id) : null;
  usePageTitle(company?.name ? `Add: ${company.name.substring(0, 20)}` : null);

  const [showModifyFinancialsDialog, setShowModifyFinancialsDialog] = React.useState(false);

  React.useEffect(() => {
    if (companyId) {
      load(companyId);
    }
    return () => dispose();
  }, [companyId, dispose, load]);

  const handleFinancialSummaryEditClick = () => {
    setShowModifyFinancialsDialog(true);
  };

  const handleFinancialSummarySaveClick = (changes) => {

    const {
      allowMarketingEngagement,
      allowInvoiceBackdating,
      autoCreditIncreaseThreshold,
      autoCreditIncreaseIncrement,
      dunBradstreetScore,
      paydexScore,
      paydexAverageDaysToPay,
      creditRiskIndicator,
      feinNumber,
      dunsNumber
    } = changes;

    const updatedCompany = company.allowMarketingEngagement !== allowMarketingEngagement ?
      {
        ...company,
        allowMarketingEngagement
      } : null;

    const updatedCompanyInvoiceSettings = companyInvoiceSettings.allowInvoiceBackdating !== allowInvoiceBackdating ?
      {
        ...(companyInvoiceSettings || createEmptyCompanyInvoiceSettings(companyId)),
        companyId,
        allowInvoiceBackdating
      } : null;

    const updatedCompanyFinancial = {
      ...(companyFinancial || createEmptyCompanyFinancial(companyId)),
      companyId,
      autoCreditIncreaseThreshold,
      autoCreditIncreaseIncrement,
      dunBradstreetScore,
      paydexScore,
      paydexAverageDaysToPay,
      creditRiskIndicator,
      feinNumber,
      dunsNumber
    };
    console.log({
      companyFinancial,
      updatedCompanyFinancial
    });

    setShowModifyFinancialsDialog(false);
    saveFinancialChanges(updatedCompany, updatedCompanyInvoiceSettings, updatedCompanyFinancial)
      .then(() => sendSnackbarMessage({ content: 'Financial settings saved.' }))
      .then(() => loadFinancialHealthSummary(companyId))
      .catch((error) => {
        console.error(error);
        sendSnackbarMessage({ content: 'Saving of financial settings failed.', color: 'error' });
      });
  };

  const handleFinancialSummaryCancelClick = () => {
    setShowModifyFinancialsDialog(false);
  };

  // Company ID is required, redirect back to listing if not provided.
  if (!companyId)
    return (<Redirect to={'/address-book'} />);

  const title = composePageTitle('Address Book', 'Financial Health', company?.name);

  return (
    <FullWidthLayout SideNav={AddressBookRecordNav} title={title} className="address-book-styles">
      {company !== null ?
        <CheckIfAuthorized
          isAdmin={isAdmin}
          modifyingAssociate={modifyingAssociate}
          company={company} >
          <Grid container spacing={2} justifyContent={'flex-start'} direction={'row'}>
            <Grid item xs={12} md={4}>
              <FinancialSummaryCard
                isAdmin={isAdmin}
                companyFinancialHealthSummary={companyFinancialHealthSummary}
                onEditClick={handleFinancialSummaryEditClick}
              />
            </Grid>
            <Grid item xs={12} md={4} >
              <CreditLimitChangesCard
                  companyCreditLimitChanges={companyCreditLimitChanges}
              />
            </Grid>
          </Grid>
        </CheckIfAuthorized>
        :
        <MissingOrDeniedCompanyAlert />}

      {
        showModifyFinancialsDialog &&
        <ModifyFinancialsDialog
          autoCreditIncreaseThreshold={companyFinancial?.autoCreditIncreaseThreshold}
          autoCreditIncreaseIncrement={companyFinancial?.autoCreditIncreaseIncrement}
          dunBradstreetScore={companyFinancial?.dunBradstreetScore}
          paydexScore={companyFinancial?.paydexScore}
          paydexAverageDaysToPay={companyFinancial?.paydexAverageDaysToPay}
          creditRiskIndicator={companyFinancial?.creditRiskIndicator}
          allowMarketingEngagement={company?.allowMarketingEngagement}
          allowInvoiceBackdating={companyInvoiceSettings?.allowInvoiceBackdating}
          feinNumber={companyFinancial?.feinNumber}
          dunsNumber={companyFinancial?.dunsNumber}
          onSaveClick={handleFinancialSummarySaveClick}
          onCancelClick={handleFinancialSummaryCancelClick}
        />
      }
    </FullWidthLayout>
  );
};

export default ComponentBuilder
  .wrap(FinancialHealthPage)
  .props({
    company: PropTypes.object,
    companyFinancial: PropTypes.object,
    companyFinancialHealthSummary: PropTypes.object,
    load: PropTypes.func.isRequired,
    loadFinancialHealthSummary: PropTypes.func.isRequired,
    saveFinancialChanges: PropTypes.func.isRequired,
    dispose: PropTypes.func.isRequired,
    sendSnackbarMessage: PropTypes.func.isRequired
  })
  .stateToProps((state, ownProps) => ({
    isAdmin: useIsAdministrator(),
    modifyingAssociate: state.persona.modifyingAssociate,
    company: state.addressBook.modification.company,
    companyFinancial: state.addressBook.modification.companyFinancial,
    companyInvoiceSettings: state.addressBook.modification.companyInvoiceSettings,
    companyFinancialHealthSummary: state.addressBook.modification.companyFinancialHealthSummary,
    companyCreditLimitChanges: state.addressBook.modification.companyCreditLimitChanges
  }))
  .dispatchToProps((shell, dispatch, getState) => ({
    async load(companyId) {
      dispatch(shell.actions.sys.processStart(LoadProcessName));
      const actions = await Promise.all([
        shell.actions.addressBook.modification.loadCompany(companyId),
        shell.actions.addressBook.modification.loadCompanyFinancial(companyId),
        shell.actions.addressBook.modification.loadCompanyInvoiceSettings(companyId),
        shell.actions.addressBook.modification.loadCompanyFinancialHealthSummary(companyId),
        shell.actions.addressBook.modification.loadCompanyCreditLimitChanges(companyId)
      ]);
      actions.forEach(dispatch);
      dispatch(shell.actions.sys.processComplete(LoadProcessName));
    },
    async loadFinancialHealthSummary(companyId) {
      dispatch(shell.actions.sys.processStart(LoadProcessName));
      dispatch(await shell.actions.addressBook.modification.loadCompanyFinancialHealthSummary(companyId));
      dispatch(shell.actions.sys.processComplete(LoadProcessName));
    },
    async saveFinancialChanges(company, companyInvoiceSettings, companyFinancial) {
      dispatch(shell.actions.sys.processStart(SaveProcessName));
      const actions = await Promise.all([
        company ? shell.actions.addressBook.modification.saveCompany(company) : null,
        companyInvoiceSettings ? shell.actions.addressBook.modification.saveCompanyInvoiceSettings(companyInvoiceSettings) : null,
        companyFinancial ? shell.actions.addressBook.modification.saveCompanyFinancial(companyFinancial) : null
      ]);
      actions.filter(x => x).forEach(dispatch);
      dispatch(shell.actions.sys.processComplete(SaveProcessName));
    },
    async dispose() {
      dispatch(await shell.actions.addressBook.modification.dispose());
    },
    async sendSnackbarMessage(message) {
      dispatch(await shell.actions.sys.sendSnackbarMessage(message));
    },
    createEmptyCompanyInvoiceSettings(companyId) {
      return {
        ...shell.gateway.createEmptyCompanyInvoiceSettings(),
        companyId
      };
    },
    createEmptyCompanyFinancial(companyId) {
      return {
        ...shell.gateway.createEmptyCompanyFinancial(),
        companyId
      };
    }
  }))
  .build();