import {Tooltip} from "@material-ui/core";
import React from "react";
import "./TgfIconTooltip.scss";
import {makeStyles} from "@material-ui/styles";
import PropTypes from "prop-types";

export const TgfIconTooltip = ({content, maxWidth, textAlign, icon}) => {
    // this component actually makes use of MUI. But stuck it under kendo/tooltips for ease of locating.
    const classes = makeStyles(() => ({customWidth: {maxWidth: maxWidth ?? 500, textAlign: textAlign ?? "left"}}))();

    return (
        content !== null ?
        <Tooltip
            style={{whiteSpace: 'pre-line'}}
            classes={{ tooltip: classes.customWidth }}
            title={
                <div className={"tgf-icon-tooltip_content"} >
                    {content}
                </div>
            }>
            {React.createElement(icon, { className: `tgf-icon-tooltip_icon`, fontSize:'small'} )}
        </Tooltip>
    :
        React.createElement(icon, { className: `tgf-icon-tooltip_icon tgf-icon-tooltip_icon-disabled`, fontSize:'small'} )
    )
}


TgfIconTooltip.propTypes = {
    // content can be a string, a React element, or a custom React component
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    // maxWidth is a string or number (e.g., "300px" or 300)
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // textAlign is a string with possible values like "left", "right", "center"
    textAlign: PropTypes.oneOf(['left', 'right', 'center']),
    // icon is a React component (or a function component)
    // if unsure, then look for an existing example.
    icon: PropTypes.elementType.isRequired,
};