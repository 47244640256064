import React, {useEffect, useRef, useState} from "react";
import {useStyles} from "@material-ui/pickers/views/Year/Year";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import AddressBookRecordNav from "../AddressBookRecordNav";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import CheckIfAuthorized from "../tempSecurity/CheckIfAuthorized";
import {Grid} from "@material-ui/core";
import ProductsGrid from "./includes/ProductsGrid";
import MissingOrDeniedCompanyAlert from "../MissingOrDeniedCompanyAlert";
import AppButton from "../../../core/components/AppButton/AppMuiButton";
import {Alert} from "@material-ui/lab";
import {Add} from "@material-ui/icons";
import AddEditProductModal from "./includes/AddEditProductModal";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";

const ProductsPage = () => {
    const classes = useStyles();
    const urlParams = useParams();
    const companyId = parseInt(urlParams?.id);
    const isAdmin = useIsAdministrator();
    const modifyingAssociate = useSelector(state => state.persona.modifyingAssociate);
    const personalDispatch = useDispatch();
    const [company, setCompany] = useState(null);
    const [addEditProductModalOpen, setAddEditProductModalOpen] = useState(false);
    usePageTitle('TGF: Products');

    const load = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadPageDependencies'));
            personalDispatch(await window.shell.actions.addressBook.modification.loadCompany(companyId));

            if (companyId) setCompany(await window.shell.gateway.getCompany(companyId));
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadPageDependencies'));
        }
    }

    const ref = useRef();
    const reloadData = () => {
        ref.current.refresh();
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <FullWidthLayout
            SideNav={AddressBookRecordNav}
            title={`Address Book - Product Catalog - ${company?.name || ""}`}
            className={`${classes.header} address-book-styles`}
        >
            {addEditProductModalOpen &&
                <AddEditProductModal
                    showNewProduct={true}
                    companyId={companyId}
                    setAddEditProductModalOpen={setAddEditProductModalOpen}
                    reloadData={reloadData}
                />
            }
            {company?.name ?
                <CheckIfAuthorized
                    isAdmin={isAdmin}
                    modifyingAssociate={modifyingAssociate}
                    company={company}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AppButton startIcon={<Add/>} onClick={() => setAddEditProductModalOpen(true)}>
                                New Product
                            </AppButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Alert severity="info">
                                The below products can be selected when adding Load Contents to shipments in the CRM or the Rating Engine. You can edit/delete items in your catalog as desired. We do have plans to keep improving the Product Catalog experience.
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <ProductsGrid
                                reloadData={reloadData}
                                ref={ref}
                            />
                        </Grid>
                    </Grid>
                </CheckIfAuthorized>
                :
                <MissingOrDeniedCompanyAlert/>
            }
        </FullWidthLayout>
    )
}

export default ProductsPage;
