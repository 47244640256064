import {Grid} from "@material-ui/core";

export class TgfFormGrid {
    constructor({
                    style = {},
                    spacing = 2,
                    fieldList = [],
                    isVisibleFunction = (data, isEditMode) => {
                        return true
                    }
                }) {
        this.fieldList = fieldList;
        this.style = style;
        this.spacing = spacing;
        this.isVisibleFunction = isVisibleFunction;
    }

    renderGrid(setValue, data, validationErrors, isEditMode) {
        if (this.isVisibleFunction(data, isEditMode)) {
            return <Grid container spacing={this.spacing} style={this.style}>
                {this.renderFieldList(setValue, data, validationErrors, isEditMode)}
            </Grid>;
        } else {
            return <></>;
        }
    }

    renderFieldList(setValue, data, validationErrors, isEditMode) {
        return <>
            {this.fieldList.map((field, i) => this.renderField(field, setValue, data, validationErrors, isEditMode))}
        </>
    }

    renderField(field, setValue, data, validationErrors, isEditMode) {
        return <Grid item xs={field.columnWidth}>
            {field.renderField(setValue, data, validationErrors, isEditMode)}
        </Grid>

    }

}
