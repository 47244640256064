import When from "../../core/components/condtionals/When";
import React from "react";
import useIsAdministrator from "../../hubs/personaV2/selectors/useIsAdministrator";

const WhenAdministrator = props => {

    const isAdmin = useIsAdministrator();

    return (
        <When condition={isAdmin}>
            {props.children}
        </When>
    );
};
export default WhenAdministrator;
