import {Route} from "react-router-dom";
import AccessDeniedPage from "../errors/AccessDeniedPage";
import React from "react";
import CrmDataInitializer from "../../crm/components/CrmDataInitializer";

const AuthorizedRoute = (props) => {

    const {
        component,
        render,
        isAuthorized,
        ...routeProps
    } = props;

    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);

    const safeRender = (safeRenderProps) => {
        const DelegateComponent = !!component ? component : render;
        return (<CrmDataInitializer><DelegateComponent {...safeRenderProps} /></CrmDataInitializer>);
    };

    return (
        employeeContext?.id ?
            <Route
                {...routeProps}
                render={safeRender}
            />
            : <AccessDeniedPage />
    );
}
export default AuthorizedRoute;