import React from 'react';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import ComponentBuilder from '../../../core/ComponentBuilder';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import DetailsForm from "./DetailsForm";
import isNewCarrier from "../../../hubs/carrier/actions/modification/isNewCarrier";
import FormFactor from "../../../core/FormFactor/FormFactor";
import selectStateProvinces from "../../../hubs/support/selectors/stateProvinces";
import CarrierRecordNav from "../CarrierRecordNav";
import useHasCarrierPrivilege from '../../../hubs/personaV2/selectors/useHasCarrierPrivilege';
import "./DetailsPage.scss";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const LoadProcessName = 'Carrier.DetailPage.Load';
const SaveProcessName = 'Carrier.DetailPage.Save';

const DetailsPage = (props) => {

  const {
    carrier,
    dispose,
    freightCategoryTypes,
    history,
    load,
    match,
    saveCarrier,
    stateProvinces,
    hasCarrierPrivilege,
    sendSnackbarMessage
  } = props;

  const mcNumber = match.params.id !== 'new' ?
    match.params.id : undefined;

  usePageTitle(mcNumber ? `Carr: ${carrier?.name.substring(0, 20)}` : "Carrier: New");

  React.useEffect(() => {
    load(mcNumber);

    return () => dispose();
  }, [mcNumber, dispose, load]);

  const stateProvinceObjects = stateProvinces
    .map(([abbreviation, name]) => ({abbreviation, name}));

  const initialValues = {
    hasCarrierPrivilege,
    address1: carrier && carrier.address1,
    alternatePhone: carrier && carrier.alternatePhone,
    blackListed: carrier && carrier.blackListed,
    businessPhone: carrier && carrier.businessPhone,
    cargoLiabilityAmount: carrier && carrier.cargoLiabilityAmount,
    city: carrier && carrier.city,
    dotNumber: carrier && carrier.dotNumber,
    emailAddress: carrier && carrier.emailAddress,
    faxPhone: carrier && carrier.faxPhone,
    freightCategoryTypes,
    generalLiabilityAmount: carrier && carrier.generalLiabilityAmount,
    generalLiabilityExpirationDate: carrier && carrier.generalLiabilityExpirationDate,
    insuranceExpirationDate: carrier && carrier.insuranceExpirationDate,
    isCertified: carrier && carrier.rmisCarrier ?
      carrier.rmisCarrier.isCertified : null,
    isNew: isNewCarrier(mcNumber),
    mcNumber: carrier && carrier.mcNumber,
    name: carrier && carrier.name,
    packetReceivedDate: carrier && carrier.packetReceivedDate,
    postalCode: carrier && carrier.postalCode,
    repFirstName: carrier && carrier.repFirstName,
    repLastName: carrier && carrier.repLastName,
    selectedFreightCategory: carrier && freightCategoryTypes.find(fct => fct.id === carrier.freightCategoryId),
    selectedStateProvince: carrier && carrier.stateProvince ?
      stateProvinceObjects.find(sp => sp.abbreviation === carrier.stateProvince) :
      null,
    stateProvinces: stateProvinceObjects,
    tgfOverride: carrier && carrier.tgfOverride,
    vendorTaxId: carrier && carrier.vendorTaxId
  }

  const handleSubmit = (values, formFactor) => {
    const isNew = isNewCarrier(mcNumber);

    const carrierData = Object.assign({}, carrier, values);

    saveCarrier(carrierData)
      .then((updatedCarrier) => {
        sendSnackbarMessage({ content: 'Carrier saved.' })
        if (isNew)
          history.replace(`/carrier/${updatedCarrier.mcNumber}/details`);
      });
  };

  const FormSchema = Yup.lazy(values => {
    const schema = {
      name: Yup.string()
        .nullable()
        .max(300, 'Max character 300')
        .required('Name is required.'),
      mcNumber: Yup.string()
        .nullable()
        .max(50, 'Max character 50')
        .required('MC# is required.'),
      selectedFreightCategory: Yup.object()
        .nullable()
        .required('Carrier Type must be selected'),
      dotNumber: Yup.string()
        .nullable()
        .max(50, 'Max character 50'),
      vendorTaxId: Yup.string()
        .nullable()
        .max(20, 'Max character 20'),
      repFirstName: Yup.string()
        .nullable()
        .max(50, 'Max character 50'),
      repLastName: Yup.string()
        .nullable()
        .max(50, 'Max character 50'),
      emailAddress: Yup.string()
        .nullable()
        .max(1500, 'Max character 1500')
        .email('Email is invalid.'),
      address1: Yup.string()
        .nullable()
        .max(1500, 'Max character 1500'),
      city: Yup.string()
        .nullable()
        .max(50, 'Max character 50'),
      postalCode: Yup.string()
        .nullable()
        .min(5, 'Zip/Postal Code must be at least 5 digits.')
        .max(7, 'Zip/Postal Code cannot be more than 7 digits.'),
      cargoLiabilityAmount: Yup.string()
        .nullable()
        .max(12, 'Max amount allowed 1,000,000,000.00'),
      generalLiabilityAmount: Yup.string()
        .nullable()
        .max(12, 'Max amount allowed 1,000,000,000.00')

    };
    return Yup.object().shape(schema);
  });

  const titlePostfix = carrier && mcNumber ?
    `${carrier.name} (${mcNumber})` :
    'New';

  return (
    <FullWidthLayout
      SideNav={isNewCarrier(mcNumber) ? null : CarrierRecordNav}
      title={`Carrier - Details - ${titlePostfix}`}
      className="carrier-styles"
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormFactor
            initialValues={initialValues}
            schema={FormSchema}
            onSubmit={handleSubmit}
          >
            {DetailsForm}
          </FormFactor>
        </Grid>
      </Grid>
    </FullWidthLayout>
  )
}
export default ComponentBuilder
  .wrap(DetailsPage)
  .stateToProps((state, ownProps) => ({
    carrier: state.carrier.modification.carrier,
    freightCategoryTypes: state.support.freightCategoryTypes,
    hasCarrierPrivilege: useHasCarrierPrivilege(),
    stateProvinces: selectStateProvinces(state)
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(mcNumber) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));

        const isNew = isNewCarrier(mcNumber);

        if (isNew) {
          const associate = shell.stateStore.getState().persona.associate;
          dispatch(await shell.actions.carrier.modification.loadNewCarrier(associate));
        } else {
          dispatch(await shell.actions.carrier.modification.loadCarrier(mcNumber));
        }

        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.carrier.modification.dispose());
      },
      async saveCarrier(carrierData) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        const saveCarrier = await shell.actions.carrier.modification.saveCarrier(carrierData)
        dispatch(saveCarrier);
        dispatch(shell.actions.sys.processComplete(SaveProcessName));
        return saveCarrier.carrier;
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    }
  })
  .build();