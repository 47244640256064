import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { Label } from '@progress/kendo-react-labels';
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDebouncedValue } from "../../../../hooks/useDebouncedValue";

/* Notes
 JS: This is as close to good auto-complete behavior as I could get (so far). This will form the base for future auto-completes till further notice.
 So please try not to alter / deconstruct this code. Make a clone to test on if you want to try and improve upon this code.
 Bug: For some reason, if exact text is typed, then it wont let you select the dropdown option and does not trigger the onChange. I
 manage to create a workaround that adds a space to the data value used for 'textField' and then removes the space when clicked.
 Just something to keep in mind when building future autocompletes.
 */

const TgfCustomerAutoComplete = (props) => {
    const {
        label,
        id,
        onChange,
        className
    } = props;

    const globalState = useSelector(state => state);
    const associate = globalState.persona.modifyingAssociate;

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [typedValue, setTypedValue] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [shouldLoad, setShouldLoad] = useState(true);

    const debouncedTypedValue = useDebouncedValue(typedValue, 600);

    useEffect(() => {
        if (props.value) {
            setShouldLoad(false);
            setTypedValue(props.value);
            setSelectedValue(props.value);
        }
    }, []);

    const loadCustomers = async (searchTerm) => {
        const filter = {
            companyName: {
                $like: `${searchTerm}%`
            },
            categoryTypeName: props?.companyType || "Customer",
        };
        // if not ace, can only query their companies.
        if (associate.roles[0].name !== "Ace") {
            associate.roles[0].name === "Queen" ? filter.associateId = `${associate.id}` : filter.associateId = `${associate.parentAssociateId}`;
        }

        if (associate.roles[0].name === "Ace") {
            if (props?.agentIdThatOwnsCompany) {
                filter.associateId = `${props.agentIdThatOwnsCompany}`;
            }
        }

        const options = { filter };

        let { companies } = await window.shell.gateway.searchCompanies(options);
        if (companies.length > 0) companies = companies.map(c => { return { ...c, companyName: c.companyName + " " }; });
        setData(companies);
        setIsLoading(false);
        setIsOpen(true);
    };

    useEffect(() => {
        if (debouncedTypedValue && shouldLoad === true) {
            loadCustomers(debouncedTypedValue);
        } else {
            setIsLoading(false);
            setIsOpen(false);
        }
    }, [debouncedTypedValue]);

    const handleOnChange = async (e) => {
        const value = e.target.value;
        if (e.nativeEvent.type === "click" || e.nativeEvent.key === "Enter") {
            setShouldLoad(false);
            setTypedValue(value.trim());
            setSelectedValue(value.trim());

            let company = data.find(company => company.companyName === value);
            if (company?.companyName) company.companyName = company.companyName.trim();
            onChange({ target: { value: company, name: props.name } });
            setIsOpen(false);
        } else {
            setShouldLoad(true);
            setIsLoading(true);
            setIsOpen(false);
            setTypedValue(value);
        }
    };

    const onBlur = () => {
        setTypedValue(selectedValue);
        setShouldLoad(false);
        setIsOpen(false);
        setData([]);
    };
    const onFocus = () => {
        data.length > 0 && setIsOpen(true);
    };

    return (
        <>
            {label && <Label editorId={id}>{props.label}</Label>}
            <AutoComplete
                {...props}
                placeholder={"Search..."}
                className={"tgf-kendo-combobox" + (className ? ` ${className}` : "")}
                textField={"companyName"}
                value={typedValue}
                data={data}
                loading={isLoading}
                onChange={handleOnChange}
                opened={isOpen}
                onBlur={onBlur}
                onFocus={onFocus}
                clearButton={Boolean(selectedValue)}
                label={null} // we set this to null, because label is used in the Label component up above.
            />
        </>
    );
};

TgfCustomerAutoComplete.propTypes = {
    // Will show a label if one provided.
    label: PropTypes.string,
    // Id of the input.
    id: PropTypes.string.isRequired,
    // Name of the input, this is sometimes used to reference in the event handler.
    name: PropTypes.string.isRequired,
    //
    value: PropTypes.any,
    // A reference to your onChange event handler.
    onChange: PropTypes.func.isRequired,
    // If provided, will specify the company category type in the search filter. E.G customer, Shipper, etc
    companyType: PropTypes.string,
    // Bool to disable the input if needed.
    disabled: PropTypes.bool,
    // Tab index to allow users to cycle through the inputs in order using the tab button.
    tabIndex: PropTypes.number,
};

export default TgfCustomerAutoComplete;

