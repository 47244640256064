import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const doubleBrokered = async (shell, options) => {

  const { doubleBrokeredLoads, count } = await shell.gateway.getDoubleBrokeredLoads(options);

  return {
    type: ActionTypes.Automation.DoubleBrokered.Loaded,
    doubleBrokeredLoads,
    count
  };
};

export default ActionBuilder
  .for(doubleBrokered)
  .build();
