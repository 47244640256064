import {TgfBaseFormField} from "./TgfBaseFormField";
import React from "react";
import {Button, Tooltip} from "@material-ui/core";

export class TgfButtonFormField extends TgfBaseFormField {
    constructor({
                    columnWidth = 1,
                    variant = 'contained',
                    color = 'primary',
                    type = null,
                    mouseOverTooltipContent = null,
                    disabledFunction = (isEditMode, data, validationErrors) => {
                        return false;
                    },
                    contentFunction = (isEditMode, data, validationErrors) => {
                        return {};
                    },
                    isVisibleFunction = (isEditMode, data, validationErrors) => {
                        return true;
                    },
                    onClickHandler = (isEditMode, data, validationErrors) => {
                    },
                }) {
        super(columnWidth, '', '');

        this.variant = variant;
        this.color = color;
        this.type = type;
        this.disabledFunction = disabledFunction;
        this.contentFunction = contentFunction;
        this.isVisibleFunction = isVisibleFunction;
        this.mouseOverTooltipContent = mouseOverTooltipContent;
        this.onClickHandler = onClickHandler;
    }

    internalOnClick(isEditMode, data, validationErrors) {
        this.onClickHandler(isEditMode, data, validationErrors);
    }

    renderField(setValue, data, validationErrors, isEditMode) {
        this.setValue = setValue;


        if (this.mouseOverTooltipContent !== null) {
            return <>
                {this.isVisibleFunction(isEditMode, data, validationErrors) &&
                    <Tooltip title={this.mouseOverTooltipContent}>
                    <span>
                        <Button
                            variant={this.variant}
                            color={this.color}
                            type={this.type}
                            disabled={this.disabledFunction(isEditMode, data, validationErrors)}
                            onClick={this.internalOnClick.bind(this, isEditMode, data, validationErrors)}
                        >
                            {this.contentFunction(isEditMode, data, validationErrors)}
                        </Button>
                    </span>
                    </Tooltip>
                }
            </>;
        } else {
            return <>
                {this.isVisibleFunction(isEditMode, data, validationErrors) &&
                    <Button
                        variant={this.variant}
                        color={this.color}
                        type={this.type}
                        disabled={this.disabledFunction(isEditMode, data, validationErrors)}
                        onClick={this.internalOnClick.bind(this, isEditMode, data, validationErrors)}
                    >
                        {this.contentFunction(isEditMode, data, validationErrors)}
                    </Button>
                }
            </>;
        }
    }
}
