import React from 'react';
import {Checkbox, Grid} from '@material-ui/core';
import AppSearch from '../../../core/components/inputs/AppSearch';
import ComponentBuilder from '../../../core/ComponentBuilder';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import ListingButtonGroup from './ListingButtonGroup.js';
import AddressBookListingTable from './includes/AddressBookListingTable';
import useDebounce from '../../../core/hooks/useDebounce';
import CrmAssociateDropDown from "../../../crm/components/CrmAssociateDropDown";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import "./ListingPage.scss";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import InfoIconTooltip from "../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

const LoadProcessName = 'ListingPage.Load';

const AllCategoryType = {
  id: '',
  name: 'All',
  requiresAdmin: false
};

const ListingPage = (props) => {
  const {
    match,
    isAdmin,
    categoryTypes,
    companies,
    companyCount,
    load,
    dispose
  } = props;

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['companyName', 'asc']]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('companyName');
  const [showDisabledOnly, setShowDisabledOnly] = React.useState(false);
  const [filterAgentId, setFilterAgentId] = React.useState(null);
  usePageTitle("TGF: Address Book");

  // Determine if the user is filtering by a certain category type.
  const activeCategoryTypeId = match.params.categoryTypeId && parseInt(match.params.categoryTypeId);

  const showAgentFilter = isAdmin && [undefined, 1, 2, 5].includes(activeCategoryTypeId);

  const securedCategoryTypes = categoryTypes
    .filter(t => isAdmin || !t.requiresAdmin);

  const filteredCategoryTypeIds = securedCategoryTypes
    .filter(t => !activeCategoryTypeId || t.id === activeCategoryTypeId)
    .map(t => t.id);

  React.useEffect(() => () => dispose(), []);

  React.useEffect(() => {
    if(!showAgentFilter) {
      setFilterAgentId(null);
    }
  }, [showAgentFilter]);

  // Register a mount/update hook to load companies
  // when the context is ready.
  React.useEffect(() => {
    load(searchTerm, filteredCategoryTypeIds, offset, limit, sort, showDisabledOnly, filterAgentId);
  }, [searchTerm, activeCategoryTypeId, categoryTypes, offset, limit, sort, showDisabledOnly, filterAgentId]);

  const allCategoryTypes = [AllCategoryType]
    .concat(securedCategoryTypes)
    // Attach an isActive flag to make it easier for the
    //ListingButtonGroup to highlight the links.
    .map(t => Object.assign({}, t, {
      isActive: t.id === activeCategoryTypeId ||
        (!t.id && !activeCategoryTypeId)
    }));

  //Handles when the user changes pages within the table.
  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

  // Handles when the user clicks on column headers for sorting.
  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

    setSort([[column, changeOrder]]);
    setOrder(changeOrder);
    setOrderBy(column);
  };

  // Debounce the call to load.
  const searchCompanies = (searchTerm) => {
    setSearchTerm(searchTerm);
    setOffset(0);
  };

  // Event handler for calling the debounced load.
  const handleSearchTermChange = useDebounce((value) => searchCompanies(value), 450);

  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  const handleFilterAgentIdChange = (e) => {
    const value = e?.id ?? "";
    setFilterAgentId(value);
  }

  return (
    <FullWidthLayout title={'Address Book - Companies'} className="adress-book-styles">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={6}>
              <ListingButtonGroup links={convertCategoryTypes(allCategoryTypes, setOffset, setShowDisabledOnly)} />
            </Grid>
            <Grid item xs={2} className={"address-book_associate-dropdown-grid-item"}>
              { showAgentFilter &&
                  <>
                    <CrmAssociateDropDown
                        id={'agent'}
                        placeholder={'Search Agents...'}
                        onChangeAssociate={handleFilterAgentIdChange}
                        autoSelectFirst={false}
                        includeInactive={true}
                    />
                  </>
              }
            </Grid>
            <Grid item xs={2}>
              { activeCategoryTypeId === 1 &&
                  <>
                    <Checkbox checked={showDisabledOnly} onChange={() => setShowDisabledOnly(!showDisabledOnly)}></Checkbox>
                    Show Disabled Customers Only
                  </>
              }
            </Grid>
            <Grid item xs={2} className="address-book-search">
              <InfoIconTooltip title={"This field searches the information within the Name, Affiliate, Phone, Address 1, City, State, and Zip columns below. If the entered characters are anywhere within those columns for an entered address, it will be included within the displayed results."} />
              <AppSearch id={"search"} onChange={(e) => handleSearchTermChange(e.target.value)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AddressBookListingTable
            count={companyCount}
            data={companies}
            orderBy={orderBy}
            order={order}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSortChange}
          />
        </Grid>
      </Grid>
    </FullWidthLayout>
  );
};

const convertCategoryTypes = (categoryTypes, setOffSet, setShowDisabledOnly) => {
  return categoryTypes
    .map(t => ({
      url: `/address-book/listing/${t.id}`,
      title: t.name,
      isActive: t.isActive,
      onClick: () => {setShowDisabledOnly(false); setOffSet(0);}
    }));
};

export default ComponentBuilder
  .wrap(ListingPage)
  .stateToProps((state, ownProps) => {
    return {
      categoryTypes: state.support.companyCategoryTypes,
      companies: state.addressBook.listing.companies,
      companyCount: state.addressBook.listing.companyCount,
      isAdmin: useIsAdministrator()
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      load: async (searchTerm, categoryTypes, offset, limit, sort, showDisabledOnly, filterAgentId) => {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.addressBook.listing.load(searchTerm, categoryTypes, offset, limit, sort, showDisabledOnly, filterAgentId));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      dispose: async () =>
        dispatch(await shell.actions.addressBook.listing.dispose())
    };
  })
  .build();
