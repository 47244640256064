import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {ButtonGroup, Button, Tooltip} from '@material-ui/core';
import combineClassNames from "../../../../core/utils/combineClassNames";
import useIsAdministrator from "../../../../hubs/personaV2/selectors/useIsAdministrator";
import ConditionalWrapper from "../../../../core/kendo/ConditionalWrapper";

/**
 * Provides the navigation bar for the LoadBoard page.
 */
const useStyles = makeStyles((theme) => ({
      root: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
      },
      buttonLink: {
          textDecoration: 'none',
          color: theme.palette.primary.main,
          '&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.tgfGrey.light,
          },
      },
      active: {
          textDecoration: 'none',
          color: theme.palette.white,
          backgroundColor: theme.palette.primary.main,
          '&:hover': {
              color: theme.palette.primary.main,
          }
      },
      badge: {
          borderRadius: 5,
          marginLeft: 5,
          padding: '0 3px',
          color: theme.palette.white,
      },
      activeBadge: {
          backgroundColor: theme.palette.white,
          color: theme.palette.primary.main
      },
      default: {
          backgroundColor: theme.palette.default.main,
      },
      warning: {
          backgroundColor: theme.palette.warning.main,
      },
      error: {
          backgroundColor: theme.palette.error.main,
      },
      primary: {
          backgroundColor: theme.palette.primary.main,
      }
  }
));

const EditDialogNav = ({currentTab, setCurrentTab, shipment, datPostingId, truckstopPostingId}) => {
    const classes = useStyles();

    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);

    const hasDatCredentials = employeeContext?.claims?.hasDatCredentials;
    const hasTruckstopCredentials = employeeContext?.claims?.hasTruckstopCredentials;
    const isAdmin = useIsAdministrator();

    const datEnabled = datPostingId || (!isAdmin && hasDatCredentials);
    const truckstopEnabled = truckstopPostingId || (!isAdmin && hasTruckstopCredentials);

    let datDisabledReason = '';
    if(!datEnabled) {
        if(!hasDatCredentials) {
            datDisabledReason = 'You currently do not have valid DAT credentials.';
        } else if(isAdmin && !datPostingId) {
            datDisabledReason = 'New Postings from open loads can only be made by agents and their assistants.';
        }
    }

    let truckstopDisabledReason = '';
    if(!truckstopEnabled) {
        if(!hasTruckstopCredentials) {
            truckstopDisabledReason = 'You currently do not have valid ITS credentials.';
        } else if(isAdmin && !truckstopPostingId) {
            truckstopDisabledReason = 'New Postings from open loads can only be made by agents and their assistants.';
        }
    }

    const buttonList = [
        {
            title: 'Shipment',
            tabName: 'shipment',
            style: 'primary',
            visible: true,
            disabled: !shipment,
            disabledReason: ''
        },
        {
            title: 'DAT Posting',
            tabName: 'dat',
            style: 'primary',
            visible: true,
            disabled: !datEnabled,
            disabledReason: datDisabledReason
        },
        {
            title: 'ITS Posting',
            tabName: 'its',
            style: 'primary',
            visible: true,
            disabled: !truckstopEnabled,
            disabledReason: truckstopDisabledReason
        },
    ];

    return (
      <ButtonGroup role="group" aria-label="CRM toolbar button group.">
          {
              buttonList.map((item, key) => {
                  const activeClassName = item.tabName === currentTab ? classes.active : classes.buttonLink;
                  const activeBadge = classes[item.style];

                  return (
                      <ConditionalWrapper
                          key={key}
                          condition={item.disabled}
                          wrapper= {children =>
                              <Tooltip title={<div className={"tgf-kendo-grid-tooltip"}>
                                  <p>{item.disabledReason}</p>
                              </div>
                              }>
                                  <span>{children}</span>
                              </Tooltip>
                          }
                      >
                          <Button
                              className={activeClassName}
                              key={key}
                              onClick={() => setCurrentTab(item.tabName)}
                              disabled={item.disabled}
                          >
                              {item.title}
                              <span className={combineClassNames(classes.badge, activeBadge)}>{item.value}</span>
                          </Button>
                      </ConditionalWrapper>
                  );
              })
          }
      </ButtonGroup>
    );
}

export default EditDialogNav;
