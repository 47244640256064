import React, {useState} from 'react';
import ComponentBuilder from '../../core/ComponentBuilder';
import {useDispatch} from "react-redux";

const CrmDataInitializer = (props) => {

  const {
    actions,
    children
  } = props;

  const personalDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
        personalDispatch(window.shell.actions.sys.processStart('crmDataInit'));
        const promises = actions.map(a => a());
        Promise.all(promises)
        .catch(error => console.error(error))
        .finally(() => {
            setIsLoading(false);
            personalDispatch(window.shell.actions.sys.processComplete('crmDataInit'))
        });
  }, []);

  if(isLoading) return null;

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

export default ComponentBuilder
  .wrap(CrmDataInitializer)
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      actions: [
        async () => dispatch(await shell.actions.support.loadShipmentStatusTypes()),
        async () => dispatch(await shell.actions.support.loadFreightCategoryTypes()),
        async () => dispatch(await shell.actions.support.loadCompanyCategoryTypes()),
        async () => dispatch(await shell.actions.support.loadCompanyInvoiceDeliveryMethodTypes()),
        async () => dispatch(await shell.actions.support.loadShipmentEquipmentTypes()),
        async () => dispatch(await shell.actions.support.loadShipmentHazardousStates()),
        async () => dispatch(await shell.actions.support.loadShipmentTrackingPhases()),
        async () => dispatch(await shell.actions.support.loadShipmentFreightClasses()),
        async () => dispatch(await shell.actions.support.loadStateProvinces()),
        async () => dispatch(await shell.actions.support.loadShipmentDocumentTypes()),
        async () => dispatch(await shell.actions.support.loadCarrierDocumentTypes()),
        async () => dispatch(await shell.actions.support.loadShipmentPackageTypes()),
        async () => dispatch(await shell.actions.support.loadShipmentTruckLoadValueConfirmationType()),
        async () => dispatch(await shell.actions.support.loadCompanyDocumentTypes()),
        async () => dispatch(await shell.actions.persona.loadRoles())
      ]
    };
  })
  .build();