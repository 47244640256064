import { Grid } from "@material-ui/core";
import { Label } from "@progress/kendo-react-labels";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AppAlertStatic from "../../../../core/components/AppAlertStatic";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import TgfDropDownList from "../../../../core/kendo/form/inputs/TgfDropDownList";
import { ShipmentNumberCell } from "../../../open-load-board/includes/listingCells/multiUseListingCells";

const FromToHistoryGrid = (props) => {
    const ref = useRef();
    const urlParams = useParams();
    const companyId = parseInt(urlParams?.id);
    const personalDispatch = useDispatch();
    const [involvementFilter, setInvolvementFilter] = useState("All");
    const [pageReady, setPageReady] = useState(false);

    const loadFromToHistory = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadFromToHistory'));
            const result = await window.shell.gateway.getFromToHistory(odata, companyId);
            setPageReady(true);
            return result;
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadFromToHistory'));
        }
    };

    const involvementFilterOptions = [
        {
            id: "All",
            display: "All"
        },
        {
            id: "Shipper",
            display: "Shipper"
        },
        {
            id: "Consignee",
            display: "Consignee"
        },
        {
            id: "Multi-Stop Location",
            display: "Multi-Stop Location"
        }
    ];

    const handleFilterState = (e) => {
        setInvolvementFilter(e.value.id);
    };

    useEffect(() => {
        if (pageReady) {
            ref?.current?.refresh();
        }
    }, [involvementFilter]);

    const applyAdditionalFilters = (odata) => {
        if (involvementFilter !== "All") {
            odata.filter = { involvement: involvementFilter };
        }
        return odata;
    };

    const gridColumns = [
        {
            title: 'Shipment #',
            field: 'bolNumber',
            type: 'custom',
            cell: ShipmentNumberCell,
            width: 80
        },
        {
            title: 'Pickup Date',
            field: 'pickupDate',
            type: 'date',
            width: 100
        },
        {
            title: 'Customer Name',
            field: 'customerName',
            type: 'link',
            url: (props) => `/address-book/${props.customerId}/details`,
        },
        {
            title: 'Type',
            field: 'freightCategory',
            type: 'text',
            width: 40
        },
        {
            title: 'Carrier',
            field: 'carrierName',
            type: 'link',
            url: (props) => `/carrier/${props.carrierMc}/compliance`
        },
        {
            title: 'Current Shipment Status',
            field: 'shipmentStatus',
            type: 'text'
        },
        {
            title: 'Involvement',
            field: 'involvement',
            type: 'text',
            sortable: false
        },
    ];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
                <Label>Involvement:</Label>
                <TgfDropDownList
                    id={"involvement"}
                    name={"involvement"}
                    data={involvementFilterOptions}
                    textField={"display"}
                    dataItemKey={"id"}
                    value={involvementFilter}
                    onChange={handleFilterState}
                />
            </Grid>
            <Grid item xs={12}>
                <AppAlertStatic
                    color={'info'}
                    title={
                        <span>
                            This provides all the shipments that this company has been involved in as a Shipper, a
                            Consignee, or a Multi-Stop Location.
                        </span>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <TgfDataGrid
                    className={"open-load-board_shipment-listing-grid"}
                    ref={ref}
                    onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                    onLoadDataPage={loadFromToHistory}
                    gridColumns={gridColumns}
                    sort={[{ field: "pickupDate", dir: "desc" }]}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500]
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default FromToHistoryGrid;
