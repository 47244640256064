import {Grid} from '@material-ui/core';
import FullWidthLayout from 'core/layouts/FullWidthLayout';
import React, {useEffect, useRef, useState} from 'react';
import useIsAdministrator from "../../../../hubs/personaV2/selectors/useIsAdministrator";
import isHeadOfAgency from "../../../../hubs/personaV2/selectors/useIsHeadOfAgency";
import LeftNav from '../../LeftNav';
import TgfAgencyComboBox from "../../../../core/kendo/form/inputs/specialty/TgfAgencyComboBox";
import AccessDeniedPage from "../../../errors/AccessDeniedPage";
import {AgencyCompanyGrid} from "./AgencyCompanyGrid/AgencyCompanyGrid";
import validateCanViewAgencyPage from "../../../../hubs/personaV2/selectors/canViewAgencyPage";
import {UserCompanyVisibilityModal} from "./UserCustomerVisibilityModal/UserCompanyVisibilityModal";
import EditAssignmentModal from "../includes/EditAssignmentModal/EditAssignmentModal";
import TgfAgencyAssociatesComboBox, {
    TgfAgencyAssociateComboBoxFilters
} from "../../../../core/kendo/form/inputs/specialty/TgfAgencyAssociatesComboBox";
import useEmployeeContext from "../../../../hubs/personaV2/selectors/getEmployeeContext";
import {Checkbox} from "@progress/kendo-react-inputs";
import CrmTypedAddressBookListingComboBox from "../../../../crm/components/CrmTypedAddressBookListingComboBox";
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import "./assignmentPage.scss";

export const AssignmentPage = ({type}) => {
    const isAdmin = useIsAdministrator();
    const gridRef = useRef();

    const headOfAgency = isHeadOfAgency();
    const employeeContext = useEmployeeContext();
    const canViewAgencyPage = validateCanViewAgencyPage();

    const [selectedAgencyId, setSelectedAgencyId] = useState(headOfAgency ? employeeContext?.agency?.id : null);
    const [associateFilter, setAssociateFilter] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    
    const [userCompanyVisibilityModalOpen, setUserCompanyVisibilityModalOpen] = useState(false);
    const [userCompanyVisibilityModalProps, setUserCompanyVisibilityModalProps] = useState({});

    const [editAssignmentModalOpen, setEditAssignmentModalOpen] = useState(false);
    const [editAssignementModalprops, setEditAssignmentModalProps] = useState({});

    const [showOnlyActiveCustomers, setShowOnlyActiveCustomers] = useState(false);
    const [showOnlyInactiveCustomers, setShowOnlyInactiveCustomers] = useState(false);

    const handleCloseAssignmentModal = () => {
        setEditAssignmentModalOpen(false);
        reload();
    }


    const handleSelectAgency = (e) => {
        setAssociateFilter(null);
        setSelectedCompany(null);
        setShowOnlyActiveCustomers(false);
        setShowOnlyInactiveCustomers(false);
        setSelectedAgencyId(e.target.value);
    };

    const onSelectAssociate = (e, type) => {
        const associate = e?.target?.value ? e?.target?.value : e;
        if (!associate?.id) {
            setAssociateFilter(null);
            return
        }
        switch (type) {
            case "agency company owner":
                setAssociateFilter({agencyCompanyOwner: associate})
                break;
            case "agency assigned user":
                setAssociateFilter({agencyAssignedUser: associate})
                break;
            case "agency any":
                setAssociateFilter({agencyAny: associate})
                break;
            default:
                setAssociateFilter(null);
        }
    };

    const handleHasVisibilityColumnClick = (companyId, companyName) => {
        setUserCompanyVisibilityModalProps({companyId, companyName});
        setUserCompanyVisibilityModalOpen(true);
    }

    const handleEditAssignmentClick = (companyId, companyName) => {
        setEditAssignmentModalProps({companyId, companyName});
        setEditAssignmentModalOpen(true);
    }

    const handleShowOnlyActiveCustomers = (e) => {
        setShowOnlyInactiveCustomers(false);
        setShowOnlyActiveCustomers(e.target.value);
    }

    const handleShowOnlyInactiveCustomers = (e) => {
        setShowOnlyActiveCustomers(false);
        setShowOnlyInactiveCustomers(e.target.value);
    }

    const handleCompanyChange = (company) => {
        setAssociateFilter(null);
        setShowOnlyActiveCustomers(false);
        setShowOnlyInactiveCustomers(false);
        setSelectedCompany(company);
    }

    const filterCompaniesByAgency = (filter) => {
        if(selectedAgencyId)
            filter.associateId = selectedAgencyId;
        return filter;
    };

    const reload = () => {
        gridRef.current?.refresh();
    };

    useEffect(() => {
        reload();
    }, [selectedAgencyId, headOfAgency, showOnlyActiveCustomers, showOnlyInactiveCustomers]);

    useEffect(() => {
        if(selectedAgencyId){
            gridRef.current?.setPageIndexAndRefresh(0);
        }
    }, [associateFilter, selectedCompany]);

    const tooltipTitles = {
        ownerDropdown: (
            <>
                <p>Selecting a user here will filter the table below to just those {type === "customer" ? "customers" : "leads"} that the user is the assigned Customer Owner of.</p>
                <p>Inactive users will not display as an option on this dropdown, which is why working with the back office when terminating a Restricted user is important as they can make the transition/reassigning of accounts an easy process.</p>
                <p>We also included UNASSIGNED as an option so {type === "customer" ? "customers" : "leads"} who have no Customer Owner assigned could easily be seen.</p>
            </>
        ),
        assignedDropdown: (
            <>
                <p>Selecting a user here will filter the table below to just those {type === "customer" ? "customers" : "leads"} that the user is an Assigned User of.</p>
                <p>Inactive users will not display as an option on this dropdown, which is why working with the back office when terminating a Restricted user is important as they can make the transition/reassigning of accounts an easy process.</p>
                <p>We also included NONE as an option so {type === "customer" ? "customers" : "leads"} who have no Assigned Users assigned could easily be seen.</p>
            </>
        ),
        visibilityDropdown: (
            <>
                <p>Selecting a user here will filter the table below to just those {type === "customer" ? "customers" : "leads"} that the user has visibility of which depends on their Visibility Permission setting and the customers they are assigned to or owners of.</p>
                <p>Inactive users will not display as an option on this dropdown, which is why working with the back office when terminating a Restricted user is important as they can make the transition/reassigning of accounts an easy process.</p>
            </>
        )
    }

    return (
        canViewAgencyPage ? <FullWidthLayout SideNav={LeftNav} title={`${type === "customer" ? "Customer" : "Lead"} Assignment`}>
            <Grid container spacing={2}  alignItems={"center"}>
                {isAdmin &&
                    <Grid item xs={12} md={3}>
                        <TgfAgencyComboBox
                            label={"Franchise Mode Agencies"}
                            onChange={handleSelectAgency}
                            value={selectedAgencyId}
                            franchiseMode={true}
                            isDisabled={false}
                        />
                    </Grid>
                }
                {isAdmin && <Grid item xs={9}></Grid> }
                <Grid item xs={2} style={{display: "flex"}}>
                    <TgfAgencyAssociatesComboBox
                        placeholder={`Filter by ${type === "customer" ? "customer" : "lead"} owners...`}
                        onChange={(e) => onSelectAssociate(e, "agency company owner")}
                        selectedAgencyAssociate={associateFilter?.agencyCompanyOwner ?? null}
                        activeOnly={true}
                        includeUnassignedOption={true}
                        roleFilter={type === "customer" ? TgfAgencyAssociateComboBoxFilters.customerOwners : TgfAgencyAssociateComboBoxFilters.leadOwners}
                        agencyId={selectedAgencyId}
                        disabled={Boolean(associateFilter?.agencyAny || associateFilter?.agencyAssignedUser || !selectedAgencyId || selectedCompany)}
                    />
                </Grid>
                <Grid item className={"assignment-page_info-icon-tooltip"}>
                    <InfoIconTooltip title={tooltipTitles.ownerDropdown} maxWidth={600} />
                </Grid>
                <Grid item xs={2} style={{display: "flex"}}>
                    <TgfAgencyAssociatesComboBox
                        placeholder={'Filter by assigned users...'}
                        onChange={(e) => onSelectAssociate(e, "agency assigned user")}
                        selectedAgencyAssociate={associateFilter?.agencyAssignedUser ?? null}
                        activeOnly={true}
                        includeNoneOption={true}
                        roleFilter={type === "customer" ? TgfAgencyAssociateComboBoxFilters.assignedToCustomers : TgfAgencyAssociateComboBoxFilters.assignedToLeads}
                        agencyId={selectedAgencyId}
                        disabled={Boolean(associateFilter?.agencyAny || associateFilter?.agencyCompanyOwner || !selectedAgencyId || selectedCompany)}
                    />
                </Grid>
                <Grid item className={"assignment-page_info-icon-tooltip"}>
                    <InfoIconTooltip title={tooltipTitles.assignedDropdown} maxWidth={600} />
                </Grid>
                <Grid item xs={2} style={{display: "flex"}}>
                    <TgfAgencyAssociatesComboBox
                        placeholder={'Filter by user visibility...'}
                        onChange={(e) => onSelectAssociate(e, "agency any")}
                        selectedAgencyAssociate={associateFilter?.agencyAny ?? null}
                        activeOnly={true}
                        companyOwnersOnly={false}
                        agencyId={selectedAgencyId}
                        disabled={Boolean(associateFilter?.agencyAssignedUser || associateFilter?.agencyCompanyOwner || !selectedAgencyId || selectedCompany)}
                    />
                </Grid>
                <Grid item className={"assignment-page_info-icon-tooltip"}>
                    <InfoIconTooltip title={tooltipTitles.visibilityDropdown} maxWidth={600} />
                </Grid>
                {type === "customer" &&
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Checkbox
                                    value={showOnlyActiveCustomers}
                                    id={"showOnlyActiveCustomers"}
                                    name={"showOnlyActiveCustomers"}
                                    label={"Hide All Disabled Customers"}
                                    onChange={handleShowOnlyActiveCustomers}
                                    disabled={Boolean(!selectedAgencyId || selectedCompany)}
                                />
                            </Grid>
                            <Grid item>
                                <Checkbox
                                    value={showOnlyInactiveCustomers}
                                    id={"showOnlyInactiveCustomers"}
                                    name={"showOnlyInactiveCustomers"}
                                    label={"Show Only Disabled Customers"}
                                    onChange={handleShowOnlyInactiveCustomers}
                                    disabled={Boolean(!selectedAgencyId || selectedCompany)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item style={{flex: 1, maxWidth: 300}}>
                    <CrmTypedAddressBookListingComboBox
                        additionalInputProps={{style: {padding: 7}}}
                        types={type === "customer" ? [CrmTypedAddressBookListingComboBox.Customer] : [CrmTypedAddressBookListingComboBox.Lead]}
                        placeholder={`Search ${type === "customer" ? "customers" : "leads..."}`}
                        value={selectedCompany || null}
                        onChange={handleCompanyChange}
                        disabled={!selectedAgencyId}
                        filterMap={filterCompaniesByAgency}
                        popupIcon={' '}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AgencyCompanyGrid
                        type={type}
                        ref={gridRef}
                        handleHasVisibilityClick={handleHasVisibilityColumnClick}
                        handleEditAssignmentClick={handleEditAssignmentClick}
                        selectedAgencyId={selectedAgencyId}
                        selectedCompany={selectedCompany}
                        associateFilter={associateFilter}
                        companyAssociateId={selectedAgencyId}
                        showOnlyActiveCustomers={showOnlyActiveCustomers}
                        showOnlyInactiveCustomers={showOnlyInactiveCustomers}
                    />
                </Grid>
                {userCompanyVisibilityModalOpen &&
                    <Grid item xs={12}>
                        <UserCompanyVisibilityModal
                            {...userCompanyVisibilityModalProps}
                            onClose={() => setUserCompanyVisibilityModalOpen(false)}
                        />
                    </Grid>
                }
                {editAssignmentModalOpen &&
                    <Grid item xs={12}>
                        <EditAssignmentModal
                            {...editAssignementModalprops}
                            onClose={handleCloseAssignmentModal}
                        />
                    </Grid>
                }
            </Grid>
        </FullWidthLayout> : <AccessDeniedPage />
    );
};
