import React from "react";
import {Grid} from '@material-ui/core'
import {Shipment, Invoice} from '@tgf-crm/business';
import {
    Lock,
    Save,
    Undo,
    SettingsEthernet,
    FileCopy,
    Assignment,
} from "@material-ui/icons";
import Core from "@atomos/core";
import AppButton from "../../../core/components/AppButton/AppMuiButton";
import DetailCard from "./includes/DetailCard";

import ReferenceNumberCard from "./includes/ReferenceNumberCard/ReferenceNumberCard";
import GeneralNotesCard from "./includes/GeneralNotesCard";
import CarrierContactDetailCard from "./includes/CarrierContactDetailCard";
import DriverDetailCard from "./includes/DriverDetailCard";
import isHazardous from "../../../hubs/shipment/actions/modification/isHazardous";
import ChangeStatusDialog from './includes/ChangeStatusDialog';
import LockShipmentDialog from './includes/LockShipmentDialog';
import AppTooltip from '../../../core/components/AppTooltip';
import {Link} from 'react-router-dom';
import CurrentCreditRemaining from "./includes/CurrentCreditRemaining";
import StatusTypeNames from "../../../hubs/shipment/StatusTypeNames";
import SecureAction from "../permissions/SecureAction";
import Add from "@material-ui/icons/Add";
import FormFactor from "../../../core/FormFactor/FormFactor";
import * as Yup from "yup";
import AppDialog from "../../../core/components/AppDialog";
import AppDropdown from "../../../core/components/dropdowns/AppDropdown";
import AppMuiInput from "../../../core/components/inputs/AppInput";
import AppValidationFailure from "../../../core/components/AppValidationFailure";
import AppIconButton from "../../../core/components/AppButton/AppIconButton";
import RenderCustomerAgent from "./includes/RenderCustomerAgent";
import HaltRevertInvoice from "../../../crm/components/halt-revert-invoice/HaltRevertInvoice";
import HaltRevertType from "../../../crm/components/halt-revert-invoice/HaltRevertType";
import DisableShipmentAutomation from "./includes/DisableShipmentAutomation";
import AgentTooltipComponent from "../../../crm/components/agent-tooltip/AgentTooltipComponent";

const InvoiceAssertionsFactory = Invoice.InvoiceAssertions;
const ShipmentAssertionsFactory = Shipment.ShipmentAssertions;
const ShipmentInvoiceAssertionsFactory = Shipment.ShipmentInvoiceAssertions;

const renderDetailForm = ({
                              values,
                              supportingValues,
                              errors,
                              isValid,
                              isDirty,
                              setFieldValue,
                              setFieldValues,
                              setSupportingValue,
                              submitForm,
                              resetForm,
                              voidOutShipment
                          }) => {
    const invoiceAssertions = InvoiceAssertionsFactory();
    const shipmentAssertions = ShipmentAssertionsFactory(
      values.shipmentStatus,
      values.freightCategoryTypes
    );
    const shipmentInvoiceAssertions = ShipmentInvoiceAssertionsFactory(
      shipmentAssertions,
      invoiceAssertions
    );
    const permManager = new Shipment.ShipmentStatusPermissionManager(
      invoiceAssertions
    );

    const authorAssociateId = values.shipment
      ? Core.Utils.get(values, "shipment.associate.id")
      : null;

    const associateFullName = values.shipment
      ? `${Core.Utils.get(
        values,
        "shipment.associate.firstName"
      )} ${Core.Utils.get(values, "shipment.associate.lastName")} `
      : "";

    const detailCardProps = {
        selectedShipmentStatus: values.selectedShipmentStatus,
        selectedFreightCategory: values.selectedFreightCategory,
        selectedEquipmentType: values.selectedEquipmentType,
        bolDate: values.bolDate,
        estimatedDeliveryDate: values.estimatedDeliveryDate,
        pickupTime: values.pickupTime,
        deliveryTime: values.deliveryTime,
        customerRep: values.customerRep,
        shipmentStatus: values.shipmentStatus,
        freightCategoryTypes: values.freightCategoryTypes,
        candidateEquipmentTypes: values.candidateEquipmentTypes,
        equipmentTypes: values.equipmentTypes,
        selectedCustomer: values.selectedCustomer,
        selectedConsignee: values.selectedConsignee,
        selectedShipper: values.selectedShipper,
        selectedThirdParty: values.selectedThirdParty,
        isMultipleStop: values.isMultipleStop,
        selectedCarrier: values.selectedCarrier,
        candidateCarriers: values.candidateCarriers,
        isNew: values.isNew,
        isLoadingCandidateCarriers: values.isLoadingCandidateCarriers,
        getCarrier: values.getCarrier,
        getCreditStatus: values.getCreditStatus,
        allowDeliveryDate: values.isTlCategory,
        lockThirdParty: values.isTlCategory,
        thirdPartyCompanies: values.defaultThirdPartyCompanies,
        tallgrassBillingCompany: values.tallgrassBillingCompany,
        hasCustomerChanged: values.hasCustomerChanged,
        lockedAssociate: values.lockedAssociate,
        lockedDate: values.lockedDate,
        isHardCopyPodRequired: Boolean(
          values.selectedCustomer?.companyIsPodRequired
        ),
        setFieldValue,
        setFieldValues,
        errors,
        creditStatus: values.creditStatus,
        bolNumber: values.bolNumber,
        setShipmentOwner: values.setShipmentOwner,
        shipment: values.shipment,
    };

    const referenceNumberCardProps = {
        isAdmin: values.isAdmin,
        selectedShipmentStatus: values.selectedShipmentStatus,
        pickupNumber: values.pickupNumber,
        deliveryNumber: values.deliveryNumber,
        refNum1Description: values.refNum1Description,
        refNum1: values.refNum1,
        refNum2Description: values.refNum2Description,
        refNum2: values.refNum2,
        refNum3Description: values.refNum3Description,
        refNum3: values.refNum3,
        refNum4Description: values.refNum4Description,
        refNum4: values.refNum4,
        isHazardousMaterialsShipment: isHazardous(
          values.hazardousStates,
          values.hazardousStateId
        ),
        isBlind: values.isBlind,
        isDeliveryOrderRequired: values.isDeliveryOrderRequired,
        hazardousStates: values.hazardousStates,
        setFieldValue,
    };

    const GeneralNotesCardProps = {
        isAdmin: values.isAdmin,
        selectedShipmentStatus: values.selectedShipmentStatus,
        note: values.note,
        rateConNote: values.rateConNote,
        ltlCarrierPickupNumber: values.ltlCarrierPickupNumber,
        pickupTerminalNumber: values.pickupTerminalNumber,
        deliveryTerminalNumber: values.deliveryTerminalNumber,
        ratingRefNumber: values.ratingRefNumber,
        proNumber: values.proNumber,
        isTlCategory: values.isTlCategory,
        allowRatingNote: values.isTlCategory,
        allowRatingRef: !values.isTlCategory,
        setFieldValue,
        errors
    };

    const CarrierContactDetailCardProps = {
        isAdmin: values.isAdmin,
        selectedShipmentStatus: values.selectedShipmentStatus,
        experienceNote: values.experienceNote,
        repName: values.repName,
        repPhone: values.repPhone,
        repEmail: values.repEmail,
        carrierCallContactPreferred: values.carrierCallContactPreferred,
        carrierTextContactPreferred: values.carrierTextContactPreferred,
        carrierEmailContactPreferred: values.carrierEmailContactPreferred,
        setFieldValue,
        repPhoneError: errors.repPhone,
        repEmailError: errors.repEmail,
    };

    const DriverDetailCardProps = {
        isAdmin: values.isAdmin,
        selectedShipmentStatus: values.selectedShipmentStatus,
        driverName: values.driverName,
        driverPhone: values.driverPhone,
        driverCallContactPreferred: values.driverCallContactPreferred,
        driverTextContactPreferred: values.driverTextContactPreferred,
        truckNumber: values.truckNumber,
        trailerNumber: values.trailerNumber,
        setFieldValue,
        driverPhoneError: errors.driverPhone,
    };

    const handleResetClick = (e) => resetForm();

    const lockedStatusType = values.shipmentStatus.find(
      (st) => st.name === StatusTypeNames.Locked
    );
    // Map the availableStatusTypes into a structure that provides whether
    // the status can be changed and corresponding permission message.
    const availableStatusTypeOptions = values.shipmentStatus
      // Exclude LOCKED as the change status dialog can never switch to locked directly.
      .filter((st) => st !== lockedStatusType)
      .map((statusType) => {
          const permContext = {
              shipment: values.shipment,
              invoice: values.invoice,
              associate: values.associate,
              currentStatus: values.selectedShipmentStatus.name,
              proposedStatus: statusType.name,
          };

          let permission = permManager.getPermission(permContext);
          if(values.shipment.hasOwnProperty("voidId")) {
              const warningMessage1 = `You are changing the status from “Cancelled/Voided” to "${statusType.name}". Please review all details to make sure this change is correct to avoid potential issues.`;
              const noLockDateMessage = `Confirmed Locked Date must first be completed.`
              const warningMessage2 = `Changing the status from “Cancelled/Voided” to ${statusType.name} is abnormal.  Review all details to make sure this change is correct as this could affect the commission payout and/or the invoicing of the customer.`
              let chosenMessage = '';
              let canAction = true;
              let canView = true;
              switch (statusType.name) {
                  case 'New':
                      chosenMessage = warningMessage1;
                      break;
                  case 'In Transit':
                      chosenMessage = warningMessage1;
                      break;
                  case 'Awaiting POD':
                      chosenMessage = warningMessage1;
                      break;
                  case 'Delivered':
                      chosenMessage = warningMessage1;
                      break;
                  case 'Dispute':
                      chosenMessage = warningMessage1;
                      break;
                  case 'Claim':
                      chosenMessage = warningMessage1;
                      break;
                  case 'Hold':
                      chosenMessage = values.invoice?.actualDeliveryDate ? warningMessage2 : noLockDateMessage;
                      canAction = values.invoice?.actualDeliveryDate ;
                      break;
                  case 'Payments Pending':
                      chosenMessage = values.invoice?.actualDeliveryDate  ? warningMessage2 : noLockDateMessage;
                      canAction = values.invoice?.actualDeliveryDate ;
                      break;
                  case 'Invoice Generated':
                      chosenMessage = values.invoice?.actualDeliveryDate  ? warningMessage2 : noLockDateMessage;
                      canAction = values.invoice?.actualDeliveryDate ;
                      break;
                  case 'Customer Paid':
                      chosenMessage = values.invoice?.actualDeliveryDate  ? warningMessage2 : noLockDateMessage;
                      canAction = values.invoice?.actualDeliveryDate ;
                      break;
                  default:
                      console.log(`Unexpected StatusType: ${statusType.name}.`);
                      break;
              }
              permission = {
                  assertions: [],
                  message: chosenMessage,
                  permission: 'action',
                  purposedStatus: statusType.name,
                  canAction: () => canAction,
                  canView: () => canView
              }
          }
          if (!permission || !(permission.canView() || permission.canAction())) {
              return null;
          }

          statusType.messageType = permission.canAction() ? 'warning' : 'error';

          return {
              statusType,
              canChange: permission.canAction(),
              message: permission.message
          };
      })
      .filter((x) => Boolean(x));

    let warningMessage1 = `You are changing the status from "${values.selectedShipmentStatus.name}" to "Cancelled/Voided". This may be a mistake. Review all details to make sure this change is correct to avoid potential issues.`;
    let warningMessage2 = `You are changing the status from "${values.selectedShipmentStatus.name}" to "Cancelled/Voided". This may be a mistake. Review all details to make sure this change is correct as this could affect the commission payout and/or the invoicing of the customer.`;
    let voidCancelMessage = '';
    let messageType;
    switch (values.selectedShipmentStatus.name) {
        case 'New':
            voidCancelMessage = warningMessage1;
            messageType = 'warning';
            break;
        case 'In Transit':
            voidCancelMessage = warningMessage1;
            messageType = 'error';
            break;
        case 'Awaiting POD':
            voidCancelMessage = warningMessage1;
            messageType = 'error';
            break;
        case 'Delivered':
            voidCancelMessage = warningMessage1;
            messageType = 'error';
            break;
        case 'LOCKED':
            voidCancelMessage = warningMessage1;
            messageType = 'error';
            break;
        case 'Dispute':
            voidCancelMessage = warningMessage1;
            messageType = 'error';
            break;
        case 'Claim':
            voidCancelMessage = warningMessage1;
            messageType = 'error';
            break;
        case 'Hold':
            voidCancelMessage = warningMessage2;
            messageType = 'error';
            break;
        case 'Payments Pending':
            voidCancelMessage = warningMessage2;
            messageType = 'error';
            break;
        case 'Invoice Generated':
            voidCancelMessage = warningMessage2;
            messageType = 'error';
            break;
        case 'Customer Paid':
            voidCancelMessage = warningMessage2;
            messageType = 'error';
            break;
        default:
            console.log(`Unexpected Shipment Status: ${values.selectedShipmentStatus.name}.`);
            break;
    }

    if(values.isAdmin && !values.shipment.hasOwnProperty('voidId')) {
        availableStatusTypeOptions.push({
            statusType: {
                id: -1,
                name: 'Cancelled/Voided',
                bolHistoryOrder: 99,
                messageType: messageType
            },
            canChange: true,
            message: voidCancelMessage
        });
    }

    // The user can change the status when the form isn't dirty and they have
    // available status types to change to.
    const canChangeStatus = !isDirty && availableStatusTypeOptions.length > 0 && (values.isAdmin || !values.shipment.hasOwnProperty('voidId')) ;

    // Determine whether the current shipment can be locked using the button.
    const shipmentReadyToLock =
      values.shipment &&
      values.invoice &&
      !isDirty &&
      shipmentAssertions.readyToLock.matches(values.shipment);

    // Change the lock confirmation message according to admin vs agent.
    const lockConfirmMessage = values.isAdmin
      ? adminConfirmMessage(
        values.shipment.bolNumber,
        values.selectedShipper,
        values.selectedConsignee
      )
      : agentConfirmMessage(
        values.shipment.bolNumber,
        values.selectedShipper,
        values.selectedConsignee
      );

    const handleChangeStatusClick = () => {
        setSupportingValue("showChangeStatusDialog", true);
    };
    const handleChangeStatusCancelClick = () => {
        setSupportingValue("showChangeStatusDialog", false);
    };
    const handleChangeStatusSaveClick = async (statusType, voidReason = null) => {
        if (statusType.id === -1) {
            await values.voidAndReload(voidReason, statusType, setFieldValues, setSupportingValue)
        } else {
            const fieldValues = [
                ["statusId", statusType.id],
                ["selectedShipmentStatus", statusType],
            ];
            setFieldValues(fieldValues).then(() => {
                setSupportingValue("showChangeStatusDialog", false);
                submitForm();
            });
        }


    };
    const handleLockClick = () => {
        setSupportingValue("showLockDialog", true);
    };
    const handleLockCancelClick = () => {
        setSupportingValue("showLockDialog", false);
    };
    const handleLockSaveClick = () => {
        setFieldValues([
            ["statusId", lockedStatusType.id],
            ["selectedShipmentStatus", lockedStatusType],
            ["lockedAssociateId", values.associate.id],
            ["lockedDate", new Date()],
        ]).then(() => {
            setSupportingValue("showLockDialog", false);
            submitForm();
        });
    };

    let {
        saveCompanyNote,
        setIsAddingNote,
        sendSnackbarMessage,
        bolNumber,
        associate,
    } = values;

    const handleNewNoteOpen = (e) => values.setIsAddingNote(true);

    const handleNewNoteClose = (e) => values.setIsAddingNote(false);

    const handleNewNoteSubmit = (values, formFactor) => {
        const newNote = {
            bolNumber: bolNumber,
            associateId: associate.id,
            note: values.note,
            loadTrackingPhaseId: values.selectedPhase.id,
        };
        saveCompanyNote(newNote).then(() => {
            setIsAddingNote(false);
            sendSnackbarMessage({content: "Tracking note saved."});
        });
    };

    let {trackingPhases} = values;

    const newNoteInitialValues = {
        trackingPhases,
        selectedPhase: trackingPhases[0],
        note: "",
        onClose: handleNewNoteClose,
    };

    const NewNoteSchema = Yup.object().shape({
        note: Yup.string().trim("Note is required.").required("Note is required."),
    });

    const renderNewNoteForm = ({
                                   values,
                                   errors,
                                   isValid,
                                   isDirty,
                                   setFieldValue,
                                   submitForm,
                               }) => {
        const newNoteActions = [
            {
                title: "Cancel",
                action: values.onClose,
            },
            {
                title: "Save",
                action: submitForm,
                disabled: !isValid || !isDirty,
            },
        ];

        const handleTrackingPhaseChange = (e, value) => {
            const trackingPhase = value;

            if (trackingPhase) {
                setFieldValue("trackingPhase", trackingPhase.id);
                setFieldValue("selectedPhase", trackingPhase);
            } else {
                setFieldValue("trackingPhase", null);
                setFieldValue("selectedPhase", null);
            }
        };

        const handleNoteChange = (e) => setFieldValue("note", e.target.value);

        return (
          <AppDialog
            title="New Load Tracking Note"
            open={true}
            width="sm"
            onClose={values.onClose}
            actionButtons={newNoteActions}
          >
              <Grid container direction="column" spacing={2}>
                  <Grid item>
                      <AppDropdown
                        field={"name"}
                        fieldIndex={"id"}
                        label="Phase"
                        tabIndex={1}
                        options={values.trackingPhases}
                        value={values.selectedPhase}
                        onChange={handleTrackingPhaseChange}
                      />
                  </Grid>
                  <Grid item>
                      <AppMuiInput
                        autoFocus
                        id="companyNote"
                        label="Note"
                        tabIndex={2}
                        rows="10"
                        multiline={true}
                        inputProps={{
                            maxLength: 1000,
                        }}
                        value={values.note}
                        onChange={handleNoteChange}
                      />
                      <AppValidationFailure message={errors.note}/>
                  </Grid>
              </Grid>
          </AppDialog>
        );
    };

    const RenderTooltip = ({className}) => {
        const latestTrackingNote =
          values.trackingNotes.length > 0
            ? values.trackingNotes[values.trackingNotes.length - 1]
            : null;
        const latestTrackingNoteDate = latestTrackingNote
          ? new Date(latestTrackingNote.createDate).toLocaleString("en-US", {
              dateStyle: "short",
              timeStyle: "short",
          })
          : null;
        return (
          latestTrackingNote && (
            <div className={className} style={{fontSize: '.9rem', right: 10}}>
                <p>{latestTrackingNote.associate?.firstName} {latestTrackingNote.associate?.lastName} {latestTrackingNoteDate}</p>
                <hr/>
                <p><span style={{fontWeight: 'bold'}}>Phase:</span> {latestTrackingNote.phase.name}</p>
                <hr/>
                <p><span style={{fontWeight: 'bold'}}>Note:</span> {latestTrackingNote.note}</p>
            </div>
          )
        );
    };

    const showTooltipText = () => {
        let text = document.querySelector(".tooltiptext");
        text.style.visibility = "visible";
    }

    const hideTooltipText = () => {
        let text = document.querySelector(".tooltiptext");

        if (text) {
            text.style.visibility = "hidden";
        }
    }

    return (
      <React.Fragment>
          <Grid item xs={12}>
              <Grid container spacing={1} style={{display: "flex"}}>
                  <Grid item>
                      <AppButton
                        type={"submit"}
                        startIcon={<Save/>}
                        disabled={!(isValid && isDirty)}
                      >
                          Save
                      </AppButton>
                  </Grid>
                  <Grid item>
                      <AppButton
                        type={"button"}
                        startIcon={<Undo/>}
                        color={"warning"}
                        disabled={!isDirty}
                        onClick={handleResetClick}
                      >
                          Reset
                      </AppButton>
                  </Grid>
                  {!values.isNew && (
                    <React.Fragment>
                        <Grid item>
                            <AppButton
                              type={"button"}
                              startIcon={<SettingsEthernet/>}
                              onClick={handleChangeStatusClick}
                              disabled={!canChangeStatus}
                            >
                                Change Status
                            </AppButton>
                        </Grid>
                        <Grid item>
                            <AppTooltip title={""}>
                                <AppButton
                                  type={"button"}
                                  startIcon={<Lock/>}
                                  onClick={handleLockClick}
                                  disabled={!shipmentReadyToLock}
                                >
                                    Lock
                                </AppButton>
                            </AppTooltip>
                        </Grid>
                        <Grid item>
                            <AppButton
                              type={"button"}
                              component={Link}
                              to={`/shipment/${values.bolNumber}/create-docs`}
                              color={"primary"}
                              startIcon={<Assignment/>}
                              disabled={isDirty || values.shipment.hasOwnProperty('voidId')}
                            >
                                Create Docs
                            </AppButton>
                        </Grid>
                        <Grid item>
                            <AppButton
                              type={"button"}
                              component={Link}
                              to={`/shipment/${values.bolNumber}/duplicate`}
                              startIcon={<FileCopy/>}
                              disabled={isDirty || values.shipment.hasOwnProperty('voidId')}
                              color={"primary"}
                            >
                                Duplicate
                            </AppButton>
                        </Grid>
                        <Grid item>
                            <SecureAction
                              pageName={"loadTracking"}
                              actionName={"addNote"}
                              as={AppButton}
                              startIcon={<Add/>}
                              onClick={handleNewNoteOpen}
                            >
                                Add Tracking Note
                            </SecureAction>
                        </Grid>
                        {values.isAddingNote && (
                          <FormFactor
                            initialValues={newNoteInitialValues}
                            schema={NewNoteSchema}
                            onSubmit={handleNewNoteSubmit}
                          >
                              {renderNewNoteForm}
                          </FormFactor>
                        )}

                        <AppIconButton onMouseEnter={showTooltipText} onMouseLeave={hideTooltipText} Icon={Assignment}/>
                        <RenderTooltip className={'tooltiptext'}></RenderTooltip>

                        <Grid item>
                            <HaltRevertInvoice
                              type={values.shipment.isHalted ? HaltRevertType.REVERT : HaltRevertType.HALT}
                              bolNumber={bolNumber}
                              reload={values.reload}
                              disabled={values?.shipment?.statusId !== 18 || values?.shipment?.automationDisabled}
                            />
                        </Grid>
                        {values.isAdmin &&
                            <Grid item>
                                <DisableShipmentAutomation
                                    automationDisabled={values.shipment.automationDisabled}
                                    bolNumber={bolNumber}
                                    reload={values.reload}
                                    disabled={isDirty}
                                />
                            </Grid>
                        }
                    </React.Fragment>
                  )}
                  <CurrentCreditRemaining creditStatus={values.creditStatus} bolNumber={values.bolNumber}/>
                  <div className={"shipment-details-page_author-owner-text-container"}>
                      {values.selectedCustomer && <RenderCustomerAgent
                        selectedCustomer={values.selectedCustomer}
                        loadCustomerAgent={values.loadCustomerAgent}></RenderCustomerAgent>}
                      <AgentTooltipComponent content={''} agentId={authorAssociateId}><label style={{padding: 0, margin: 2}}>Author: <b>{associateFullName}</b></label></AgentTooltipComponent>
                  </div>
              </Grid>
          </Grid>
          <Grid item xs={12}>
              <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                      <DetailCard {...detailCardProps} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                      <ReferenceNumberCard {...referenceNumberCardProps} />
                  </Grid>
                  <Grid item xs={12}>
                      <Grid container spacing={2}>
                          <Grid item xs={12} md={GeneralNotesCardProps.isTlCategory ? 4 : 8}>
                              <GeneralNotesCard {...GeneralNotesCardProps} />
                          </Grid>
                          {values.isTlCategory && (
                            <Grid item xs={12} md={4}>
                                <CarrierContactDetailCard
                                  {...CarrierContactDetailCardProps}
                                />
                            </Grid>
                          )}
                          {values.isTlCategory && (
                            <Grid item xs={12} md={4}>
                                <DriverDetailCard {...DriverDetailCardProps} />
                            </Grid>
                          )}
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
          {supportingValues?.showChangeStatusDialog && (
            <ChangeStatusDialog
              bolNumber={values.shipment.bolNumber}
              currentStatusType={values.selectedShipmentStatus}
              statusTypeOptions={availableStatusTypeOptions}
              values={values}
              onSave={handleChangeStatusSaveClick}
              onCancel={handleChangeStatusCancelClick}
            />
          )}
          {supportingValues?.showLockDialog && (
            <LockShipmentDialog
              confirmMessage={lockConfirmMessage}
              onSave={handleLockSaveClick}
              onCancel={handleLockCancelClick}
              canSave={values.selectedShipper && values.selectedConsignee}
            />
          )}
      </React.Fragment>
    );
};

const adminConfirmMessage = (bolNumber, selectedShipper, selectedConsignee) =>
  selectedShipper && selectedConsignee ? (
    <p>
        Are you sure you want to lock shipment <b>#{bolNumber}</b>?
    </p>
  ) : (
    <p style={{fontWeight: 600, color: "red"}}>
        This shipment cannot be locked at this time due to the Shipper and/or
        Consignee fields not being completed. Contact management to assist.
    </p>
  );

const agentConfirmMessage = (bolNumber, selectedShipper, selectedConsignee) =>
  selectedShipper && selectedConsignee ? (
    <p>
        You are about to lock shipment <b>#{bolNumber}</b>. Please make sure your
        customer's profile has invoice instructions added and that the POD is
        attached to this shipment if your customer requires PODs for invoicing.
    </p>
  ) : (
    <p style={{fontWeight: 600, color: "red"}}>
        This shipment cannot be locked at this time due to the Shipper and/or
        Consignee fields not being completed. Contact management to assist.
    </p>
  );

export default renderDetailForm;
