import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Business from '@tgf-crm/business';
import ShipmentListingCard from "../../../crm/components/associate/CommissionsCenter/includes/ShipmentListingCard";
import DataSelectorCard from "../../../crm/components/associate/CommissionsCenter/includes/DataSelectorCard";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import LeftNav from '../LeftNav';
import {useDispatch, useSelector} from "react-redux";
import CommissionTypeNames from "../../../hubs/commission/CommissionTypeNames";
import TgfAgencyAssociatesComboBox, {
    TgfAgencyAssociateComboBoxFilters
} from "../../../core/kendo/form/inputs/specialty/TgfAgencyAssociatesComboBox";
import InfoIconTooltip from "../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import {Checkbox} from "@progress/kendo-react-inputs";
import TgfAgencyComboBox from "../../../core/kendo/form/inputs/specialty/TgfAgencyComboBox";

const LoadShipmentsProcessName = 'CommissionCenter.LoadShipments';

const CommissionShipment = () => {
    return {
        id: null,
        bolNumber: null,
        adjustedCarrierCost: null,
        fee: null,
        adjustedCustomerCost: null,
        totalMargin: null,
        loadType: null,
        carrierName: null,
        thirdPartyName: null,
        pickupDate: null,
        customerName: null,
        earnedPayout: null,
        lockedDate: null,
        repPaid: null,
        mcNumber: null,
        companyId: null
    };
};

const computePayPeriodOptions = (monthYearSelectedDate) => {
    const payPeriods = Business.Associate.PayPeriod.buildFromMonth(
        monthYearSelectedDate.getMonth(),
        monthYearSelectedDate.getFullYear());

    const payPeriodOptions = payPeriods.map(pp => {
        const range = pp.toString();
        return {
            title: range,
            value: range,
            startDate: pp.startDate,
            endDate: pp.endDate
        };
    });
    return payPeriodOptions;
};

const AgencyCommissionCenterPage = (props) => {
    const {} = props;

    usePageTitle("TGF: Agency Commissions");

    const computePayPeriodShipment = (associateCommissionRate) => (shipment) => {

        const commissionShipment = CommissionShipment();

        let totalMargin = 0;
        let earnedPayout = 0;

        if (associateCommissionRate) {
            const commissionCalculator = new Business.Associate.CommissionCalculator(associateCommissionRate);
            earnedPayout = commissionCalculator.calculate(shipment.invoice);
            totalMargin = Business.Shipment.ShipmentMath.grossMargin(shipment.invoice);
        }

        const categoryType = freightCategoryTypes.find(fc => fc.id === shipment.freightCategoryId);

        commissionShipment.id = shipment.id;
        commissionShipment.bolNumber = shipment.bolNumber;
        commissionShipment.adjustedCarrierCost = shipment.invoice.adjustedCarrierCost;
        commissionShipment.adjustedCustomerCost = shipment.invoice.adjustedCustomerCost;
        commissionShipment.fee = totalMargin - earnedPayout;
        commissionShipment.totalMargin = totalMargin;
        commissionShipment.loadType = categoryType.name;
        commissionShipment.carrierName = shipment.carrier.name;
        commissionShipment.thirdPartyName = shipment.thirdParty?.name;
        commissionShipment.bolDate = shipment.bolDate;
        commissionShipment.customerName = shipment.customer.name;
        commissionShipment.earnedPayout = earnedPayout;
        commissionShipment.actualDeliveryDate = shipment.invoice.actualDeliveryDate;
        commissionShipment.repPaid = shipment.invoice.associateWasPaid || shipment.invoice.confirmedAssociateWasPaid;
        commissionShipment.mcNumber = shipment.carrier.mcNumber;
        commissionShipment.companyId = shipment.customer.id;

        return commissionShipment;
    };

    const isAdmin = useIsAdministrator();

    const personalDispatch = useDispatch();

    const agencyId = JSON.parse(localStorage.getItem("employeeContext") ?? null)?.agency?.id;

    const freightCategoryTypes = useSelector(state => state.support.freightCategoryTypes);

    const [monthYearSelectedDate, setMonthYearSelectedDate] = React.useState(new Date());
    const [payPeriodOptions, setPayPeriodOptions] = React.useState(computePayPeriodOptions(monthYearSelectedDate));
    const [payPeriod, setPayPeriod] = React.useState(new Date() <= payPeriodOptions[0].endDate ? payPeriodOptions[0] : payPeriodOptions[1]);
    const [shipmentOffset, setShipmentOffset] = React.useState(0);
    const [shipmentLimit, setShipmentLimit] = React.useState(20);
    const [shipmentSort, setShipmentSort] = React.useState([['bolNumber', 'desc']]);
    const [associateCommissionRate, setAssociateCommissionRate] = React.useState(null);
    const [computedShipments, setComputedShipments] = React.useState(null);
    const [totalComputedShipments, setTotalComputedShipments] = React.useState(null);
    const [payPeriodShipmentCount, setPayPeriodShipmentCount] = React.useState(null);
    const [associateFilter, setAssociateFilter] = useState(null);
    const [hideCustomerOwnerShipments, setHideCustomerOwnerShipments] = useState(false);
    const [selectedAgencyId, setSelectedAgencyId] = useState(agencyId);
    const [shipmentTotal, setShipmentTotal] = useState(0);
    const [marginTotal, setMarginTotal] = useState(0);
    const [earnedPayoutTotal, setEarnedPayoutTotal] = useState(0);

    const loadPageDependencies = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadMultiTransPage'));

            const startDate = payPeriod.startDate;

            const payPeriodShipments = await window.shell.gateway.getAgencyAssociatePayPeriodShipments(
                selectedAgencyId,
                {
                    startDate,
                    offset: shipmentOffset,
                    limit: shipmentLimit,
                    sort: shipmentSort
                },
                hideCustomerOwnerShipments,
                associateFilter?.agencyCustomerOwner?.id
            );

            let associateCommissionRate = await window.shell.gateway.getCommissionRate(
                selectedAgencyId,
                startDate
            );

            if (!associateCommissionRate) {
                associateCommissionRate = window.shell.gateway.createCommissionRate();
                associateCommissionRate.associateId = selectedAgencyId;
                associateCommissionRate.commissionDate = startDate;
                associateCommissionRate.commissionType = CommissionTypeNames.Margin;
            }

            setAssociateCommissionRate(associateCommissionRate);

            setPayPeriodShipmentCount(payPeriodShipments.count);

            setComputedShipments(payPeriodShipments.shipments.map(computePayPeriodShipment(associateCommissionRate)));

            setTotalComputedShipments(payPeriodShipments.totalShipments.map(computePayPeriodShipment(associateCommissionRate)));


        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadMultiTransPage'));
        }
    };

    useEffect(() => {
        setShipmentTotal(totalComputedShipments?.length);
        setMarginTotal(totalComputedShipments?.reduce((n, {totalMargin}) => n + totalMargin, 0));
        setEarnedPayoutTotal(totalComputedShipments?.reduce((n, {earnedPayout}) => n + earnedPayout, 0));
    }, [totalComputedShipments]);

    useEffect(() => {
        if (payPeriod && selectedAgencyId) {
            loadPageDependencies();
        } else {
            setAssociateCommissionRate(null);
            setComputedShipments(null);
            setTotalComputedShipments(null);
            setPayPeriodShipmentCount(null);
        }

    }, [
        payPeriod,
        associateFilter,
        selectedAgencyId,
        hideCustomerOwnerShipments,
        shipmentOffset,
        shipmentLimit,
        shipmentSort
    ]);

    //Handles when the user changes pages within the table.
    const handleShipmentPageChange = (e, page) => {
        setShipmentOffset(page * shipmentLimit);

        if (payPeriod && selectedAgencyId) {
            loadPageDependencies();
        }
    };

    // Handles when the user clicks on column headers for sorting.
    const handleShipmentSortChange = (column) => {
        const [[columnName, order]] = shipmentSort;
        const changeOrder = (order === 'asc' && columnName === column) ? 'desc' : 'asc';

        setShipmentSort([[column, changeOrder]]);

        if (payPeriod && selectedAgencyId) {
            loadPageDependencies();
        }
    };
    const handleShipmentLimitChange = (e) => {
        setShipmentOffset(0);
        setShipmentLimit(e.target.value);

        if (payPeriod && selectedAgencyId) {
            loadPageDependencies();
        }
    };

    const handleMonthYearChange = (monthYearValue) => {
        const date = monthYearValue && monthYearValue.isValid() ?
            monthYearValue.toDate() : null;

        const payPeriods = computePayPeriodOptions(date);
        setMonthYearSelectedDate(date);
        setPayPeriodOptions(payPeriods);
        setPayPeriod(new Date() <= payPeriods[0].endDate ? payPeriods[0] : payPeriods[1]);
        setShipmentOffset(0);
    };

    const handlePayPeriodChange = (payPeriodValue) => {
        setPayPeriod(payPeriodValue);
        setShipmentOffset(0);
    };

    const onSelectAssociate = (e, type) => {
        const associate = e?.target?.value ? e?.target?.value : e;
        if (!associate?.id) {
            setAssociateFilter(null);
            setShipmentOffset(0);
            setShipmentLimit(20);
            setShipmentSort([['bolNumber', 'desc']]);
            return;
        }
        switch (type) {
            case "agency customer owner":
                setAssociateFilter({agencyCustomerOwner: associate});
                break;
            case "agency any":
                setAssociateFilter({agencyAny: associate});
                break;
            case "any":
                setAssociateFilter({any: associate});
                break;
            default:
                setAssociateFilter(null);
        }

        setShipmentOffset(0);
        setShipmentLimit(20);
        setShipmentSort([['bolNumber', 'desc']]);
    };

    const handleHideCustomerOwnedShipments = (e) => {
        setHideCustomerOwnerShipments(e.target.value);
        setShipmentOffset(0);
        setShipmentLimit(20);
        setShipmentSort([['bolNumber', 'desc']]);
    };

    const handleSelectionOfAgency = (e) => {
        setSelectedAgencyId(e.target.value);

        setShipmentOffset(0);
        setShipmentLimit(20);
        setShipmentSort([['bolNumber', 'desc']]);
        setAssociateFilter(null);
        setHideCustomerOwnerShipments(false);
    };

    return (
        <FullWidthLayout SideNav={LeftNav} title={'Agency Commissions'}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {isAdmin && <Grid item xs={12} md={2}>
                            <TgfAgencyComboBox
                                label={null}
                                onChange={handleSelectionOfAgency}
                                value={selectedAgencyId}
                                franchiseMode={true}
                                isDisabled={false}
                            />
                        </Grid>
                        }
                        <Grid item xs={12} md={2} style={{display: "flex"}}>
                            <TgfAgencyAssociatesComboBox
                                placeholder={'Filter by customer owners...'}
                                onChange={(e) => onSelectAssociate(e, "agency customer owner")}
                                selectedAgencyAssociate={associateFilter?.agencyCustomerOwner ?? null}
                                activeOnly={true}
                                visibilityPermissionIdFilter={2}
                                actionRoleId={3}
                                roleFilter={TgfAgencyAssociateComboBoxFilters.customerOwners}
                                agencyId={selectedAgencyId}
                                disabled={Boolean(associateFilter?.agencyAny || hideCustomerOwnerShipments)}
                            />
                            <InfoIconTooltip
                                title={
                                    <>
                                        <p>
                                            Selecting a user here will filter the page's information below to just their
                                            customers' shipment information.
                                        </p>
                                        <p>
                                            Inactive users will not display as an option on this dropdown, which is why
                                            working with the back office when terminating a Restricted user is important
                                            as they can make the transition/reassigning of accounts an easy process.
                                        </p>
                                    </>
                                }
                                style={{alignSelf: "center"}}
                            />
                        </Grid>
                        <Grid
                            item xs={12} md={2} style={{display: "flex", alignItems: "center", alignSelf: "center"}}
                        >
                            <Checkbox
                                value={hideCustomerOwnerShipments}
                                id={"hideCustomerOwnerShipments"}
                                name={"hideCustomerOwnerShipments"}
                                label={"Hide customer owner shipments"}
                                onChange={handleHideCustomerOwnedShipments}
                                disabled={associateFilter?.agencyCustomerOwner || associateFilter?.agencyAny}
                            />
                            <InfoIconTooltip
                                title={
                                    <>
                                        <p>
                                            Checking this box will hide all information below involving customers who
                                            have a Customer Owner assigned to them - even those involving an inactive
                                            Restricted user.
                                        </p>
                                        <p>
                                            Working with the back office when terminating a Restricted user is important
                                            as they can make the transition/reassigning of accounts an easy process.
                                        </p>
                                    </>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <DataSelectorCard
                        onMonthYearChange={handleMonthYearChange}
                        onPayPeriodChange={handlePayPeriodChange}
                        onAssociateChange={null}
                        monthYearSelectedDate={monthYearSelectedDate}
                        payPeriodOptions={payPeriodOptions}
                        payPeriod={payPeriod}
                    />
                </Grid>
                <Grid container item xs={12} md={4} spacing={3}>
                    <Grid item xs={12}>
                        <div style={{display: "flex", justifyContent: "center", padding: "10px", marginTop: "2px"}}>
                            <label style={{fontSize: "larger", fontWeight: "bold"}}>
                                Information Totals
                            </label>
                            <InfoIconTooltip
                                title={
                                    `This information will change depending on the filter and checkbox above being used. 
                                    Otherwise, it will provide information for your entire agency.`
                                }
                                style={{alignSelf: "center"}}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{padding: "10px", backgroundColor: "white"}}>
                            <label style={{fontSize: "larger", fontWeight: "bold", color: "green"}}>Shipment
                                Total: </label>
                            <label style={{fontSize: "larger", fontWeight: "bold"}}>{shipmentTotal}</label>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{padding: "10px", backgroundColor: "white"}}>
                            <label style={{fontSize: "larger", fontWeight: "bold", color: "green"}}>Margin
                                Total: </label>
                            <label
                                style={{
                                    fontSize: "larger",
                                    fontWeight: "bold",
                                    color: marginTotal >= 0 ? "" : "red"
                                }}
                            >{marginTotal?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            })}</label>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{padding: "10px", backgroundColor: "white"}}>
                            <label style={{fontSize: "larger", fontWeight: "bold", color: "green"}}>Earned Payout
                                Total: </label>
                            <label
                                style={{
                                    fontSize: "larger",
                                    fontWeight: "bold",
                                    color: earnedPayoutTotal >= 0 ? "" : "red"
                                }}
                            >{earnedPayoutTotal?.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            })}</label>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ShipmentListingCard
                        associateCommissionRate={associateCommissionRate}
                        freightCategoryTypes={freightCategoryTypes}
                        offset={shipmentOffset}
                        limit={shipmentLimit}
                        sort={shipmentSort}
                        shipments={computedShipments}
                        shipmentCount={payPeriodShipmentCount}
                        onPageChange={handleShipmentPageChange}
                        onLimitChange={handleShipmentLimitChange}
                        onSortChange={handleShipmentSortChange}
                        onEditShipmentClick={null}
                        agencyMode={true}
                    />
                </Grid>
            </Grid>
        </FullWidthLayout>
    );
};

export default AgencyCommissionCenterPage;

