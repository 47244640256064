import React, {useState} from 'react';
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import {Assignment} from "@material-ui/icons";
import FormFactor from "../../../../core/FormFactor/FormFactor";
import AppDialog from "../../../../core/components/AppDialog";
import {Grid} from "@material-ui/core";
import AppDropdown from "../../../../core/components/dropdowns/AppDropdown";
import AppMuiInput from "../../../../core/components/inputs/AppInput";
import AppValidationFailure from "../../../../core/components/AppValidationFailure";
import * as Yup from "yup";
import sendSnackbarMessage from "../../../../hubs/sys/actions/sendSnackbarMessage";
import shipmentTrackingPhases from "../../../../hubs/support/selectors/shipmentTrackingPhases";

const AddNewNoteIcon = React.forwardRef((props, ref) => {

    const {
        bolNumber,
        onNoteAdded
    } = props;

    const state = window.shell.stateStore.getState();
    const associateId = state.persona.associate.id;
    const trackingPhases = shipmentTrackingPhases(state);

    const handleNewNoteOpen = (e) => setIsAddingNote(true);
    const handleNewNoteClose = (e) => setIsAddingNote(false);

    const handleNewNoteSubmit = async (values, formFactor) => {
        const newNote = {
            bolNumber: bolNumber,
            associateId: associateId,
            note: values.note,
            loadTrackingPhaseId: values.selectedPhase.id,
        };
        await window.shell.actions.shipment.modification.saveShipmentTrackingNote(newNote).then(() => {
            setIsAddingNote(false);
            onNoteAdded();
            sendSnackbarMessage({content: "Tracking note saved."});
        });
    };

    const [isAddingNote, setIsAddingNote] = useState(false);

    const newNoteInitialValues = {
        trackingPhases,
        selectedPhase: trackingPhases[0],
        note: '',
        onClose: handleNewNoteClose
    };

    const NewNoteSchema = Yup.object().shape({
        note: Yup.string().trim("Note is required.").required("Note is required."),
    });

    const renderNewNoteForm = ({ values, errors, isValid, isDirty, setFieldValue, submitForm }) => {
        const newNoteActions = [
            {
                title: 'Cancel',
                action: values.onClose
            },
            {
                title: 'Save',
                action: submitForm,
                disabled: !isValid || !isDirty
            }
        ];

        const handleTrackingPhaseChange = (e, value) => {
            const trackingPhase = value;

            if (trackingPhase) {
                setFieldValue('trackingPhase', trackingPhase.id);
                setFieldValue('selectedPhase', trackingPhase);
            }
            else {
                setFieldValue('trackingPhase', null);
                setFieldValue('selectedPhase', null);
            }
        };

        const handleNoteChange = (e) =>
            setFieldValue('note', e.target.value);

        return (
            <AppDialog
                title="New Load Tracking Note"
                open={true}
                width='sm'
                onClose={values.onClose}
                actionButtons={newNoteActions}
            >
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <AppDropdown
                            field={'name'}
                            fieldIndex={'id'}
                            label="Phase"
                            tabIndex={1}
                            options={values.trackingPhases}
                            value={values.selectedPhase}
                            onChange={handleTrackingPhaseChange}
                        />
                    </Grid>
                    <Grid item>
                        <AppMuiInput
                            autoFocus
                            id="companyNote"
                            label="Note"
                            tabIndex={2}
                            rows="10"
                            multiline={true}
                            inputProps={{
                                maxLength: 1000
                            }}
                            value={values.note}
                            onChange={handleNoteChange} />
                        <AppValidationFailure message={errors.note} />
                    </Grid>
                </Grid>
            </AppDialog>
        );
    };

    return <div className={"new-note-icon-container"}>
        <AppIconButton onClick={handleNewNoteOpen} Icon={Assignment}/>
        {isAddingNote && <FormFactor
            initialValues={newNoteInitialValues}
            schema={NewNoteSchema}
            onSubmit={handleNewNoteSubmit}>
            {renderNewNoteForm}
        </FormFactor>}
    </div>
});

export default AddNewNoteIcon;
