import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Divider } from '@material-ui/core';
import { Publish } from '@material-ui/icons';

import ComponentBuilder from '../../../core/ComponentBuilder';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import ShipmentRecordNav from "../ShipmentRecordNav";
import ShipmentDocumentTypeNames from '../../../hubs/shipment/ShipmentDocumentTypeNames';

import AppDocumentDrawer from '../../../core/components/Documents/AppDocumentDrawer';
import AppDocumentRemoveFile from '../../../core/components/Documents/AppDocumentRemoveFile';
import AppDocumentFilters from '../../../core/components/Documents/AppDocumentFilters';
import AppComposeDocumentTypeColors from '../../../core/components/Documents/AppComposeDocumentTypeColors';

import AppButton from '../../../core/components/AppButton';
import AppDocumentCardGroup from '../../../core/components/Documents/AppDocumentCardGroup';
import "./DocumentsPage.scss";
import printJS from 'print-js'
import {ShipmentTitle} from "../ShipmentTitle";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const LoadProcessName = 'Shipment.DocumentsPage.Load';
const PublishProcessName = 'Shipment.DocumentsPage.Publish';
const DeleteProcessName = 'Shipment.DocumentsPage.Delete';
const printProcessName = 'Shipment.DocumentsPage.Print';

const useStyles = makeStyles(theme => ({
  noDocs: {
    marginTop: 50,
    textAlign: 'center'
  }
}));

const createDocColors = (isAdmin) => ({
  [ShipmentDocumentTypeNames.CarrierInvoice]: {
    color: 'tgfBlack',
    isVisible: isAdmin
  },
  [ShipmentDocumentTypeNames.CarrierInvoicePOD]: {
    color: 'error',
    isVisible: isAdmin
  },
  [ShipmentDocumentTypeNames.CustomerInvoice]: {
    color: '#fc0fc0',
    isVisible: isAdmin
  },
  [ShipmentDocumentTypeNames.POD]: {
    color: 'info',
    isVisible: true
  },
  [ShipmentDocumentTypeNames.SignedRateCon]: {
    color: 'success',
    isVisible: true
  },
  [ShipmentDocumentTypeNames.ScaleTicket]: {
    color: 'warning',
    isVisible: true
  },
  [ShipmentDocumentTypeNames.LumperReceipt]: {
    color: 'warning',
    isVisible: true
  }
  ,[ShipmentDocumentTypeNames.InterchangeSlip]: {
    color: 'warning',
    isVisible: true
  }
  ,[ShipmentDocumentTypeNames.CustomerShipmentAgreement]: {
    color: 'warning',
    isVisible: true
  }
  ,[ShipmentDocumentTypeNames.TallySheet]: {
    color: 'warning',
    isVisible: true
  }
  ,[ShipmentDocumentTypeNames.FalveyInsurance]: {
    color: 'warning',
    isVisible: true
  }
  ,[ShipmentDocumentTypeNames.Other]: {
    color: 'warning',
    isVisible: true
  },

});

const DocumentsPage = (props) => {
  const classes = useStyles();
  const [openNewDocument, setOpenNewDocument] = React.useState(false);
  const [removeFileOpen, setRemoveFileOpen] = React.useState(false);
  const [cardDocument, setCardDocument] = React.useState(null);
  const [documentId, setDocumentId] = React.useState(null);
  const [documentFilter, setDocumentFilter] = React.useState(0); //Defaults to All (0)
  const [animateIn, setAnimateIn] = React.useState(true);
  const {
    dispose,
    isAdmin,
    load,
    match,
    shipmentDocuments,
    deleteShipmentDocument,
    publishShipmentDocument,
    shipmentDocumentTypes,
    sendSnackbarMessage,
    printDocument
  } = props;

  const bolNumber = parseInt(match.params.id);
  usePageTitle(`Ship ${bolNumber}`);
  const docColors = createDocColors(isAdmin);
  const securedCategoryTypes = shipmentDocumentTypes
    .filter(docType => docColors[docType.name].isVisible);
  const coloredDocumentTypes = AppComposeDocumentTypeColors(shipmentDocumentTypes, docColors);
  const filterColoredDocumentTypes = AppComposeDocumentTypeColors(securedCategoryTypes, docColors);

  React.useEffect(() => {
    load(bolNumber);
  }, [bolNumber, load, dispose]);

  const handleCloseNewDocument = () => setOpenNewDocument(false);
  const handleNewDocument = () => setOpenNewDocument(true);
  const handleRemoveFileClose = () => setRemoveFileOpen(false);

  const handlePublishClick = (shipmentDocument) => {
    publishShipmentDocument(bolNumber, shipmentDocument)
      .then(() => {
        setOpenNewDocument(false);
        sendSnackbarMessage({ content: 'Document uploaded.'});
        load(bolNumber);
      });
  };

  const handleOpenDownloadFileClick = (document, shouldOpen) => {
    const a = window.document.createElement('a');

    shouldOpen ? a.target = '_blank' : a.download = document.filename;

    a.href = `/file/shipment/${bolNumber}/document/${document.voidId ? document.voidId : document.id}`;
    a.click();
    a.remove();
  };

  const handlePrintFileClick = (document) => {
    if(document.mimeType.includes("pdf")) {
      printDocument(`/file/shipment/${bolNumber}/document/${document.id}`, 'pdf')
    } else if (document.mimeType.includes("image")) {
      printDocument(`/file/shipment/${bolNumber}/document/${document.id}`, 'image')
    }
  };

  const handleRemoveFileClick = async  (document) => {
    setCardDocument(document);
    await setDocumentId(document.voidId ? document.voidId : document.id);
    setRemoveFileOpen(true);
  }

  const buildData = (filterItem) => {
    const cardShipmentDocuments = shipmentDocuments
      .filter(item => filterItem === 0 ? item : filterItem === item.typeId)
      .map(document => ({
        ...document,
        docType: coloredDocumentTypes.find(docType => docType.id === document.typeId),
        path: `/shipment/${document.bolNumber}/document/${document.id}`,
      }));
    return cardShipmentDocuments;
  };

  const handleRemoveFileConfirmClick = () => {
    setRemoveFileOpen(false);
    deleteShipmentDocument(bolNumber, documentId)
      .then(() => {
        sendSnackbarMessage({content: 'Document deleted.' });
        load(bolNumber);
      });
  };

  const NoDocuments = () => <Typography variant="h4" className={classes.noDocs}>No documents available.</Typography>;

  const handleFilter = (filterItem) => {
    setAnimateIn(false);
    setDocumentFilter(filterItem);
    setTimeout(() => {
      setAnimateIn(true);
    }, 300);
  };

  const filterDocuments = buildData(documentFilter);
  const options = [
    {
      label: 'Open',
      click: (document) => handleOpenDownloadFileClick(document, true),
      isVisible: true
    },
    {
      label: 'Print',
      click: (document) => handlePrintFileClick(document, true),
      isVisible: true
    },
    {
      label: 'Download',
      click: (document) => handleOpenDownloadFileClick(document, false),
      isVisible: true
    },
    {
      label: 'Remove',
      click: (document) => handleRemoveFileClick(document),
      isVisible: isAdmin
    }
  ];
  return (
    <FullWidthLayout SideNav={ShipmentRecordNav} className='shipments-styles'>
      <Grid container spacing={2}>
        <Grid item xs={6}><ShipmentTitle bolNumber={bolNumber} title={`Shipments - Documents - ${bolNumber ? bolNumber : 'New'}`}/></Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          <AppButton onClick={handleNewDocument}>
            <Publish /> Upload
          </AppButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <AppDocumentFilters
            documentTypes={coloredDocumentTypes}
            onFilter={handleFilter}
            filterItem={documentFilter}
          />
        </Grid>
        <Grid item xs={12}>
          {
            filterDocuments.length > 0 ?
              <AppDocumentCardGroup
                documents={filterDocuments}
                options={options}
                animateIn={animateIn}
                bolNumber={bolNumber}
              />
              : <NoDocuments />
          }
        </Grid>
      </Grid>
      <AppDocumentDrawer
        anchor="right"
        documentTypes={filterColoredDocumentTypes}
        open={openNewDocument}
        onClose={handleCloseNewDocument}
        onPublish={handlePublishClick}
      />
      {cardDocument && <AppDocumentRemoveFile
        document={cardDocument}
        open={removeFileOpen}
        onClose={handleRemoveFileClose}
        onConfirmClick={handleRemoveFileConfirmClick}
      />
      }
    </FullWidthLayout>
  )
}
export default ComponentBuilder
  .wrap(DocumentsPage)
  .stateToProps((state, ownProps) => (
    {
      shipmentDocuments: state.shipment.modification.shipmentDocuments,
      shipmentDocumentTypes: state.support.shipmentDocumentTypes,
      isAdmin: useIsAdministrator()
    }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(bolNumber) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));

        const actionResults = await Promise.all([
          shell.actions.shipment.modification.loadShipment(bolNumber),
          shell.actions.shipment.modification.loadShipmentDocuments(bolNumber)
        ]);

        actionResults.forEach(dispatch);

        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.shipment.modification.dispose());
      },
      async deleteShipmentDocument(bolNumber, documentId) {
        dispatch(shell.actions.sys.processStart(DeleteProcessName));
        dispatch(await shell.actions.shipment.modification.deleteShipmentDocument(bolNumber, documentId));
        dispatch(shell.actions.sys.processComplete(DeleteProcessName));
      },
      async publishShipmentDocument(bolNumber, shipmentDocument) {
        dispatch(shell.actions.sys.processStart(PublishProcessName));
        dispatch(await shell.actions.shipment.modification.publishShipmentDocument(bolNumber, shipmentDocument));
        dispatch(shell.actions.sys.processComplete(PublishProcessName));
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      },
      printDocument(path, mimeType) {
        dispatch(shell.actions.sys.processStart(printProcessName));
        printJS(path, mimeType);
        // faux loading time
        setTimeout(() => {
          dispatch(shell.actions.sys.processComplete(printProcessName));
        },1000)
      }
    }
  })
  .build();
