import {ComboBox} from "@progress/kendo-react-dropdowns";
import {Label} from "@progress/kendo-react-labels";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {filterBy} from "@progress/kendo-data-query";

const TgfAgencyComboBox = (props) => {
    const {
        id,
        label,
        franchiseMode = null,
        isDisabled = null,
        className = null,
        placeholder = "Search Agencies..."
    } = props;

    const personalDispatch = useDispatch();
    const [agencyData, setAgencyData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [value, setValue] = useState(null);

    const loadAllAgencies = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('getAgencies'));

            let agencies = await window.shell.gateway.getAllAgencies(franchiseMode, isDisabled);
            setAgencyData(agencies);

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('getAgencies'));
        }
    };

    useEffect(() => {
        loadAllAgencies();
    }, []);

    useEffect(() => {
        setValue(agencyData.find(x => x.id === props.value) ?? null);
    }, [agencyData, props.value]);

    const filterData = filter => {
        const data = agencyData.slice();
        return filterBy(data, filter);
    };
    const filterChange = event => {
        setFilteredData(filterData(event.filter));
    };

    useEffect(() => {
        setFilteredData(agencyData);
    }, [agencyData]);

    const handleChange = (e) => {
        setValue(e.target.value);
        // outside of this component, we only input/output associateId.
        props.onChange({ target: { value: e.target.value?.id ?? null } });
    };

    return (
        <div>
            {label && <Label editorId={id}>{label}</Label>}
            <ComboBox
                {...props}
                className={"tgf-kendo-combobox" + (className ? ` ${className}` : "")}
                data={filteredData}
                dataItemKey={"id"}
                textField="display"
                filterable={true}
                onFilterChange={filterChange}
                onChange={handleChange}
                value={value}
                placeholder={placeholder}
                label={null}
            />
        </div>
    );
};

export default TgfAgencyComboBox;
