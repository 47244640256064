import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const loadCompanyAgency = async (shell, companyId) => {

  const agency = await shell.gateway.getCompanyAgency(companyId);

  return {
    type: ActionTypes.Agency.CompanyAgencyLoaded,
    agency
  };

};

export default ActionBuilder
  .for(loadCompanyAgency)
  .build();