import * as Redux from 'redux';

import qaReview from "./qaReview";
import qaFailed from "./qaFailed";
import autoProcessed from "./autoProcessed";
import closed from "./closed";
import doNotPayCarrier from "./doNotPayCarrier";
import doubleBrokered from "./doubleBrokered";

export default Redux.combineReducers({
  qaReview,
  qaFailed,
  autoProcessed,
  closed,
  doNotPayCarrier,
  doubleBrokered
});
