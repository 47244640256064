import { Grid, Tooltip } from '@material-ui/core';
import { AttachMoney, Block, CreditCard, Edit, FileCopyOutlined, PlayArrow, Save, Undo, Whatshot } from '@material-ui/icons';
import { Checkbox } from "@progress/kendo-react-inputs";
import Business from '@tgf-crm/business';
import React from 'react';
import AppAlertStatic from '../../../core/components/AppAlertStatic';
import AppButton from '../../../core/components/AppButton';
import AppTooltip from '../../../core/components/AppTooltip/AppTooltip';
import AppValidationFailure from '../../../core/components/AppValidationFailure';
import InfoIconTooltip from "../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import AppCard from '../../../core/components/cards/AppCard';
import When from '../../../core/components/condtionals/When';
import AppAutoComplete from '../../../core/components/dropdowns/AppAutoComplete';
import AppDropdown from '../../../core/components/dropdowns/AppDropdown';
import AppDatePicker from '../../../core/components/inputs/AppDatePicker';
import AppInput from '../../../core/components/inputs/AppInput/AppMuiInput';
import AppNumericInput from '../../../core/components/inputs/AppNumericInput/AppMuiNumericInput';
import AppPhoneInput from "../../../core/components/inputs/AppPhoneInput/AppMuiPhoneInput";
import AppSwitch from '../../../core/components/inputs/AppSwitch';
import CheckNumberRegExp from "../../../core/text/CheckNumberRegExp";
import CrmTypedAddressBookListingComboBox from '../../../crm/components/CrmTypedAddressBookListingComboBox';
import AgentTooltipComponent from "../../../crm/components/agent-tooltip/AgentTooltipComponent";
import isNewCompany from '../../../hubs/addressBook/actions/modification/isNewCompany';
import CustomerDisableHistory from "./CustomerDisableHistory";
import RequestCreditIncreaseDialog from './RequestCreditIncreaseDialog';

const CurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

const renderDetailsForm = ({
  values,
  supportingValues,
  errors,
  isValid,
  isDirty,
  setFieldValue,
  setSupportingValue,
  resetForm
}) => {


  const handlePhoneChange = (key) => (e) => {
    const value = e.target.value.match(CheckNumberRegExp) ?
      e.target.value : null;
    setFieldValue(key, value);
  };
  const handleCreditLimitChange = (e) =>
    setFieldValue('creditLimit', e.target.value ? parseFloat(e.target.value) : null);

  const handleChange = (key) => (e) =>
    setFieldValue(key, e.target.value);

  const handleCategoryTypeChange = (e, value) => {
    const categoryType = value;

    if (categoryType) {
      setFieldValue('categoryTypeId', categoryType.id);
      setFieldValue('selectedCategoryType', categoryType);
    } else {
      setFieldValue('categoryTypeId', null);
      setFieldValue('selectedCategoryType', null);
      setFieldValue('selectedCategoryType', null);
    }
  };

  const handleStateProvinceChange = (e, value) => {
    const stateProvince = value;
    if (stateProvince) {
      setFieldValue('stateProvince', stateProvince.abbreviation);
      setFieldValue('selectedStateProvince', stateProvince);
    } else {
      setFieldValue('stateProvince', null);
      setFieldValue('selectedStateProvince', null);
    }
  };

  const handleAssociateChange = (e, value) => {
    const associate = value;
    if (associate) {
      setFieldValue('associateId', associate.id);
      setFieldValue('selectedAssociate', associate);
    } else {
      setFieldValue('associateId', null);
      setFieldValue('selectedAssociate', null);
    }
  };

  const handleCustomerOwnerChange = (e, value) => {
    const associate = value;
    if (associate) {
      setFieldValue('customerOwnerId', associate.id);
      setFieldValue('customerOwner', associate);
    } else {
      setFieldValue('customerOwnerId', null);
      setFieldValue('customerOwner', null);
    }
  };

  const handleAffiliateChange = (value) => {
    const affiliate = value;

    if (affiliate) {
      setFieldValue('affiliateId', affiliate.companyId);
      setFieldValue('selectedAffiliate', affiliate);
    } else {
      setFieldValue('affiliateId', null);
      setFieldValue('selectedAffiliate', null);
    }
  };

  const handleSwitchChange = (key) => (e) => {
    setFieldValue(key, e.target.checked);
  };

  const handleDateFieldChange = (date) => setFieldValue("creditAppReceivedDate", date);

  const handleResetClick = () => {
    resetForm();
  };

  const handleDuplicateShipperConsigneeClick = () => {
    values.setDuplicateShipperConsigneeModalOpen(true);
  };

  const handleRciCancelClick = () =>
    setSupportingValue('showRequestCreditIncreaseDialog', false);

  const handleRciCompleteClick = () => {
    values.handleAutoCreditIncreaseComplete();
  };

  const handleAutofillFieldsChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value ?? "";
    setFieldValue(`companyAutofillFields.${name}`, value);
  };

  const firstShipmentDateText = values.firstShipmentDate ?
    values.firstShipmentDate.toMoment().format('L') :
    'N/A';

  const creditRemaining = values.creditRemaining || 0;
  const creditRemainingText = CurrencyFormatter.format(creditRemaining);

  const canChangeToLead = !isDirty &&
    !values.isAdmin &&
    values.selectedCategoryType &&
    values.selectedCategoryType.name === "Lead";

  const manualDisableRecord = values.disabledCompanyHistory
    .find(cdh => cdh.reasonId === Business.Company.CompanyDisablementReasonCodes.Manual);

  const isCustomer = values.selectedCategoryType &&
    values.selectedCategoryType.name === "Customer";

  const canDisableCustomer = (!manualDisableRecord ||
    manualDisableRecord.classificationCode === 'enabled') &&
    isCustomer;

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <AppButton
                startIcon={<Save />}
                type={'submit'}
                disabled={!((isValid && isDirty) || values.nonFormfactorIsDirty)}>
                Save
              </AppButton>
            </Grid>
            <Grid item>
              <AppButton
                startIcon={<Undo />}
                disabled={!(isDirty)}
                color="warning"
                onClick={handleResetClick}
              >
                Reset
              </AppButton>
            </Grid>
            {values?.categoryTypeId === 2 &&
              <Grid item>
                <AppButton
                  startIcon={<FileCopyOutlined />}
                  disabled={(isDirty)}
                  onClick={handleDuplicateShipperConsigneeClick}
                >
                  Duplicate Under Another Customer
                </AppButton>
              </Grid>
            }
            {
              !values.isNew && values.isAdmin &&
              <Grid item>
                <AppButton
                  startIcon={<Edit />}
                  onClick={values.openChangeCategory}
                >
                  Change Co. Type
                </AppButton>
              </Grid>
            }
            {
              canChangeToLead &&
              <Grid item>
                <AppButton
                  startIcon={<AttachMoney />}
                  onClick={values.convertToCustomer}
                >
                  Convert to Customer
                </AppButton>
              </Grid>
            }
            {
              values.isAdmin && isCustomer && canDisableCustomer &&
              <Grid item>
                <AppButton
                  startIcon={<Block />}
                  color={'danger'}
                  onClick={values.openDisableCustomer}>
                  Disable
                </AppButton>
              </Grid>
            }
            {
              values.isAdmin && isCustomer && !canDisableCustomer &&
              <Grid item>
                <AppButton
                  startIcon={<PlayArrow />}
                  onClick={values.handleEnableCustomer}>
                  Enable
                </AppButton>
              </Grid>
            }
            {
              !values.isNew && isCustomer &&
              <Grid item>
                <AppButton
                  startIcon={<CreditCard />}
                  onClick={() => setSupportingValue('showRequestCreditIncreaseDialog', true)}>
                  {
                    values.isAdmin ?
                      'Auto-Credit Increase Check' :
                      'Request Credit Increase'
                  }
                </AppButton>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <AppInput
                id="name"
                name="name"
                label="Name"
                tabIndex={1}
                placeholder="Enter company name..."
                minLength="1"
                inputProps={{
                  maxLength: 50
                }}
                value={values.name || ''}
                onChange={handleChange('name')}
              />
              <AppValidationFailure
                message={errors.name}
              />
            </Grid>
            <Grid item xs={12} md={2} style={{ alignSelf: "end" }}>
              <AppSwitch
                checked={values.isInactive || false}
                label="Address Inactive"
                onChange={handleSwitchChange('isInactive')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <When condition={values.isAdmin}>
                {() => <>
                  <AgentTooltipComponent content={''} agentId={values.selectedAssociate?.id} width={"auto"}>
                    <AppAutoComplete
                      label="Associate"
                      tabIndex={20}
                      field={'fullName'}
                      fieldIndex={'id'}
                      options={values.associates}
                      value={values.selectedAssociate}
                      onChange={handleAssociateChange}
                    />
                    <AppValidationFailure
                      message={errors.associate}
                    />
                  </AgentTooltipComponent>
                </>
                }
              </When>
              <When condition={!values.isAdmin}>
                {() =>
                  <AgentTooltipComponent content={''} agentId={values.selectedAssociate.id} width={"auto"}>
                    <AppInput
                      label="Associate"
                      disabled
                      value={`${values.selectedAssociate.firstName} ${values.selectedAssociate.lastName}`}
                    />
                  </AgentTooltipComponent>
                }
              </When>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <AppTooltip
                    title={!values.isAdmin && !isNewCompany(values.id) ? "Company Type can only be changed by management." : ""}
                  >
                    <AppDropdown
                      label="Company Type"
                      field={'name'}
                      disabled={!isNewCompany(values.id)}
                      fieldIndex={'id'}
                      options={values.categoryTypes}
                      value={values.selectedCategoryType}
                      onChange={handleCategoryTypeChange}
                    />
                  </AppTooltip>
                  <AppValidationFailure
                    message={errors.selectedCategoryType}
                  />
                </Grid>
                {
                  values.selectedCategoryType &&
                  values.selectedCategoryType.requiresAffiliate &&
                  <Grid item xs={12}>
                    <label>Affiliate</label>
                    <CrmTypedAddressBookListingComboBox
                      tabIndex={3}
                      types={[CrmTypedAddressBookListingComboBox.Customer]}
                      value={values.selectedAffiliate || null}
                      onChange={handleAffiliateChange}
                      popupIcon={' '}
                    />
                    <AppValidationFailure
                      message={errors.selectedAffiliate}
                    />
                  </Grid>
                }
                <Grid item xs={12}>
                  <Grid container spacing={1} direction="row">
                    <Grid item xs={12} md={6}>
                      <AppPhoneInput
                        label="Business Phone"
                        id="companyBusinessPhone"
                        name="businessPhone"
                        tabIndex={4}
                        value={values.businessPhone || ''}
                        onChange={handlePhoneChange('businessPhone')}
                      />
                      <AppValidationFailure
                        message={errors.businessPhone}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AppPhoneInput
                        label="Fax Phone"
                        id="companyFaxPhone"
                        type="tel"
                        tabIndex={5}
                        value={values.faxPhone || ''}
                        onChange={handlePhoneChange('faxPhone')}
                      />
                      <AppValidationFailure
                        message={errors.faxPhone}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AppInput
                        label="Shipping Hours"
                        id="companyShippingHours"
                        tabIndex={6}
                        inputProps={{
                          maxLength: 30
                        }}
                        value={values.shippingHours || ''}
                        onChange={handleChange('shippingHours')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AppInput
                        id="companyReceivingHours"
                        label="Receiving Hours"
                        tabIndex={7}
                        inputProps={{
                          maxLength: 30
                        }}
                        value={values.receivingHours || ''}
                        onChange={handleChange('receivingHours')}
                      />
                    </Grid>
                    {(values?.categoryTypeId === 2 || values?.categoryTypeId === 1) &&
                      <>
                        <Grid item xs={12} md={6}>
                          <Grid container alignItems={"center"}>
                            <Grid item>
                              <Checkbox
                                value={values.companyAutofillFields?.autofillShippingHours}
                                id={"autofillShippingHours"}
                                name={"autofillShippingHours"}
                                label={"Use for Pickup Time on shipments"}
                                onChange={handleAutofillFieldsChange}
                              />
                            </Grid>
                            <Grid item>
                              <InfoIconTooltip
                                title={"Upon selecting this address as the Shipper on a shipment, the Pickup Time field will be completed with this information (if checked). Remember that a shipment's Pickup Time field has a limit of 20 characters."}
                                style={{ whiteSpace: 'pre-line', display: "flex", alignItems: "center" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container alignItems={"center"}>
                            <Grid item>
                              <Checkbox
                                value={values.companyAutofillFields?.autofillReceivingHours}
                                id={"autofillReceivingHours"}
                                name={"autofillReceivingHours"}
                                label={"Use for Delivery Time on shipments"}
                                onChange={handleAutofillFieldsChange}
                              />
                            </Grid>
                            <Grid item>
                              <InfoIconTooltip
                                title={"Upon selecting this address as the Receiver on a shipment, the Delivery Time field will be completed with this information (if checked). Remember that a shipment's Delivery Time field has a limit of 20 characters."}
                                style={{ whiteSpace: 'pre-line', display: "flex", alignItems: "center" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    }
                    <Grid item xs={12}>
                      <Grid container direction="column">
                        {/*<Grid item>*/}
                        {/*    {*/}
                        {/*        // When the category requires credit (customer type)*/}
                        {/*        // allow the user to select whether they need a POD for*/}
                        {/*        // every shipment in order to invoice.*/}
                        {/*        // https://www.pivotaltracker.com/n/projects/2437768/stories/171657859*/}
                        {/*      values.selectedCategoryType &&*/}
                        {/*      values.selectedCategoryType.requiresCredit &&*/}
                        {/*      <AppSwitch*/}
                        {/*        label="PODs Required for Invoicing"*/}
                        {/*        checked={values.isHardCopyPodRequired || false}*/}
                        {/*        onChange={handleSwitchChange('isHardCopyPodRequired')}*/}
                        {/*      />*/}
                        {/*    }*/}
                        {/*</Grid>*/}
                        {values.companyContacts.every(c => !c.isPrimary) && <Grid item>
                          <AppAlertStatic color="warning">At least one contact must be marked as
                            Primary.</AppAlertStatic>
                        </Grid>}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      values.isInactive &&
                      <AppAlertStatic color="error" icon={false}>
                        This address is inactive and will not
                        appear as an option when building shipments.
                      </AppAlertStatic>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <AppInput
                    id="companyAddress1"
                    name="companyAddress1"
                    label="Address Line 1"
                    tabIndex={8}
                    inputProps={{
                      maxLength: 50
                    }}
                    value={values.address1 || ''}
                    onChange={handleChange('address1')}
                  />
                  <AppValidationFailure
                    message={errors.address1}
                  />
                </Grid>
                <Grid item>
                  <AppInput
                    id="companyAddress2"
                    name="companyAddress2"
                    label="Address Line 2"
                    tabIndex={9}
                    inputProps={{
                      maxLength: 100
                    }}
                    value={values.address2 || ''}
                    onChange={handleChange('address2')}
                  />
                </Grid>
                <Grid item>
                  <AppInput
                    id="companyCity"
                    name="companyCity"
                    label="City"
                    tabIndex={10}
                    inputProps={{
                      maxLength: 30
                    }}
                    value={values.city || ''}
                    onChange={handleChange('city')}
                  />
                  <AppValidationFailure
                    message={errors.city}
                  />
                </Grid>
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item sm={12} md={6}>
                      <AppDropdown
                        label="State/Province"
                        field={'name'}
                        fieldIndex={'abbreviation'}
                        closeIcon=' '
                        disableClearable
                        options={values.stateProvinces}
                        value={values.selectedStateProvince}
                        onChange={handleStateProvinceChange}
                      />
                      <AppValidationFailure
                        message={errors.selectedStateProvince}
                      />
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <AppInput
                        id="companyPostalCode"
                        name="companyPostalCode"
                        label="Zip/Postal Code"
                        tabIndex={12}
                        inputProps={{
                          minLength: "5",
                          maxLength: "7"
                        }}
                        value={values.postalCode || ''}
                        onChange={handleChange('postalCode')}
                      />
                      <AppValidationFailure
                        message={errors.postalCode}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <When condition={values.selectedCategoryType && values.selectedCategoryType.requiresCredit}>
            {() =>
              <AppCard>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <label htmlFor="companyCreditLimit">Credit Limit</label>
                    <AppNumericInput
                      id="companyCreditLimit"
                      name="companyCreditLimit"
                      tabIndex={13}
                      disabled={!values.isAdmin}
                      decimalScale={2}
                      value={values.creditLimit}
                      onChange={handleCreditLimitChange}
                    />
                    <AppValidationFailure
                      message={errors.creditLimit}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AppNumericInput
                      disabled
                      label="Credit Remaining"
                      value={creditRemainingText}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AppInput
                      disabled
                      label="First Ship. Date"
                      value={firstShipmentDateText}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AppDatePicker
                      id="creditAppReceivedDate"
                      name="creditAppReceivedDate"
                      label="App Rec. Date"
                      tabIndex={16}
                      value={values.creditAppReceivedDate}
                      disabled={!values.isAdmin}
                      onChange={handleDateFieldChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{ marginTop: 20 }}>
                      <AppSwitch
                        label="Credit App. on File"
                        checked={values.hasCreditAppOnFile}
                        disabled={!values.isAdmin}
                        onChange={handleSwitchChange('hasCreditAppOnFile')}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label htmlFor="paymentTerms">Payment Terms</label>
                    <AppNumericInput
                      id="paymentTerms"
                      name="paymentTerms"
                      disabled={!values.isAdmin}
                      value={values.paymentTerms}
                      onChange={handleChange('paymentTerms')}
                    />
                    <AppValidationFailure message={errors.paymentTerms} />
                  </Grid>
                  <Grid item xs={12}>
                    {
                      !values.hasCreditAppOnFile &&
                      <AppAlertStatic color={'warning'} icon={false}>
                        Customer may be disabled if a credit application is not on file 15 days after
                        the first shipment date.
                      </AppAlertStatic>
                    }
                  </Grid>
                </Grid>
              </AppCard>
            }
          </When>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} md={3}>
          <AppInput
            multiline={true}
            label="Notes"
            id="companyNote"
            rows="10"
            tabIndex="19"
            inputProps={{
              maxLength: 2000
            }}
            value={values.note || ''}
            onChange={handleChange('note')}
          />
        </Grid>
        <Grid item xs={6} md={3}>

          <AppInput
            multiline={true}
            label={
              <div style={{ top: '0px', position: 'absolute', minWidth: '250px' }}>
                Hot Notes (400 max characters)
                <span>
                  <Tooltip
                    title={<div className={"tgf-kendo-grid-tooltip"}>If notes are added in this field, this same icon will appear when this address is selected within a shipment to alert you that there are Hot Notes for this address. Hovering over that icon will display the entered Hot Notes internally only as these do not appear on any generated documents.</div>}>
                    <Whatshot style={{ color: "red", fontSize: "small" }} />
                  </Tooltip>
                </span>
              </div>
            }
            id="hotNote"
            rows="10"
            tabIndex="19"
            inputProps={{
              maxLength: 400
            }}
            value={values.hotNote || ''}
            onChange={handleChange('hotNote')}
          />

        </Grid>
        {
          values.selectedCategoryType && values.selectedCategoryType.requiresCredit &&
          <Grid item xs={12} md={6}>
            <CustomerDisableHistory
              companyDisabled={values.isDisabled}
              disabledHistory={values.disabledCompanyHistory}
              disabledHistoryCount={values.disabledCompanyHistoryCount}
              onPageChange={values.handleDisabledCustomerHistoryPageChange}
              onLimitChange={values.handleDisabledCustomerHistoryLimitChange}
              onSortChange={values.handleDisabledCustomerHistorySortChange}
              offset={values.disabledHistoryOffset}
              limit={values.disabledHistoryLimit}
              sort={values.disabledHistorySort}
            />
          </Grid>
        }
        {/*<Grid item xs={12} md={3}>*/}
        {/*    <When condition={values.isAdmin}>*/}
        {/*        {() => <>*/}
        {/*            <AgentTooltipComponent content={''} agentId={values.customerOwner.id} width={"auto"}>*/}
        {/*                <AppAutoComplete*/}
        {/*                    label="Customer Owner"*/}
        {/*                    tabIndex={20}*/}
        {/*                    field={'fullName'}*/}
        {/*                    fieldIndex={'id'}*/}
        {/*                    options={values.associates}*/}
        {/*                    value={values.customerOwner || null}*/}
        {/*                    onChange={handleCustomerOwnerChange}*/}
        {/*                />*/}
        {/*                <AppValidationFailure*/}
        {/*                    message={errors.associate}*/}
        {/*                />*/}
        {/*            </AgentTooltipComponent>*/}
        {/*        </>*/}
        {/*        }*/}
        {/*    </When>*/}
        {/*    <When condition={false}>*/}
        {/*        {() =>*/}
        {/*            <AgentTooltipComponent content={''} agentId={values.customerOwner.id} width={"auto"}>*/}
        {/*                <AppInput*/}
        {/*                    label="Associate"*/}
        {/*                    disabled*/}
        {/*                    value={`${values.customerOwner.firstName} ${values.customerOwner.lastName}`}*/}
        {/*                />*/}
        {/*            </AgentTooltipComponent>*/}
        {/*        }*/}
        {/*    </When>*/}
        {/*</Grid>*/}
      </Grid>
      <Grid container spacing={2}>
        {/*<OperationsViewUserGrid />*/}
      </Grid>
      {
        Boolean(supportingValues.showRequestCreditIncreaseDialog) &&
        <RequestCreditIncreaseDialog
          summarizeOnly={values.isAdmin}
          companyId={values.companyId}
          onCancelClick={handleRciCancelClick}
          onCompleteClick={handleRciCompleteClick}
        />
      }
    </React.Fragment>
  );

};

export default renderDetailsForm;
