import {TgfBaseFormField} from "./TgfBaseFormField";

export class TgfCustomFormField extends TgfBaseFormField {
    constructor({
                    columnWidth = 1,
                    isVisibleFunction = (isEditMode, data, validationErrors) => {
                        return true;
                    },
                    displayElementFunction
                }) {
        super(columnWidth);

        this.displayElementFunction = displayElementFunction;
        this.isVisibleFunction = isVisibleFunction;
    }

    renderField(setValue, data, validationErrors, isEditMode) {
        if(this.isVisibleFunction(isEditMode, data, validationErrors)) {
            return this.displayElementFunction(isEditMode, data, validationErrors);
        } else {
            return <></>;
        }
    }
}
