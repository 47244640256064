import React from "react";
import CommissionCenterPage from '../../../crm/components/associate/CommissionsCenter/CommissionCenterPage';
import ReportNav from '../ReportNav';
import AccessDeniedPage from "../../errors/AccessDeniedPage";



export default () => {
    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);
    const canView = (employeeContext?.claims?.isHeadOfAgency) || (employeeContext?.claims?.isAdministrator);

    return canView ? <CommissionCenterPage LeftNav={ReportNav}/> : <AccessDeniedPage/>

}
