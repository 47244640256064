import React, {useState, useEffect} from 'react';
import ComponentBuilder from "../../../core/ComponentBuilder";
import AppMuiInput from "../../../core/components/inputs/AppInput";
import "./CrmSearchAllComboBox.scss";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import useIsHeadOfAgency from "../../../hubs/personaV2/selectors/useIsHeadOfAgency";
import {SearchAllRenderFactory} from "./SearchAllRenderFactory";

// For matching additional ref values against search string.
let matcherString;
export const searchTermMatch = (stringToMatch) => {
  const matcher = `^${matcherString}`;
  const regexp = new RegExp(matcher, "i");
  return regexp.test(stringToMatch);
}

const CrmSearchAllComboBox = (props) => {

  let {
    searchAll,
    filterMap,
    isAdmin,
    isHeadOfAgencyGuy,
    associateId,
    agencyId,
    ...otherProps
  } = props;
  const [inputValue, setInputValue] = useState("");
  const [searchResults, setSearchResults] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const debouncedInputValue = useDebouncedValue(inputValue, 500);
  const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);
  matcherString = inputValue;

  isAdmin = useIsAdministrator();
  isHeadOfAgencyGuy = useIsHeadOfAgency();
  associateId = employeeContext.id;
  agencyId = employeeContext.agency.id;

  // value debouncer
  function useDebouncedValue(value, wait) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const id = setTimeout(() => setDebouncedValue(value), wait);
      return () => clearTimeout(id);
    }, [value]);
    return debouncedValue;
  }

  // logic for showing/hiding results
  const dropDownWrapperDiv = document.querySelector(".dropDownWrapperDiv");
  const handleOnBlur = () => {
    dropDownWrapperDiv.classList.add("hidden");
  }
  const handleOnFocus = () => {

    dropDownWrapperDiv.classList.remove("hidden");
  }

  const handleSearchTermChange = async (value) => {
    setInputValue(value)
  }

  useEffect(() => {
    const getData = async () => {
      setSearchResults(null)
      setIsLoading(true);
      const data = await searchAll(debouncedInputValue, associateId, agencyId, isAdmin, isHeadOfAgencyGuy);
      setSearchResults(data)
      setIsLoading(false);
    }
    getData();
  }, [debouncedInputValue]);

  const renderData = () => {
    const {
      carriers,
      companies,
      trackingNumbers,
      shipmentNumbers,
      thirdPartyNumbers,
      pickupNumbers,
      deliveryNumbers,
      referenceNumbers,
      noResults,
    } = searchResults;
    if(noResults) return <p className={'no-results-p'} key={'no-results-p'}>No results found</p>;

    const carrierBlock = SearchAllRenderFactory.getContentRender('carriers');
    const companiesBlock = SearchAllRenderFactory.getContentRender('companies');
    const shipmentNumbersBlock = SearchAllRenderFactory.getContentRender('shipmentNumbers');
    const trackingNumbersBlock = SearchAllRenderFactory.getContentRender('trackingNumbers');
    const thirdPartyNumbersBlock = SearchAllRenderFactory.getContentRender('thirdPartyNumbers');
    const pickupNumbersBlock = SearchAllRenderFactory.getContentRender('pickupNumbers');
    const deliveryNumbersBlock = SearchAllRenderFactory.getContentRender('deliveryNumbers');
    const referenceNumbersBlock = SearchAllRenderFactory.getContentRender('referenceNumbers');

    return (
      <>
        {shipmentNumbers.length > 0 && shipmentNumbersBlock.renderContents(shipmentNumbers)}
        {carriers.length > 0 && carrierBlock.renderContents(carriers)}
        {companies.length > 0 && companiesBlock.renderContents(companies)}
        {trackingNumbers.length > 0 && trackingNumbersBlock.renderContents(trackingNumbers)}
        {thirdPartyNumbers.length > 0 && thirdPartyNumbersBlock.renderContents(thirdPartyNumbers)}
        {pickupNumbers.length > 0 && pickupNumbersBlock.renderContents(pickupNumbers)}
        {deliveryNumbers.length > 0 && deliveryNumbersBlock.renderContents(deliveryNumbers)}
        {referenceNumbers.length > 0 && referenceNumbersBlock.renderContents(referenceNumbers)}
      </>
    )
  }

  // final return
  return (
    <div className={'catch-all-search-input-wrapper-div'} tabIndex="0" onBlur={handleOnBlur} onFocus={handleOnFocus}>
      <AppMuiInput
        className={'catch-all-search-input'}
        value={inputValue}
        style={{width: 200}}
        placeholder={'search'}
        onChange={(e) => handleSearchTermChange(e.target.value)}
      >
      </AppMuiInput>
      <div className={'dropDownWrapperDiv'} key={'dropDownWrapperDiv'}>
        {isLoading && <p key={"loading-p"} className="loading-p">Loading</p>}
        {searchResults &&
          <div key={'renderDataDiv'}>
            {renderData()}
          </div>
        }
      </div>
    </div>
  );
}

export default ComponentBuilder
  .wrap(CrmSearchAllComboBox)
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      async searchAll(searchTerm, associateId, agencyId, isAdmin, isHeadOfAgency) {
        if (searchTerm.trim().length === 0) return null;

        const limit = 5;
        // Filters
        const carrierFilter = ownProps.filterMap({
          $or: [
            {
              name: {
                $like: `${searchTerm}%`
              }
            },
            {
              mcNumber: {
                $like: `${searchTerm}%`
              }
            }
          ]
        });
        const companyFilter = ownProps.filterMap({
          companyName: {
            $like: `${searchTerm}%`
          }
        });
        const shipmentNumberFilter = ownProps.filterMap({
          bolNumber: {
            $like: `${searchTerm}%`
          }
        });
        const trackingNumberFilter = ownProps.filterMap({
          proNumber: {
            $like: `${searchTerm}%`
          }
        });
        const ratingRefFilter = ownProps.filterMap({
          ratingRefNumber: {
            $like: `${searchTerm}%`
          }
        });
        const pickupNumberFilter = ownProps.filterMap({
          pickupNumber: {
            $like: `${searchTerm}%`
          }
        });
        const deliveryNumberFilter = ownProps.filterMap({
          deliveryNumber: {
            $like: `${searchTerm}%`
          }
        });
        const referenceNumberFilter = ownProps.filterMap({
          $or: [
            {
              refNum1: {
                $like: `${searchTerm}%`
              }
            },
            {
              refNum2: {
                $like: `${searchTerm}%`
              }
            },
            {
              refNum3: {
                $like: `${searchTerm}%`
              }
            },
            {
              refNum4: {
                $like: `${searchTerm}%`
              }
            }
          ]
        });

        // if NOT ace
        if(!isAdmin) {
          isHeadOfAgency ? companyFilter.associateId = `${associateId}` : companyFilter.associateId = `${agencyId}`;
        }



        // search options
        const carrierSearchOptions = {
          searchTerm,
          filter: carrierFilter,
          offset: 0,
          limit: limit
        };
        const companySearchOptions = {
          filter: companyFilter,
          limit: limit
        };
        const shipmentNumberSearchOptions = {
          filter: shipmentNumberFilter,
          limit: limit
        };
        const trackingNumberSearchOptions = {
          filter: trackingNumberFilter,
          limit: limit
        };
        const ratingRefSearchOptions = {
          filter: ratingRefFilter,
          limit: limit
        };
        const pickupNumberSearchOptions = {
          filter: pickupNumberFilter,
          limit: limit
        };
        const deliveryNumberSearchOptions = {
          filter: deliveryNumberFilter,
          limit: limit
        };
        const referenceNumberSearchOptions = {
          filter: referenceNumberFilter,
          limit: limit
        };

        let carriers
        let companies
        let shipmentNumbers;
        let trackingNumbers;
        let thirdPartyNumbers;
        let pickupNumbers;
        let deliveryNumbers;
        let referenceNumbers;
        let voidShipmentNumbers;
        let voidTrackingNumbers;
        let voidThirdPartyNumbers;
        let voidPickupNumbers;
        let voidDeliveryNumbers;
        let voidReferenceNumbers;

        // API calls
        const loadData = async () => {
          return new Promise((resolve) => {
            Promise.all([
              shell.gateway.searchCarriers(carrierSearchOptions),
              shell.gateway.searchCompanies(companySearchOptions),
              shell.gateway.getAllShipments(shipmentNumberSearchOptions),
              shell.gateway.getAllShipments(trackingNumberSearchOptions),
              shell.gateway.getAllShipments(ratingRefSearchOptions),
              shell.gateway.getAllShipments(pickupNumberSearchOptions),
              shell.gateway.getAllShipments(deliveryNumberSearchOptions),
              shell.gateway.getAllShipments(referenceNumberSearchOptions),
              shell.gateway.getAllVoidShipments(shipmentNumberSearchOptions),
              shell.gateway.getAllVoidShipments(trackingNumberSearchOptions),
              shell.gateway.getAllVoidShipments(ratingRefSearchOptions),
              shell.gateway.getAllVoidShipments(pickupNumberSearchOptions),
              shell.gateway.getAllVoidShipments(deliveryNumberSearchOptions),
              shell.gateway.getAllVoidShipments(referenceNumberSearchOptions),
            ]).then((results) => {
              carriers = results[0].carriers;
              companies = results[1].companies;
              shipmentNumbers = results[2].shipments;
              trackingNumbers = results[3].shipments;
              thirdPartyNumbers = results[4].shipments;
              pickupNumbers = results[5].shipments;
              deliveryNumbers = results[6].shipments;
              referenceNumbers = results[7].shipments;
              voidShipmentNumbers = results[8].shipments;
              voidTrackingNumbers = results[9].shipments;
              voidThirdPartyNumbers = results[10].shipments;
              voidPickupNumbers = results[11].shipments;
              voidDeliveryNumbers = results[12].shipments;
              voidReferenceNumbers = results[13].shipments;
              resolve();
            })
          });
        }

        await loadData();

        const results = {
          carriers,
          companies,
          shipmentNumbers: [...shipmentNumbers, ...voidShipmentNumbers],
          trackingNumbers: [...trackingNumbers, ...voidTrackingNumbers],
          thirdPartyNumbers: [...thirdPartyNumbers, ...voidThirdPartyNumbers],
          pickupNumbers: [...pickupNumbers, ...voidPickupNumbers],
          deliveryNumbers: [...deliveryNumbers, ...voidDeliveryNumbers],
          referenceNumbers: [...referenceNumbers, ...voidReferenceNumbers],
        }

        let noResults = Object.keys(results).every((key) => {
          return results[key].length === 0;
        })

        if(noResults) {
          return {noResults: true};
        }

        // Extracting the data
        return results;
      }
    }

  })
  .build();