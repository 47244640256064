import {makeStyles} from "@material-ui/core/styles";
import React, {useState} from "react";
// import { FacebookProvider, Page } from "react-facebook";
import {Divider, Grid, IconButton, Link as Href} from "@material-ui/core";
import {LinkedIn} from "@material-ui/icons";
import NumberFormat from "react-number-format";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import ComponentBuilder from "../../../core/ComponentBuilder";
import AppCard from "../../../core/components/cards/AppCard";
import AppPercentText from "../../../core/components/text/AppPercentText";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import CrmAssociateComboBox from "../../../crm/components/CrmAssociateComboBox";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import useIsHeadOfAgency from "../../../hubs/personaV2/selectors/useIsHeadOfAgency";
import useIsAgencyOps from "../../../hubs/personaV2/selectors/useIsAgencyOps";
import WhenAdministrator from "../../securityV2/WhenAdministrator";
import "./HomePage.scss";
import MetricCard from "./MetricCard";
import LastShipmentChart from "./includes/LastShipmentChart/LastShipmentChart";
import MarginContributionChart from "./includes/MarginContributionChart/MarginContributionChart";

const useStyles = makeStyles((theme) => ({
    linkedInContainer: {
        padding: "5px 5px 0 10px",
        "& .MuiIconButton-root": {
            padding: 0,
        },
        "& .MuiIconButton-root:hover": {
            backgroundColor: "transparent",
            textDecoration: "none",
        },
    },
    linkedIn: {
        paddingRight: 5,
        fontSize: 14,
        color: theme.palette.text.primary,
        fontWeight: 500,
        textDecoration: "none",
    },
    div1: {
        color: "blue",
    },
    div2: {
        color: "green",
    },
    grid1: {
        fontSize: 14,
    },
}));

const LoadProcessName = "HomePage.Load";

const renderCurrencyText = (value) => (
    <NumberFormat
        displayType={"text"}
        thousandSeparator={","}
        decimalScale={2}
        fixedDecimalScale
        prefix={"$"}
        value={value ?? 0}
    />
);

const renderCountText = (value) => (
    <NumberFormat displayType={"text"} thousandSeparator={","} value={value ?? 0} />
);
const renderPercent = (value) => (
    <AppPercentText
        value={value}
    />
);

const renderPercentageText = (value) => {

    return (
        <AppPercentText
            value={value ?? 0}
        />
    );
};

const HomePage = (props) => {
    const classes = useStyles();
    const {
        announcement,
        trackedSetCounts,
        associateMetrics,
        totalStatement,
        tgfMetrics,
        load,
        getYtdMarginGoals,
        ytdMarginGoals,
        openLinksInNewTab,
        currentAssociate,
    } = props;

    const personalDispatch = useDispatch();
    const globalState = useSelector(state => state);
    const arReleaseHidden = globalState?.utility?.featureFlags?.arReleaseHidden;

    const isAdmin = useIsAdministrator();
    const isHeadOfAgency = useIsHeadOfAgency();
    const isAgencyOps = useIsAgencyOps();

    const [selectedAgentRewardsAssociate, setSelectedAgentRewardsAssociate] =
        React.useState(null);
    const [specialOccasionNote, setSpecialOccasionNote] = useState(null);

    const handleSelectAssociate = (associate) => {
        associate
            ? setSelectedAgentRewardsAssociate(associate)
            : setSelectedAgentRewardsAssociate(null);
    };

    const loadAdditionalResources = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAdditionalHomePageResources'));
            const specialOccasionNote = await window.shell.gateway.getSpecialOccasionNote();
            if (specialOccasionNote) setSpecialOccasionNote({__html: specialOccasionNote.note});

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAdditionalHomePageResources'));
        }
    };

    React.useEffect(() => {
        if (isHeadOfAgency) setSelectedAgentRewardsAssociate(currentAssociate);
    }, []);

    React.useEffect(() => {
        getYtdMarginGoals(selectedAgentRewardsAssociate);
    }, [selectedAgentRewardsAssociate]);

    React.useEffect(() => {
        load();
        loadAdditionalResources();
    }, [load]);

    const markup = {
        __html: announcement,
    };

    const reportBar = [
        {
            title: "# Shipments",
            metric: renderCountText(
                associateMetrics && associateMetrics.shipmentCount,
            ),
        },
        {
            title: "Margin",
            metric: renderCurrencyText(
                associateMetrics && associateMetrics.totalMargin,
            ),
        },
        {
            title: "Avg. Margin",
            metric: renderCurrencyText(
                associateMetrics && associateMetrics.averageMargin,
            ),
        },
        {
            title: "Avg. Margin %",
            metric: renderPercentageText(
                associateMetrics && associateMetrics.averageMarginPercent,
            ),
        },
        {
            title: "Avg. Revenue",
            metric: renderCurrencyText(
                associateMetrics && associateMetrics.averageRevenue,
            ),
        },
        {
            title: "TGF Avg. Margin",
            metric: renderCurrencyText(tgfMetrics && tgfMetrics.averageMargin),
            isTgfMetric: true,
        },
        {
            title: "TGF Avg. Margin %",
            metric: renderPercentageText(
                tgfMetrics && tgfMetrics.averageMarginPercent,
            ),
            isTgfMetric: true,
        },
        {
            title: "TGF Avg. Revenue",
            metric: renderCurrencyText(tgfMetrics && tgfMetrics.averageRevenue),
            isTgfMetric: true,
        },
    ];

    const queenCard = {
        currentTitle: "Current Year-to-Date",
        projectedTitle: "Year-End Pace",
        totalGm: "Total GM:",
        totalGmMetric: renderCurrencyText(
            ytdMarginGoals && ytdMarginGoals.totmargin,
        ),
        annualGMPaceTitle: "Total GM:",
        annualGMPaceMetric: renderCurrencyText(
            ytdMarginGoals && ytdMarginGoals.pace,
        ),
        club250Title: " % to Club 250:",
        club250Metric: renderPercent(ytdMarginGoals && ytdMarginGoals.twoHundred),
        club250PaceTitle: "% to Club 250:",
        club250PaceMetric: renderPercent(
            ytdMarginGoals && ytdMarginGoals.club250pace,
        ),
        eliteTitle: " % to Elite:",
        eliteMetric: renderPercent(ytdMarginGoals && ytdMarginGoals.elite),
        elitePaceTitle: "% to Elite:",
        elitePaceMetric: renderPercent(ytdMarginGoals && ytdMarginGoals.elitepace),
        diamondTitle: "% to Diamond Club:",
        diamondMetric: renderPercent(ytdMarginGoals && ytdMarginGoals.diamond),
        diamondPaceTitle: "% to Diamond Club:",
        diamondPaceMetric: renderPercent(
            ytdMarginGoals && ytdMarginGoals.diamondpace,
        ),
    };
    const LinkedInAction = () => (
        <div className={classes.linkedInContainer}>
            <IconButton
                component={Href}
                href={
                    "https://www.linkedin.com/company/tallgrass-freight-company/posts"
                }
                target={"_blank"}
                disableFocusRipple
            >
                <span className={classes.linkedIn}>Click here for LinkedIn:</span>
                <LinkedIn color="primary" />
            </IconButton>
        </div>
    );

    const isHeadOfAgencyOrAdmin = isHeadOfAgency || isAdmin;

    const hideSocialFeeds = Boolean(
        localStorage.getItem("tgf-crm-disable-social-media"),
    );

    return (
        <FullWidthLayout>
            <Grid container justify={"center"} spacing={2}>
                {!isAgencyOps && (
                    <Grid container justify={"center"} item xs={12} spacing={1}>
                        {reportBar.map((item, index) => (
                            <Grid item key={index}>
                                <MetricCard
                                    title={item.title}
                                    metric={item.metric}
                                    isTgfMetric={item.isTgfMetric}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
                <Grid container spacing={1} justify="center">
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={1} direction={"column"}>
                            <Grid item>
                                <AppCard
                                    title="Announcements"
                                >
                                    <div
                                        className={"announcement-text"}
                                        dangerouslySetInnerHTML={markup}
                                    ></div>
                                </AppCard>
                            </Grid>
                            <Grid item xs={12}>
                                <AppCard title="Shipments" height={100}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Link
                                                target={openLinksInNewTab ? "_blank" : ""}
                                                to={"shipment/listing/delivered"}
                                            >
                                                {trackedSetCounts.delivered} shipments ready to be locked.
                                            </Link>
                                        </Grid>
                                        {(arReleaseHidden === false) &&
                                            <>
                                                <Grid item xs={12}>
                                                    <Link
                                                        target={openLinksInNewTab ? "_blank" : ""}
                                                        to={"/reports/locked-shipments-report"}
                                                        className={"auto-invoice-count-link"}
                                                    >
                                                        {trackedSetCounts.stagedToBeAutoInvoiced} shipments staged to be
                                                        invoiced.
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Link
                                                        target={openLinksInNewTab ? "_blank" : ""}
                                                        to={"/reports/locked-shipments-report?show-halted-only=true"}
                                                        className={"halted-invoice-count-link"}
                                                    >
                                                        {trackedSetCounts.lockedAndHalted} shipments had their invoices
                                                        to customers halted.
                                                    </Link>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </AppCard>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={1} direction={"column"}>
                            <Grid item xs={12}>
                                {currentAssociate.canViewMarginContribution &&
                                    <MarginContributionChart />
                                }
                            </Grid>
                            {isHeadOfAgencyOrAdmin &&
                                <Grid item>
                                    <AppCard title="Agent Rewards Programs Stats">
                                        <WhenAdministrator>
                                            <Grid item xs={12}>
                                                <CrmAssociateComboBox
                                                    placeholder={"Search agents..."}
                                                    roleId={8}
                                                    onSelectAssociate={handleSelectAssociate}
                                                    style={{
                                                        minWidth: "250px",
                                                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 2px",
                                                    }}
                                                ></CrmAssociateComboBox>
                                            </Grid>
                                            <Divider
                                                style={{
                                                    margin: "16px 0px",
                                                    backgroundColor: "lightgray",
                                                }}
                                            />
                                        </WhenAdministrator>
                                        <Grid container className={classes.grid1}>
                                            <Grid item columns={6} xs={6}>
                                                <div>
                                                    <span className={classes.div1}>
                                                        {queenCard.currentTitle}
                                                    </span>
                                                </div>
                                                <br></br>
                                                <div>
                                                    {queenCard.totalGm}{" "}
                                                    <span className={classes.div1}>
                                                        {queenCard.totalGmMetric}
                                                    </span>
                                                </div>
                                                <div>
                                                    {queenCard.club250Title}{" "}
                                                    <span className={classes.div1}>
                                                        {queenCard.club250Metric}
                                                    </span>
                                                </div>
                                                <div>
                                                    {queenCard.diamondTitle}{" "}
                                                    <span className={classes.div1}>
                                                        {queenCard.diamondMetric}
                                                    </span>
                                                </div>
                                                <div>
                                                    {queenCard.eliteTitle}{" "}
                                                    <span className={classes.div1}>
                                                        {queenCard.eliteMetric}
                                                    </span>
                                                </div>
                                            </Grid>
                                            <Grid columns={6}>
                                                <div>
                                                    <span className={classes.div2}>
                                                        {queenCard.projectedTitle}
                                                    </span>
                                                </div>
                                                <br></br>
                                                <div>
                                                    {queenCard.annualGMPaceTitle}{" "}
                                                    <span className={classes.div2}>
                                                        {queenCard.annualGMPaceMetric}
                                                    </span>
                                                </div>
                                                <div>
                                                    {queenCard.club250PaceTitle}{" "}
                                                    <span className={classes.div2}>
                                                        {queenCard.club250PaceMetric}
                                                    </span>
                                                </div>
                                                <div>
                                                    {queenCard.diamondPaceTitle}{" "}
                                                    <span className={classes.div2}>
                                                        {queenCard.diamondPaceMetric}
                                                    </span>
                                                </div>
                                                <div>
                                                    {queenCard.elitePaceTitle}{" "}
                                                    <span className={classes.div2}>
                                                        {queenCard.elitePaceMetric}
                                                    </span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </AppCard>
                                </Grid>}

                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={1} direction={"column"}>
                            <Grid item xs={12}>
                                <LastShipmentChart />
                            </Grid>
                            <Grid item xs={12} style={{height: "fit-content"}}>
                                <AppCard
                                    title={<span>🎈Birthdays and Anniversaries<span
                                        style={{display: "inline-block", transform: "scale(-1, 1)"}}
                                    >🎈</span></span>}
                                >
                                    {specialOccasionNote &&
                                        <div
                                            className={"announcement-text"}
                                            dangerouslySetInnerHTML={specialOccasionNote}
                                        ></div>
                                    }
                                </AppCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FullWidthLayout>
    );
};

export default ComponentBuilder.wrap(HomePage)
    .stateToProps((state, ownProps) => {
        return {
            associateMetrics: state.persona.associateMetrics,
            totalStatement: state.persona.associateTotalStatement,
            tgfMetrics: state.persona.tgfMetrics,
            announcement: state.persona.announcementNote,
            trackedSetCounts: state.shipment.trackedSetCounts,
            ytdMarginGoals: state.persona.ytdMarginGoals,
            openLinksInNewTab: state.persona.associate.openLinksInNewTab,
            currentAssociate: state.persona.associate,
        };
    })
    .dispatchToProps((shell, dispatch, getState) => {
        return {
            async load() {
                dispatch(shell.actions.sys.processStart(LoadProcessName));
                const actions = await Promise.all([
                    shell.actions.persona.loadAssociateMonthlyMetrics(),
                    shell.actions.persona.loadTgfMonthlyMetrics(),
                    shell.actions.persona.loadAnnouncementNote(),
                    shell.actions.shipment.loadTrackedSetCounts(),
                ]);
                actions.forEach(dispatch);
                dispatch(shell.actions.sys.processComplete(LoadProcessName));
            },
            async getYtdMarginGoals(associate) {
                return dispatch(await shell.actions.persona.ytdMarginGoals(associate));
            },
        };
    })
    .build();
