import { Grid } from "@material-ui/core";
import { Block, LockOpen, Undo } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import AppMuiButton from "../../../../../../core/components/AppButton";
import trimNullifyObject from "../../../../../../core/utils/trimNullifyObject";


const ButtonBar = (props) => {
    const personalDispatch = useDispatch();

    const handleUnlockAssociate = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('unlockAssociate'));
            const data = {
                ...props.associate,
                loginAttempts: 0,
            };
            const cleanAssociate = trimNullifyObject(data);
            const savedAssociate = await window.shell.gateway.saveAssociate(cleanAssociate);
            props.setAssociate(savedAssociate);
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('unlockAssociate'));
        }
    };

    const handleLogoutAssociate = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('terminateAssociateSession'));
            await window.shell.gateway.terminateSessions(props.associateId);
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('terminateAssociateSession'));
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item>
                <AppMuiButton
                    style={{ marginLeft: 10 }}
                    type={"button"}
                    color={'primary'}
                    onClick={props.handleSubmit}
                    disabled={(!props.isDirty || !props.isValid)}
                >
                    Save
                </AppMuiButton>
            </Grid>
            <Grid item>
                <AppMuiButton
                    startIcon={<Undo />}
                    disabled={!props.isDirty}
                    color="warning"
                    onClick={props.handleReset}
                >
                    Reset
                </AppMuiButton>
            </Grid>
            <Grid item>
                <AppMuiButton
                    startIcon={<LockOpen />}
                    disabled={!props.associateId || props.associate?.loginAttempts < 3}
                    color={'error'}
                    onClick={handleUnlockAssociate}
                >
                    Unlock Account
                </AppMuiButton>
            </Grid>
            <Grid item>
                <AppMuiButton
                    startIcon={<Block />}
                    color={'error'}
                    onClick={handleLogoutAssociate}
                    disabled={!props.associateId}
                >
                    Log Out
                </AppMuiButton>
            </Grid>
        </Grid>
    );
};

export default ButtonBar;