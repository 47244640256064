import ActionTypes from "../../../ActionTypes";

const doubleBrokeredLoads = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Automation.DoubleBrokered.Loaded:
      return action.doubleBrokeredLoads;
    default:
      return state;
  }
};

export default doubleBrokeredLoads;