import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from '../../ActionTypes';

const loadStateProvinces = async (shell) => {

  const stateProvinces = await shell.gateway.getStateProvinces();

  return {
    type: ActionTypes.Support.StateProvinces.Loaded,
    stateProvinces
  };

};

export default ActionBuilder
  .for(loadStateProvinces)
  .build();