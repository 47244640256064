import buildOdataQuery from "odata-query-sequelize";
import Qs from "qs";

class QueryStringUtils {

 static buildCompleteOdataQueryString(odata, additionalParams) {
     let qs = (odata) ? buildOdataQuery(odata) : '';
     if(additionalParams) {
         qs = qs.length > 0 ? `${qs}&${Qs.stringify(additionalParams)}` : `?${Qs.stringify(additionalParams)}`
     }
     return qs;
 }
}

export default QueryStringUtils;