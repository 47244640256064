import React from "react";
import LeftNav from '../LeftNav';
import AccessDeniedPage from "../../errors/AccessDeniedPage";
import MonthlyGrossMarginPage from "../../reporting/MonthlyGrossMargin";



export default () => {
    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);
    const canView = (employeeContext?.claims?.isHeadOfAgency) || (employeeContext?.claims?.isAdministrator && employeeContext?.claims?.canViewAgencyPage);
    return canView ? <MonthlyGrossMarginPage navMenu={LeftNav} agencyMode={true}/> : <AccessDeniedPage/>
}
