import React, {useEffect} from "react";
import AppSwitch from "../../../core/components/inputs/AppSwitch/AppMuiSwitch";
import {useDispatch, useSelector} from "react-redux";
import "./ToggleShipmentPin.scss";
import InfoIconTooltip from "../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

export const ToggleShipmentPin = (props) => {
    const personalDispatch = useDispatch();
    const activeAgentId = useSelector((state) => {
        return state.persona.associate.id;
    });

    const state = useSelector(state => state);
    const disabled = !!state?.shipment?.modification?.shipment?.voidId;

    const [isPinned, setIsPinned] = React.useState(null);
    const [actionTaken, setActionTaken] = React.useState(new Date());

    useEffect(() => {
        window.shell.gateway.isShipmentPinned(props.bolNumber, activeAgentId).then((data) => {
            setIsPinned(data);
        });
    }, []);

    useEffect(() => {
        window.shell.gateway.isShipmentPinned(props.bolNumber, activeAgentId).then((data) => {
            setIsPinned(data);
        });
    }, [actionTaken]);

    const handlePinStatusChange = async (bolNumber) => {
        personalDispatch(window.shell.actions.sys.processStart('handleCoveredChange'));

        try {
            if (!isPinned) {
                await window.shell.gateway.setShipmentPin(bolNumber, activeAgentId);
            } else {
                await window.shell.gateway.unsetShipmentPin(bolNumber, activeAgentId);
            }
        } catch {

        } finally {
            setActionTaken(new Date());
            personalDispatch(window.shell.actions.sys.processComplete('handleCoveredChange'));
        }
    };

    return (
        <>
            {(isPinned != null) && <span>
                  <AppSwitch
                      checked={isPinned}
                      onChange={() => {
                          handlePinStatusChange(props.bolNumber);
                      }}
                      label={
                          <div style={{display: "flex"}}>
                              <span className={isPinned ? "pin-shipment-toggle-on" : "pin-shipment-toggle-off"}>
                                  Pin Shipment to My Load Board
                              </span>
                              <InfoIconTooltip
                                  title={
                                      `Toggling this on and off will pin/unpin this shipment to/from your own personal load board only.`
                                  }
                                  style={{alignSelf: "center"}}
                              />
                          </div>
                      }
                      disabled={disabled}
                  />
              </span>
            }
        </>
    );
};

export default ToggleShipmentPin;
