import React from "react";
import {useDispatch} from "react-redux";
import TgfDialog from "../../../../../core/kendo/dialog/TgfDialog";
import TgfDataGrid from "../../../../../core/kendo/dataGrid/TgfDataGrid";
import "./UserCompanyVisibilityModal.scss";

export const UserCompanyVisibilityModal = (props) => {
    const {
        onClose,
        companyId,
        companyName
    } = props;

    const personalDispatch = useDispatch();

    const LoadUserCompanyVisibility = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadInvolvedUsers'));
            odata.filter.companyId = companyId;
            const results = await window.shell.gateway.getPageOfUserCompanyVisibility(odata);
            return results;
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadInvolvedUsers'));
        }
    };

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0,
        };
        odata.filter.companyId = companyId;
        return odata;
    };

    const gridColumns = [
        {
            title: 'User Name',
            field: 'associateFullName',
            type: 'text',
        },
        {
            title: 'View Reason',
            field: 'visibility',
            type: 'text',
        }
    ];


    return (
        <TgfDialog
            title={`User Visibility - ${companyName}`}
            onClose={onClose}
            width={1000}
        >
            <TgfDataGrid
                // ref={ref}
                className={"user-company-visibility-modal_data-grid"}
                onLoadDataPage={LoadUserCompanyVisibility}
                onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                gridColumns={gridColumns}
                sort={[{field: "visibility", dir: "asc"}]}
                pageable={{
                    pageSize: 20,
                    buttonCount: 10,
                    pageSizes: [5, 10, 20, 50, 100, 500]
                }} />
        </TgfDialog>
    )
}