import InfoIconTooltip from "../../../components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import React from "react";

export const HeaderInfoIconTooltipCell = ({headerTitle, tooltipTitle}) => {
    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <span style={{marginTop: "3px"}}>
                {headerTitle}
            </span>
            <InfoIconTooltip title={tooltipTitle} />
        </div>
    );
};